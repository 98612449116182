import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {BesoinsService} from "../../../../core/services/gestion-commandes/besoins.service";
import {Subscription} from "rxjs";
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {
  DATEPICKER_FR,
  HELP_FOLDERS,
  MSG_KEY,
  MSG_SEVERITY,
  TYPE_LIST_UDP,
  TYPE_UNITE_DE_MESURE,
  UI_COLORS
} from "../../../../core/constants";
import {ProduitDeclinaisonService} from "../../../../core/services/entities/produit-declinaison.service";
import {UnitesDeProductionService} from "../../../../core/services/entities/unites-de-production.service";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {CatalogueAchatDTO} from "../../../../core/dtos/catalogue-achat-dto";
import {UniteDeProduction__SecteurFournisseurDTO} from "../../../../core/dtos/unite-de-production__secteur-fournisseur-dto";
import {UpdateGacaViewModel} from "../../../../shared/article/grille-remplacer-article/grille-remplacer-article.component";
import {GroupeAchatCaViewModel} from "../../../../core/models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";
import {CommandeDenreeModel} from "../../../../core/models/gestion-commande-fournisseur/commande-denree-model";
import {CommandesService} from "../../../../core/services/gestion-commandes/commandes.service";
import {find as _find} from 'lodash'
import {LotMarchePdLumpCaDto} from "../../../../core/dtos/gerstion-marche/lot-marche-pd-lump-ca-dto";
import {DATAGRID_ROW_TYPES} from "../../../../core/services/technique/devextreme.service";
import {SaveCommandeDenreeModel} from "../../../../core/models/gestion-commande-fournisseur/save-commande-denree-model";
import {BonCfDTO} from "../../../../core/dtos/boncfs-dto";
import {DxDataGridComponent} from "devextreme-angular";
import {ReglePrefereeCommandeFournisseurEnum} from "../../../../core/enums/regle-preferee-commande-fournisseur-enum";
import {confirm} from "devextreme/ui/dialog";
import {FournisseurDTO} from "../../../../core/dtos/fournisseur-d-t-o";

@Component({
  selector: 'yo-create-proposition-denree',
  templateUrl: './create-proposition-denree.component.html',
  styleUrls: ['./create-proposition-denree.component.scss']
})
export class CreatePropositionDenreeComponent implements OnInit, OnDestroy {

  @ViewChild('gridDenreeSelected') grid: DxDataGridComponent;

  displayDialog: boolean = false;
  fullScreen: boolean = true;

  uniteDeProductionList: UniteDeProductionDTO[] = [];
  uniteDeProductionSelected: UniteDeProductionDTO;
  udpSfPrefereList: UniteDeProduction__SecteurFournisseurDTO[] = [];

  commandeDenreeList: CommandeDenreeModel[] = [];

  optionScenarioArticle: any[] = [];
  scenarioArticleSelected: any;

  minDate: Date = new Date();
  dateSelected: Date = new Date();
  fr = DATEPICKER_FR;
  TYPE_UNITE_DE_MESURE = TYPE_UNITE_DE_MESURE;

  subOpenDialog: Subscription;
  subProduitDeclinaisonListPropCommande: Subscription;

  dropDownOptions = {width: 750};
  articlesDisponibles: CatalogueAchatDTO[] = [];
  selectedGacaViewModel: GroupeAchatCaViewModel;

  pathFile: string = HELP_FOLDERS.COMMANDES_FOURNISSEURS + '/proposition-commande-denree';

  constructor(public utils: UtilsService,
              private besoinSvc: BesoinsService,
              private commandesSvc: CommandesService,
              private produitDeclinaisonSvc: ProduitDeclinaisonService,
              private uniteDeProductionSvc: UnitesDeProductionService) {
  }

  ngOnInit() {
    this.subscriptionOpenDialog();
    this.subscriptionProduitDeclinaisonListPropCommande();
    this.initData();
    this.initDataOptionsScenario();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subProduitDeclinaisonListPropCommande);
  }

  subscriptionOpenDialog = (): void => {
    this.subOpenDialog = this.besoinSvc.openPropositionDialogDenree$.subscribe(data => {
      this.displayDialog = true;
    })
  }

  subscriptionProduitDeclinaisonListPropCommande = (): void => {
    this.subProduitDeclinaisonListPropCommande = this.produitDeclinaisonSvc.produitDeclinaisonListPropCommande$.subscribe(data => {
      // Garantir unicité de la liste
      let commandeDenreeSet = new Set(this.commandeDenreeList.map(item => item.idProduitDeclinaison));
      data.map(item => commandeDenreeSet.add(item.id));
      // Recuperer la liste des commandeDenree
      this.commandesSvc.getPropositionCommandeForCreationModeDenree([...commandeDenreeSet], this.uniteDeProductionSelected.id, this.dateSelected, this.scenarioArticleSelected.value).subscribe((result: ResponseWrapper<CommandeDenreeModel>) => {
        if (this.commandeDenreeList.length) {
          const newResults = result.resultList.map(r => {
            const currentOrder: CommandeDenreeModel = this.commandeDenreeList.find(cmd => cmd.idProduitDeclinaison === r.idProduitDeclinaison);
            if (currentOrder) {
              r.quantiteUC = currentOrder.quantiteUC;
              r.quantiteUT = currentOrder.quantiteUT;
              r.isQuantiteUCRonded = currentOrder.isQuantiteUCRonded;
            }
            return r;
          });
          this.commandeDenreeList = newResults;
        }
        else {
          this.commandeDenreeList = result.resultList;
        }
      });
    });
  }

  closeDialog = (): void => {
    this.displayDialog = false;
    this.commandeDenreeList = [];
  }

  initData = (): void => {
    // Unite de production
    this.uniteDeProductionSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal).subscribe((response: ResponseWrapper<UniteDeProductionDTO>) => {
      this.uniteDeProductionList = response.resultList
      if (this.uniteDeProductionList && this.uniteDeProductionList.length) {
        this.uniteDeProductionSelected = this.uniteDeProductionList[0];
        this.udpSfPrefereList = this.uniteDeProductionList[0].uniteDeProduction__secteurFournisseurList;
      }
    });
  }

  initDataOptionsScenario = (): void => {
    this.optionScenarioArticle.push({key: "Article préféré", value : ReglePrefereeCommandeFournisseurEnum.ARTICLE_PREFEREE });
    this.optionScenarioArticle.push({key: "Article le moins cher", value : ReglePrefereeCommandeFournisseurEnum.ARTICLE_LE_MOINS_CHER });
    this.optionScenarioArticle.push({key: "Filiales préférées (non géré à l'heure actuelle)", value : ReglePrefereeCommandeFournisseurEnum.FILIALE_PREFEREE });
    this.optionScenarioArticle.push({key: "Marchés - article avec ordre", value : ReglePrefereeCommandeFournisseurEnum.MARCHE_ARTICLE_AVEC_ORDRE});
    this.optionScenarioArticle.push({key: "Marchés - article le moins cher", value : ReglePrefereeCommandeFournisseurEnum.MARCHE_ARTICLE_LE_MOINS_CHER  });
    this.scenarioArticleSelected = { key: "Article préféré", value : ReglePrefereeCommandeFournisseurEnum.ARTICLE_PREFEREE };
  }

  getProviderLabel = (feed: CommandeDenreeModel): string => {
    const gacaSelected: GroupeAchatCaViewModel = feed.gacaSelected;
    const idCatalogueAchat: number = gacaSelected ? gacaSelected.idCatalogueAchat : 0;
    const caList: CatalogueAchatDTO[] = feed.catalogueAchatList;
    if (idCatalogueAchat && caList && caList.length) {
      const caFound: CatalogueAchatDTO = caList.find(ca => ca.id === idCatalogueAchat);
      return caFound && caFound.fournisseur ? caFound.fournisseur.libelle : 'Non renseigné';
    }
    return 'Non renseigné';
  }

  onChangeScenarioArticle = (event): void => {
    this.reloadCommandeDenreeList();
  }

  checkBeforeSavePropositionList = (): void => {
    if (!this.commandeDenreeList || !this.commandeDenreeList.length) {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez ajouter une ou plusieurs denrée(s) avant de poursuivre', '', 8000);
    } else {
      if (this.commandeDenreeList.filter(item => item.quantiteUT === 0).length) {
        let result = confirm("Certaines denrées ont une quantité à zéro et ne seront pas pris en compte, souhaitez-vous continuer ?", "Demande de confirmation");
        result.then((dialogResult) => {
          if (dialogResult) this.generatePropositionList()
        });
      } else {
        this.generatePropositionList();
      }
    }
  }

  removeFeed = (feed: CommandeDenreeModel): void => {
    this.commandeDenreeList = this.commandeDenreeList.filter(cmd => cmd.idProduitDeclinaison !== feed.idProduitDeclinaison);
    this.grid.instance.refresh();
  }

  generatePropositionList = (): void => {
    const saveCommandeModeDenreeList: SaveCommandeDenreeModel[] = this.getSaveCommandeModeDenree();

    this.commandesSvc.saveBonCfModeDenree(this.uniteDeProductionSelected.id, saveCommandeModeDenreeList)
      .subscribe((data: ResponseWrapper<BonCfDTO>) =>{
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Enregistrement effectué.', '', 8000);
        this.commandesSvc.announceRefreshPropositionCommandeGrid();
        this.closeDialog();
    });
  }

  private getSaveCommandeModeDenree = (): SaveCommandeDenreeModel[] => {
    let saveCommandeModeDenreeList: SaveCommandeDenreeModel[] = [];
    this.commandeDenreeList.forEach( item => {
      if (item.quantiteUC > 0){
        let scdm: SaveCommandeDenreeModel = new SaveCommandeDenreeModel();
        scdm.quantiteUC = item.quantiteUC;
        scdm.idGroupeAchat_CatalogueAchat = item.gacaSelected.id;
        scdm.dateLivraisonPossible = item.dateLivraisonPossible;
        saveCommandeModeDenreeList.push(scdm);
     }
    });
    return saveCommandeModeDenreeList;
  }

  isDisabledSaveBtn = (): boolean => {
    return this.commandeDenreeList.length < 0;
  }

  help = (): void => {
    return undefined;
  }

  openAjoutDenree = (): void => {
    this.besoinSvc.announceOpenAjoutDenreePropositionCommande(true);
  }


  onEditingStart = ($event: any): void => {
    // this.commandeDenreeSelected = $event.data;

    // si on edite la colonne libelleArticleSelected, on affecte les articles disponibles
    // if ($event.column.dataField === 'libelleArticleSelected') {
    //   this.articlesDisponibles = $event.data.catalogueAchatList;
    //   this.selectedGacaViewModel = $event.data.gacaSelected;
    // }
  }


  updatePrixUnitaireCommande = (component: any, event: UpdateGacaViewModel, cell: CommandeDenreeModel): void => {
    cell.catalogueAchatList.forEach(ca => {
      ca.groupeAchatCaList.forEach(gaca => {
        if (gaca.id === event.idGaca) {
          cell.gacaSelected = gaca;
          cell.libelleArticleSelected = ca.produitArticleLibelle;
        }
      });
    });
    component.close();
  }

  onChangeUniteDeProduction = (event: any): void => {
    if (event && event.value && event.value.uniteDeProduction__secteurFournisseurList) {
      this.udpSfPrefereList = event.value.uniteDeProduction__secteurFournisseurList;
    }
    if (this.commandeDenreeList && this.commandeDenreeList.length) {
      this.reloadCommandeDenreeList();
    }
  }

  reloadCommandeDenreeList = () :void => {
    if (!this.uniteDeProductionSelected.id || !this.commandeDenreeList || !this.commandeDenreeList.length) {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez ajouter une ou plusieurs denrée(s) avant de changer le scénario', '', 8000);
      return;
    }
    if (this.scenarioArticleSelected.value === ReglePrefereeCommandeFournisseurEnum.FILIALE_PREFEREE) {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Ce scénario n\'est pas encore fonctionnel', '', 8000);
      return;
    }
    let idsCommandeDenreeSet: Set<number> = new Set<number>(this.commandeDenreeList.map(item => item.idProduitDeclinaison));
    this.commandesSvc.getPropositionCommandeForCreationModeDenree([...idsCommandeDenreeSet], this.uniteDeProductionSelected.id, this.dateSelected, this.scenarioArticleSelected.value).subscribe((result: ResponseWrapper<CommandeDenreeModel>) => {
      result.resultList.forEach(item => {
        const qte: number = this.findQuantiteUTUnSaved(this.commandeDenreeList, item.idProduitDeclinaison);
        if (qte !== null) item.quantiteUT = qte;
      });
      this.commandeDenreeList = result.resultList;
    });
  }

  findQuantiteUTUnSaved = (commandeDenreeList: CommandeDenreeModel[], idProduitDeclinaison: number): number => {
    let cmd: CommandeDenreeModel = commandeDenreeList.find(commandeDenree => commandeDenree.idProduitDeclinaison === idProduitDeclinaison);
    return cmd ? cmd.quantiteUT : null;
  }

  getUniteDeMesureAbrevation = (cell: CommandeDenreeModel, typeUniteDeMesure: TYPE_UNITE_DE_MESURE): string => {
    if (!this.utils.isNullOrEmpty(cell)) {
      const ca: CatalogueAchatDTO = _find(cell.catalogueAchatList, ['id', cell.gacaSelected.idCatalogueAchat]);
      if (!this.utils.isNullOrEmpty(ca)) {
        switch (typeUniteDeMesure) {
          case TYPE_UNITE_DE_MESURE.uniteDeStockage :
            return ca.uniteDeStockage.abreviation;
          case TYPE_UNITE_DE_MESURE.uniteDeCommande :
            return ca.uniteDeCommande.abreviation;
          case TYPE_UNITE_DE_MESURE.uniteTechnique :
            return ca.uniteTechnique.abreviation;
          case TYPE_UNITE_DE_MESURE.uniteDeFacturation :
            return ca.uniteDeFacturation.abreviation;
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getUniteDeMesureLibelle = (cell: CommandeDenreeModel, typeUniteDeMesure: TYPE_UNITE_DE_MESURE): string => {
    if (!this.utils.isNullOrEmpty(cell)) {
      const ca: CatalogueAchatDTO = _find(cell.catalogueAchatList, ['id', cell.gacaSelected.idCatalogueAchat]);
      if (!this.utils.isNullOrEmpty(ca)) {
        switch (typeUniteDeMesure) {
          case TYPE_UNITE_DE_MESURE.uniteDeStockage :
            return ca.uniteDeStockage.libelle;
          case TYPE_UNITE_DE_MESURE.uniteDeCommande :
            return ca.uniteDeCommande.libelle;
          case TYPE_UNITE_DE_MESURE.uniteTechnique :
            return ca.uniteTechnique.libelle;
          case TYPE_UNITE_DE_MESURE.uniteDeFacturation :
            return ca.uniteDeFacturation.libelle;
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getQuantiteUTFromUC = (cell: CommandeDenreeModel): number => {
    if (!this.utils.isNullOrEmpty(cell)) {
      const ca: CatalogueAchatDTO = _find(cell.catalogueAchatList, ['id', cell.gacaSelected.idCatalogueAchat]);
      return this.utils.convertQuantiteUdm1ToQuantiteUdm2(cell.quantiteUC, ca.ratioUniteCommandeUniteBase, ca.ratioUniteTechnique);
    }
  }

  getQuantiteUFFromUT = (cell: CommandeDenreeModel): number => {
    if (!this.utils.isNullOrEmpty(cell)) {
      const ca: CatalogueAchatDTO = _find(cell.catalogueAchatList, ['id', cell.gacaSelected.idCatalogueAchat]);
      return this.utils.convertQuantiteUdm1ToQuantiteUdm2(cell.quantiteUT, ca.ratioUniteTechnique, ca.ratioUniteFacturationUniteBase);
    }
  }

  getQuantiteUCFromUT = (cell: CommandeDenreeModel): number => {
    if (!this.utils.isNullOrEmpty(cell)) {
      const ca: CatalogueAchatDTO = _find(cell.catalogueAchatList, ['id', cell.gacaSelected.idCatalogueAchat]);
      cell.quantiteUC = Math.ceil(this.utils.convertQuantiteUdm1ToQuantiteUdm2(cell.quantiteUT, ca.ratioUniteTechnique, ca.ratioUniteCommandeUniteBase));
      // Test si il y a eu un arrondi par rapport a la valeur brut
      if (cell.quantiteUC !== this.utils.convertQuantiteUdm1ToQuantiteUdm2(cell.quantiteUT, ca.ratioUniteTechnique, ca.ratioUniteCommandeUniteBase)) {
        cell.isQuantiteUCRonded = true;
      } else {
        cell.isQuantiteUCRonded = false;
      }
      return cell.quantiteUC;
    }
  }

  getLmPdUpCa = (cell: CommandeDenreeModel): LotMarchePdLumpCaDto => {
    const ca: CatalogueAchatDTO = _find(cell.catalogueAchatList, ['id', cell.gacaSelected.idCatalogueAchat]);
    if (!this.utils.isNullOrEmpty(ca)) {
      return ca.lmPdUpCa;
    }
  }

  onCellPrepared = (event: any): void => {
    // si cell est un header
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER) {
      // si colonne editable
      if (event.column.allowEditing) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    } else {
      if (event.column.dataField === 'dateLivraisonPossible' && event.data.dateLivraisonPossible > this.dateSelected) {
        event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
      }
      if (event.column.dataField === 'quantiteUC' && event.data.isQuantiteUCRonded) {
        event.cellElement.style.backgroundColor = UI_COLORS.INFO;
      }
    }
  }

  getQuantiteUCTooltip = (cell: CommandeDenreeModel): string => {
    if (cell.isQuantiteUCRonded) {
      return `La valeur a été arrondie au supérieure :
      ${cell.quantiteUC} ${this.getUniteDeMesureAbrevation(cell, TYPE_UNITE_DE_MESURE.uniteDeCommande)}  vaut  ${this.getQuantiteUTFromUC(cell)} ${this.getUniteDeMesureAbrevation(cell, TYPE_UNITE_DE_MESURE.uniteTechnique)}'.`;
    }
  }

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }

  changeDateLivraisonSouhaitee($event: any) {
    this.commandeDenreeList.forEach(commandeDenree => {
      const fournisseur: FournisseurDTO = commandeDenree.catalogueAchatList.filter(item => item.id === commandeDenree.gacaSelected.idCatalogueAchat)[0].fournisseur;
      this.commandesSvc
        .calculateDateLivraisonPossible(this.uniteDeProductionSelected.id, $event, fournisseur.id)
        .subscribe(result => commandeDenree.dateLivraisonPossible = result.one);
    })
  }
}
