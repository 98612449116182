import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ProduitDeclinaisonDenreeService} from "../../../../../core/services/gestionproduits/produit-declinaison-denree.service";
import {Subscription} from "rxjs";
import {ProduitDeclinaisonDTO} from "../../../../../core/dtos/produit-declinaison-dto";
import {ProduitDTO} from "../../../../../core/dtos/produit-dto";
import {UtilsService} from "../../../../../core/utils/utils.service";
import CustomStore from "devextreme/data/custom_store";
import {Sort} from "../../../../../core/suppliers/generics/generic-request-supplier";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {GenericFormService} from "../../../../../core/services/generics/generic-form.service";
import {DevextremeService} from "../../../../../core/services/technique/devextreme.service";
import {find as _find} from "lodash";
import {ResponseWrapper} from "../../../../../core/suppliers/wrappers/response-wrapper";
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {DxDataGridComponent} from "devextreme-angular";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-dialog-replace-denree',
  templateUrl: './dialog-replace-denree.component.html',
  styleUrls: ['./dialog-replace-denree.component.scss']
})
export class DialogReplaceDenreeComponent implements OnInit, OnDestroy {

  @ViewChild('grid') grid: DxDataGridComponent;

  displayDialog = false;
  subOpenDialogReplaceDenree: Subscription;

  denreeList: ProduitDeclinaisonDTO[] = [];
  denreeSubstitution: ProduitDeclinaisonDTO;
  produitUtilise: ProduitDeclinaisonDTO;

  pourcentageSubstitution: number = 100;

  platList: ProduitDTO[] = [];

  customStore: CustomStore;

  constructor(private produitDecliDenreeSvc: ProduitDeclinaisonDenreeService,
              public utils: UtilsService,
              private gds: GenericDatagridService,
              private gfs: GenericFormService,
              private dxSvc: DevextremeService) {
  }


  ngOnInit(): void {

    this.subOpenDialogReplaceDenree = this.produitDecliDenreeSvc.openDialogReplaceDenree$.subscribe(response => {
      this.produitUtilise = response.produitUtilise;
      this.platList = response.selectedPlatList;
      this.displayDialog = true;
      this.customStore = this.initCustomStore();
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogReplaceDenree);
  }

  initCustomStore = () => {
    const cs = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        // console.log(loadOptions);

        // page
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();
        // filters
        const filters = this.dxSvc.dxToGrsFilters(loadOptions.filter);
        // sorts
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);

        return this.gds.search(this.produitDecliDenreeSvc.filterDenreeList(page, pageSize, filters, sorts)).toPromise().then(response => {
          this.denreeList = response.resultList;
          return {
            data: this.denreeList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => {
        return null;
      }
    });
    return cs;
  };

  onSelectionChanged = (event: any) => {
    this.denreeSubstitution = _find(this.denreeList, (denree) => denree.id === event.currentSelectedRowKeys[0]);
  };

  getMesureDenreeSubstitution = () => {
    if (this.pourcentageSubstitution === 0 ||
      this.produitUtilise.ratioUniteTechnique === 0 ||
      this.denreeSubstitution.ratioUniteTechnique === 0) {
      return 0;
    } else {
      return (this.pourcentageSubstitution / 100 * (this.produitUtilise.ratioUniteTechnique / this.denreeSubstitution.ratioUniteTechnique));
    }
  };

  isDisabledSaveBtn = () => this.pourcentageSubstitution <= 0;

  saveReplaceDenree = async () => {
    const platIdList = this.platList.map((plat: ProduitDTO) => plat.id);
    const applyDishesPrice = await confirm("Souhaitez-vous appliquer le calcul du prix des plats après le remplacement de la denrée ?", "Calcul du prix des plats");
    this.produitDecliDenreeSvc.saveReplaceDenreewithRatio(platIdList, this.produitUtilise, this.denreeSubstitution, this.pourcentageSubstitution, applyDishesPrice).subscribe(
      (response :ResponseWrapper<boolean>) => {
        if(response.resultMessage.length > 0){
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, `Certains plats n'ont pas pu être substitués`, response.resultMessage, 8000);
        }
        this.closeDialog();
        this.produitDecliDenreeSvc.announceRefreshDialogListPlatGrid(true);
      });
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.pourcentageSubstitution = 100;
  };
}
