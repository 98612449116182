import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericManageDialogService} from "../../../../core/services/generics/generic-manage-dialog.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {TachePmsSavedSupplier, TachePmsService} from "../../../../core/services/pms/tache-pms.service";
import {TachePmsDto} from "../../../../core/dtos/pms/tache-pms-dto";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {HELP_FOLDERS} from "../../../../core/constants";
import {Title} from "@angular/platform-browser";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-pms-tache-grille',
  templateUrl: './pms-tache-grille.component.html',
  styleUrls: ['./pms-tache-grille.component.scss']
})
export class PmsTacheGrilleComponent implements OnInit, OnDestroy {
  tachesList: TachePmsDto[] = [];

  hasPms = false;

  subTacheSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.PMS + '/pms-taches';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              private i8nSvc: InternationalizationService,
              public gds: GenericDatagridService,
              private tachePmsSvc: TachePmsService,
              private title: Title) {}

  ngOnInit(): void {
    this.initHasPms();
    this.initTachesPms();
    this.tacheSavedSubscription();
    this.initProperty();
  }

  initProperty = (): void => {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
    this.title.setTitle(this.getTitle());
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subTacheSaved);
  }

  getTitle = (): string => "GESTION DES TÂCHES";

  canEdit = (): boolean => this.hasPms && this.auth2Svc.hasSitesLocaux();

  initTachesPms = (): void => {
    this.tachePmsSvc.getAll()
      .subscribe(response => {
        this.tachesList = response.resultList;
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  tacheSavedSubscription = (): void => {
    this.subTacheSaved = this.tachePmsSvc.tacheSaved$
      .subscribe((response: TachePmsSavedSupplier) => {
        this.initTachesPms();

        if (!response.isUpdate) {
          this.tachesList.push(response.tache);
        }
      });
  };

  openTache = (t: TachePmsDto): void => {
    this.tachePmsSvc.announceOpenDialog(t);
  };

  deleteValues = async (): Promise<void> => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.tachesList.find(s => s.id === idSelected))
        .map((tache: TachePmsDto) => tache.id);
      this.tachePmsSvc.deleteByIds(idsToDelete)
        .subscribe(o => this.tachesList = this.utils.handleIsDeletableResultWithNum(o.one, idsToDelete, this.tachesList));
    }
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les tâches (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
