import {Component, OnDestroy, OnInit} from "@angular/core";
import {UdpConditionnementVarianteDTO} from "../../../../core/dtos/conditionnement/udp-conditionnement-variante-dto";
import {UtilsService} from "../../../../core/utils/utils.service";
import {UdpConditionnementVarianteService} from "../../../../core/services/conditionnements/udp-conditionnement-variante.service";
import {Subscription} from "rxjs";
import {UpConditionnnementSupplier} from "./up-conditionnnement-resolver.service";
import {ActivatedRoute} from "@angular/router";
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {find as _find} from 'lodash';
import ConditionnementVarianteDTO from "../../../../core/dtos/conditionnement/conditionnement-variante-dto";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-up-conditionnement-variante',
  templateUrl: './up-conditionnement-variante.component.html',
  styleUrls: ['./up-conditionnement-variante.component.scss']
})
export class UpConditionnementVarianteComponent implements OnInit, OnDestroy {

  udpConditionnementVariantesList: UdpConditionnementVarianteDTO[] = [];
  uniteDeProduction: UniteDeProductionDTO;

  subscriptionRoute: Subscription;
  subCondiVariantesSelectedList: Subscription;

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production-conditionnements-variantes';

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private auth2Svc: Auth2Service,
              private udpCondiVarianteSvc: UdpConditionnementVarianteService) {
  }

  ngOnInit() {
    this.initDataRoute();
    this.subcriptionCondiVariantesSelectedList();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subCondiVariantesSelectedList);
  }

  initDataRoute = () => {
    this.subscriptionRoute = this.route.data.subscribe((data: { upConditionnnementSupplier: UpConditionnnementSupplier }) => {
      this.udpConditionnementVariantesList = data.upConditionnnementSupplier.udpConditionnementVarianteList;
      this.uniteDeProduction = data.upConditionnnementSupplier.uniteDeProduction
    });
  };

  subcriptionCondiVariantesSelectedList = () => {
    this.subCondiVariantesSelectedList = this.udpCondiVarianteSvc.condiVariantesSelectedList$.subscribe(list => {
      let listToSaved = [];
      list.forEach((CvHavetoSave: ConditionnementVarianteDTO) => {
        const udpCv: UdpConditionnementVarianteDTO = _find(this.udpConditionnementVariantesList, (udpCvOwned: UdpConditionnementVarianteDTO) => {
          return CvHavetoSave.id === udpCvOwned.conditionnementVariante.id
        });

        if (this.utils.isNullOrEmpty(udpCv)) {
          listToSaved.push(CvHavetoSave);
        }
      })

      if (listToSaved.length > 0) {
        this.udpCondiVarianteSvc.saveCondiVaraintesListForUdp(this.uniteDeProduction.id, listToSaved).subscribe(data => {
          this.loadDataGrid();
        });
      }
    });
  };

  openDialogAjoutCondiVariantes = () => {
    this.udpCondiVarianteSvc.announceOpenDialogAjoutCondi();
  };

  openDeleteObject = async (udpCondi: UdpConditionnementVarianteDTO) => {
    const result = confirm(`Etes vous sûr de vouloir supprimer le conditionnement variant  ${udpCondi.conditionnementVariante.conditionnement.libelle}    ${udpCondi.conditionnementVariante.variante.libelle} de la liste de unité de production ?`,
      'Suprression de conditionnement variant');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.udpCondiVarianteSvc.deleteOne(udpCondi.id).subscribe((response: ResponseWrapper<number>) => {
        this.loadDataGrid();
        this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, 'Suppression réussie', '', 3000);
      });
    }
  };

  loadDataGrid = () => {
    this.udpCondiVarianteSvc.findByUdpId(this.uniteDeProduction.id).subscribe((data: ResponseWrapper<UdpConditionnementVarianteDTO>) => {
      this.udpConditionnementVariantesList = data.resultList;
    });
  };

  isDisabledDeleteBtn = (udpCondi: UdpConditionnementVarianteDTO) => {
    if (!this.utils.isNullOrEmpty(udpCondi)) {
      return !this.auth2Svc.isSiteLocal(udpCondi.uniteDeProduction.site.id);
    } else {
      return true;
    }

  };

  help = () => {
  };
}
