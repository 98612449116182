import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {GestionGemrcnService} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {ModelesNutritionnelsService} from '../../../core/services/entities/modeles-nutritionnels.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {SelectItem} from 'primeng/api';
import {combineLatest, of, Subscription} from 'rxjs';
import {ModeleNutritionnelDTO} from '../../../core/dtos/modele-nutritionnel-dto';
import {FamilleGemrcnDTO} from '../../../core/dtos/famille-gemrcn-dto';
import {cloneDeep as _cloneDeep, sortBy as _sortBy} from 'lodash'
import {RegleGemrcnSupplier} from '../../../shared/ui/regle-gemrcn/regle-gemrcn-supplier';
import {CHOIX_GEMRCN, DATEPICKER_FR, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ModeleNutritionnelDetailDTO} from '../../../core/dtos/modele-nutritionnel-detail-dto';
import {catchError, switchMap} from 'rxjs/operators';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {ModeleNutritionnelGroupeGemrcnService} from '../../../core/services/entities/modele-nutritionnel-groupe-gemrcn.service';
import {ModeleNutritionnelGroupeGemrcnDTO} from '../../../core/dtos/modele-nutritionnel-groupe-gemrcn-dto';
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-modele-nutri',
  templateUrl: './modele-nutri.component.html',
  styleUrls: ['./modele-nutri.component.scss']
})
export class ModeleNutriComponent implements OnInit, OnDestroy {

  subModeleNutri: Subscription;
  subSaveGrilleGemrcn: Subscription;

  planAlimentaire: ModeleNutritionnelDTO;
  famillesGemrcn: FamilleGemrcnDTO[];
  selectedFamillesGemrcn: FamilleGemrcnDTO[];
  grilleGemrcn: ModeleNutritionnelDetailDTO[];

  colFrequence = 250;
  colFamilleGemrcnWidth = 250;
  selectedRegle: RegleGemrcnSupplier;

  CHOIX_GEMRCN = CHOIX_GEMRCN;

  optionsChoixCalcul: SelectItem[] = [
    {label: 'D', value: CHOIX_GEMRCN.DIRIGE, title: 'Choix dirigé'},
    {label: 'M', value: CHOIX_GEMRCN.MULTIPLE, title: 'Choix multiple'},
  ];


  reglesGemrcn: Map<string, RegleGemrcnSupplier> = new Map();
  controlesGemrcn: Map<string, RegleGemrcnSupplier> = new Map();

  // cells: Map<string, ModeleNutritionnelDetailDTO>;
  displayRegle: boolean;
  displayAddAnalyseGEMRCN: boolean;
  dialogTitleRegle: string;
  selectedFamilleCouleurHexa: string;

  // repas disponibles dans le modele nutritionnel
  repasOfModeleNutritionnelList: RepasDTO[];
  selectedRepasOfModeleNutritionnelList: RepasDTO[];

  // numero de groupe permettant de combiner les repas dans l'anlayse Gemrcn
  selectedNumeroGroupeAnalyseGemrcn: number;

  // groupes d'analyse gemrcn
  groupesGemrcn: ModeleNutritionnelGroupeGemrcnDTO[];

  // nombre de jours semaine disponibles pour l'analyse GEMRCN
  nbJoursSemaine: number;
  // nombre de semaine à prendre en compte dans le cycle GEMRCN;
  nbSemaines: number;

  localeFr: any;

  constructor(public ggSvc: GestionGemrcnService,
              public mnGroupeGemrcnSvc: ModeleNutritionnelGroupeGemrcnService,
              public utils: UtilsService,
              private route: ActivatedRoute,
              private gfs: GenericFormService,
              private modeleNutritionnelSvc: ModelesNutritionnelsService) {
  }

  ngOnInit() {
    this.initRouteData();
    this.localeFr = DATEPICKER_FR;
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subModeleNutri);
    this.utils.unsubscribe(this.subSaveGrilleGemrcn);
  }

  initRouteData = () => {
    const parentRoute$ = this.route.parent.data;
    const currentRoute$ = this.route.data;
    const all$ = combineLatest([parentRoute$, currentRoute$]);

    this.subModeleNutri = all$
      .subscribe(response => {
        this.planAlimentaire = response[0].planAlimentaireSupplier.planAlimentaire;
        this.famillesGemrcn = response[1].modeleNutriSupplier.famillesGemrcn;
        this.grilleGemrcn = response[1].modeleNutriSupplier.grilleGemrcn;
        this.selectedFamillesGemrcn = this.utils.preSelectMultiList(response[1].modeleNutriSupplier.selectedFamillesGemrcn, this.famillesGemrcn);
        this.reglesGemrcn = response[1].modeleNutriSupplier.reglesGemrcn;
        this.controlesGemrcn = response[1].modeleNutriSupplier.controlesGemrcn;
        this.groupesGemrcn = response[1].modeleNutriSupplier.groupesAnalyseGemrn;
        this.nbJoursSemaine = response[1].modeleNutriSupplier.nbJoursSemaine;
        this.nbSemaines = response[1].modeleNutriSupplier.nbSemaines;
      });
  };


  getRegleGemrcn = (idFamilleGemrcn: number, modeleNutritionnelGroupeGemrcnId: number) => this.reglesGemrcn.get(this.keyRegleGemrcn(idFamilleGemrcn, modeleNutritionnelGroupeGemrcnId));

  getControleGemrcn = (idFamilleGemrcn: number, modeleNutritionnelGroupeGemrcnId: number) => this.controlesGemrcn.get(this.keyRegleGemrcn(idFamilleGemrcn, modeleNutritionnelGroupeGemrcnId));

  keyRegleGemrcn = (idFamilleGemrcn: number, modeleNutritionnelGroupeGemrcnId: number) => idFamilleGemrcn + '-' + modeleNutritionnelGroupeGemrcnId;

  /***
   * Enregistrer le modele nutrititionnel , la grille de frequence et relancer le calcul de frequence
   */
  saveFrequencesGemrcn = (modeleNutritionnel: ModeleNutritionnelDTO, groupesGemrcn: ModeleNutritionnelGroupeGemrcnDTO[], reglesGemrcn: Map<string, RegleGemrcnSupplier>) => {

    if (this.utils.isNullOrEmpty(modeleNutritionnel) || modeleNutritionnel.id < 1) {
      this.utils.showMsg('plan-alimentaire', MSG_SEVERITY.ERROR, `Veuillez enregistrer la fiche d'identité avant de paramétrer vos familles GEMRCN.`, '', 8000);
      return of(null);
    } else {
      const cells = this.prepareReglesForSave(groupesGemrcn, reglesGemrcn);
      const grilleGemrcn$ = this.ggSvc.saveGrilleGemrcn(cells, modeleNutritionnel);
      const planAlimentaire$ = this.gfs.saveOne(modeleNutritionnel, this.modeleNutritionnelSvc.getEntityName());
      const all$ = combineLatest([grilleGemrcn$, planAlimentaire$]);
      return all$.pipe(
        switchMap(response => this.ggSvc.controleGemrcnPlanAlimentaire(modeleNutritionnel.id)),
        catchError(err => err)
      );
    }
  };

  /**
   * On transforme les regles gemrcn en ModeleNutritionnelDetailDTO[]
   * @param groupesGemrcn
   * @param mapReglesGemrcn
   */
  prepareReglesForSave = (groupesGemrcn: ModeleNutritionnelGroupeGemrcnDTO[], mapReglesGemrcn: Map<string, RegleGemrcnSupplier>): ModeleNutritionnelDetailDTO[] => {

    const regles: ModeleNutritionnelDetailDTO[] = [];

    if (!this.utils.isCollectionNullOrEmpty(groupesGemrcn)) {
      mapReglesGemrcn.forEach((value, key) => {
        // on recupe l'id du modele nutritionnel groupe gemrcn id
        const ggId = +(key.split('-')[1]);

        for (const gg of groupesGemrcn) {
          if (gg.id === ggId) {
            const frequenceRepas = this.mnGroupeGemrcnSvc.getFrequenceRepas(gg, this.nbSemaines, this.nbJoursSemaine);
            const mnd: ModeleNutritionnelDetailDTO = this.ggSvc.createNewModeleNutritionnelDetailDTO(gg, value, frequenceRepas);
            // prendre le choix du groupe
            mnd.modeleNutritionnelGroupeGemrcnChoix = gg.choix;
            mnd.idModeleNutritionnel = gg.modeleNutritionnelId;
            regles.push(mnd);
            break;
          }
        }
      });
    }
    return regles;
  };

  isGridDisplayable = () => {
    if (!this.utils.isCollectionNullOrEmpty(this.selectedFamillesGemrcn)) {
      return true;
    }
    return false;
  };

  openRegle = (event, regle: RegleGemrcnSupplier, famille: FamilleGemrcnDTO, modeleNutritionnelGroupeGemrcn: ModeleNutritionnelGroupeGemrcnDTO) => {
    if (this.utils.isNullOrEmpty(regle)) {
      const frequenceRepas = this.mnGroupeGemrcnSvc.getFrequenceRepas(modeleNutritionnelGroupeGemrcn, this.nbSemaines, this.nbJoursSemaine);
      regle = this.ggSvc.createNewRegleGemrcnSupplier(modeleNutritionnelGroupeGemrcn, famille, frequenceRepas);
    }
    this.reglesGemrcn.set(this.keyRegleGemrcn(famille.id, modeleNutritionnelGroupeGemrcn.id), regle);

    this.selectedRegle = _cloneDeep(regle);
    this.selectedRegle.modeleNutritionnelGroupeGemrcn = modeleNutritionnelGroupeGemrcn;

    this.dialogTitleRegle = `${famille.libelle}`;
    this.selectedFamilleCouleurHexa = famille.couleur.hexa;
    this.displayRegle = true;
  };

  updateRegle = () => {
    this.reglesGemrcn.set(this.keyRegleGemrcn(this.selectedRegle.familleGemrcn.id, this.selectedRegle.modeleNutritionnelGroupeGemrcn.id), this.selectedRegle);
    this.displayRegle = false;

    this.saveFrequencesGemrcn(this.planAlimentaire, this.groupesGemrcn, this.reglesGemrcn).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.controlesGemrcn = this.ggSvc.getControlesGemrcn(response.resultList);
      }
    });
  };

  refreshGrid = ($event: any) => {
    this.selectedFamillesGemrcn = _sortBy(this.selectedFamillesGemrcn, 'ordre');
    this.reglesGemrcn = this.initRegles(this.reglesGemrcn, this.selectedFamillesGemrcn, this.groupesGemrcn);

    this.saveFrequencesGemrcn(this.planAlimentaire, this.groupesGemrcn, this.reglesGemrcn).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.controlesGemrcn = this.ggSvc.getControlesGemrcn(response.resultList);
      }
    });
  };

  private initRegles = (mapReglesFromBack: Map<string, RegleGemrcnSupplier>, selectedFamillesGemrcn: FamilleGemrcnDTO[], groupesGemrcn: ModeleNutritionnelGroupeGemrcnDTO[]): Map<string, RegleGemrcnSupplier> => {

    const reglesGemrcn = new Map();
    mapReglesFromBack = mapReglesFromBack ? mapReglesFromBack : new Map();

    if (this.isGridDisplayable()) {
      for (let famille of selectedFamillesGemrcn) {
        for (const gm of groupesGemrcn) {
          const frequenceRepas = this.mnGroupeGemrcnSvc.getFrequenceRepas(gm, this.nbSemaines, this.nbJoursSemaine);
          let regle = mapReglesFromBack.get(this.keyRegleGemrcn(famille.id, gm.id));
          if (this.utils.isNullOrEmpty(regle)) {
            regle = this.ggSvc.createNewRegleGemrcnSupplier(gm, famille, frequenceRepas);
          }
          reglesGemrcn.set(this.keyRegleGemrcn(famille.id, gm.id), regle);
        }
      }
    }
    return reglesGemrcn;
  };

  addAnnalyseGemrcn = () => {
    this.selectedRepasOfModeleNutritionnelList = [];
    this.repasOfModeleNutritionnelList = [];

    this.mnGroupeGemrcnSvc.prepareDialogAddGroupeGemrcn(this.planAlimentaire.id).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.repasOfModeleNutritionnelList = response.additionalProperties['repasOfModeleNutritionnelList'];
        if (this.utils.isCollectionNullOrEmpty(this.repasOfModeleNutritionnelList)) {
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.WARN, 'Tous les repas parmamétrés sur ce modèle de menus sont affectés à une analyse GEMRCN !', '', 8000);
        } else {
          this.displayAddAnalyseGEMRCN = true;
        }
      }
    });
  };

  closeDialogAddAnalyseGemrcn = () => {
    this.displayAddAnalyseGEMRCN = false;
  };

  /**
   * On enregistre le groupe gemrcn puis on enregistre la grille et on affiche les simulations de choix dirigé ou multiple
   */
  saveGroupeGEMRCN = () => {
    if (this.utils.isCollectionNullOrEmpty(this.selectedRepasOfModeleNutritionnelList)) {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, 'Veuillez sélectionner un ou des repas !');
    } else {
      const mnGroupeGemrcn = this.mnGroupeGemrcnSvc.createDTO(this.planAlimentaire, this.selectedRepasOfModeleNutritionnelList, this.CHOIX_GEMRCN.DIRIGE);

      this.mnGroupeGemrcnSvc.saveGroupeGemrcn(mnGroupeGemrcn).pipe(
        switchMap(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Le paramétrage de l'analyse GEMRCN "${mnGroupeGemrcn.modeleNutritionnelParamGemrcnList.map(item => item.repasLibelle).join(' + ')}" a été enregistré avec succès.`, '', 5000);
            if (this.utils.isCollectionNullOrEmpty(this.groupesGemrcn)) {
              this.groupesGemrcn = [];
            }
            this.groupesGemrcn.push(response.one);
            this.refreshGrid(undefined);
            this.displayAddAnalyseGEMRCN = false;
          }
          return this.saveFrequencesGemrcn(this.planAlimentaire, this.groupesGemrcn, this.reglesGemrcn);
        })
      ).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.controlesGemrcn = this.ggSvc.getControlesGemrcn(response.resultList);
        }
      });
    }
  };

  removeGroupeAnalyseGemrcn = async (groupeAnalyseGemrcn: ModeleNutritionnelGroupeGemrcnDTO) => {
    const libelleGroupe = this.mnGroupeGemrcnSvc.getGroupeLibelle(groupeAnalyseGemrcn);
    const result = confirm(`Enlever l'analyse GEMRCN  '${libelleGroupe}' ?`, 'Suppression d\'analyse(s)');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.mnGroupeGemrcnSvc.deleteGroupeGemrcn(groupeAnalyseGemrcn.id).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.groupesGemrcn = this.groupesGemrcn.filter(item => item.id != groupeAnalyseGemrcn.id);
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Analyse GEMRCN '${libelleGroupe}' supprimée avec succès.`, '', 8000);
        }
      });
    }
  };

  calculGemrnc = (groupeAnalyseGemrcn: ModeleNutritionnelGroupeGemrcnDTO) => {
    this.mnGroupeGemrcnSvc.saveGroupeGemrcn(groupeAnalyseGemrcn).pipe(
      switchMap(response => this.ggSvc.controleGemrcnPlanAlimentaire(this.planAlimentaire.id))
    ).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.controlesGemrcn = this.ggSvc.getControlesGemrcn(response.resultList);
      }
    });
  };

  changeGroupeGemrcnChoix = ($event: any, groupeAnalyseGemrcn: ModeleNutritionnelGroupeGemrcnDTO) => {
    groupeAnalyseGemrcn.choix = $event;
    this.calculGemrnc(groupeAnalyseGemrcn);
  };
}
