import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {HttpParams} from '@angular/common/http';
import {FournisseurDTO} from '../../dtos/fournisseur-d-t-o';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {MSG_KEY} from '../../constants';
import {HttpService} from "../technique/http.service";
import {IsDeletableObject} from "../../models/is-deletable-object";
import {confirm} from "devextreme/ui/dialog";

export const URL_GET_FOURNISSEUR_PARENT = 'dolrest/gestion-fournisseurs/get-fournisseur-parent';
export const URL_GET_FIND_FOURNISSEUR_BY_PARENT_ID = 'dolrest/gestion-fournisseurs/find-filiale-list-by-id-parent-fournisseur';
export const URL_DELETE_FOURNISSEUR_FILIALES = 'dolrest/gestion-fournisseurs/delete-fournisseur';
export const URL_SAVE_FOURNISSEUR_FILIALE = 'dolrest/gestion-fournisseurs/save/fournisseur/filiale';
export const URL_CHECK_FOURNISSEUR_FILIALE = 'dolrest/gestion-fournisseurs/check/fournisseur/filiale';
export const URL_GET_FOURNISSEUR_BY_ID = `dolrest/gestion-fournisseurs/get-fournisseur-by-id`;
export const URL_GET_FOURNISSEUR_BY_ID_UDP = `dolrest/gestion-fournisseurs/get-fournisseur-livrable-by-id-udp`;
export const URL_GET_FOURNISSEUR_BY_ID_UDP_AND_PRODUITDECLI = `dolrest/gestion-fournisseurs/get-fournisseur-livrable-by-id-udp-and-id-pd`;

@Injectable({
  providedIn: 'root'
})
export class GestionFournisseurService {

  private subjectFournisseur = new BehaviorSubject(undefined);
  fournisseur$ = this.subjectFournisseur.asObservable();

  private subjectRefreshGrid = new BehaviorSubject(undefined);
  refreshGridParent$ = this.subjectRefreshGrid.asObservable();

  constructor(private utils: UtilsService,
              private httpSvc : HttpService) { }


  announceFournisseur = (ff: FournisseurDTO) => {
    this.subjectFournisseur.next(ff);
  };

  announceRefreshGrid = (ff: FournisseurDTO) => {
    this.subjectRefreshGrid.next(ff);
  };


  getAllFournisseurParent = () => this.httpSvc.get(URL_GET_FOURNISSEUR_PARENT);
  /**
   * Récupére les filiales fournisseur d'un fournisseur
   * @param idFournisseur
   */
  getFilialeListByIdParentFournisseur = idFournisseur => {
    const params: HttpParams =  new HttpParams().set('idFournisseur', idFournisseur + '');
    return this.httpSvc.get(URL_GET_FIND_FOURNISSEUR_BY_PARENT_ID, params);
  };


  /**
   * Récupérer un fournisseur filiale
   * @param ff
   */
  getFournisseuById = (id: number): Observable<ResponseWrapper<FournisseurDTO>> => {

    const params: HttpParams = new HttpParams().set('idFournisseur', id + '');
    return this.httpSvc.get(URL_GET_FOURNISSEUR_BY_ID, params);
  };

  openDeleteDialog = async (dto: FournisseurDTO) => {
    const result = confirm(`Etes-vous sûr de vouloir supprimer la filiale  '${dto.libelle}' ?`, 'Suppression de filiale');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.deleteFournisseur(dto.id).subscribe((response: ResponseWrapper<IsDeletableObject>) => {
        this.utils.deleteMessage(response, MSG_KEY.ROOT);
      });
    }
  };


  deleteFournisseur = (idFournisseur : number) => {
    const params: HttpParams = new HttpParams().set('idFournisseur', idFournisseur + '');
    return this.httpSvc.delete(URL_DELETE_FOURNISSEUR_FILIALES, params);
  };


  /**
   * Enregistrer une filiale
   * @param {FournisseurDTO} fournisseur
   */
  saveFournisseur = (fournisseur: FournisseurDTO): Observable<ResponseWrapper<FournisseurDTO>> => {

    if (this.utils.isNullOrEmpty(fournisseur)) return of(null);
    return this.httpSvc.post(URL_SAVE_FOURNISSEUR_FILIALE, fournisseur);
  };

  /**
   * vérifie si il ya une modification des secteurs du fournisseur et si la modification a une incidence
   * @param {FournisseurDTO} fournisseur
   */
  checkFournisseur = (fournisseur: FournisseurDTO) => {

    const params: HttpParams = new HttpParams().set('idFournisseur', fournisseur.id + '')
    if (this.utils.isNullOrEmpty(fournisseur.secteurFournisseurList)) return of(null);
    return this.httpSvc.post(URL_CHECK_FOURNISSEUR_FILIALE, fournisseur.secteurFournisseurList, params);
  };

  /**
   * Récupérer la liste des fournisseurs pouvant livré une unité de production
   * @param ff
   */
  getFournisseuByIdUdp = (idUdp: number): Observable<ResponseWrapper<FournisseurDTO>> => {

    const params: HttpParams = new HttpParams().set('idUdp', idUdp + '');
    return this.httpSvc.get(URL_GET_FOURNISSEUR_BY_ID_UDP, params);
  };

  getFournisseurListByIdUdpAndIdProduitDeclinaison = (idUdp: number, idProduitDeclinaison: number): Observable<ResponseWrapper<FournisseurDTO>> => {

    const params: HttpParams = new HttpParams()
      .set('idUdp', idUdp + '')
      .set('idProduitDeclinaison', idProduitDeclinaison + '');
    return this.httpSvc.get(URL_GET_FOURNISSEUR_BY_ID_UDP_AND_PRODUITDECLI, params);
  };
}

