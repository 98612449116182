// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]4.2.7-8562(26d85b01fa)-C01/06/2022-18:43:05-B01/06/2022-20:12:49' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]4.2.7-8562(26d85b01fa)-C01/06/2022-18:43:05-B01/06/2022-20:12:49",
  branch: "production",
  latestTag: "4.2.7",
  revCount: "8562",
  shortHash: "26d85b01fa",
  longHash: "26d85b01fa673caf11c90b4f54be70b5019cbf50",
  dateCommit: "01/06/2022-18:43:05",
  dateBuild: "01/06/2022-20:12:49",
  buildType: "Ansible",
  } ;
