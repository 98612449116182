import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import {ActivatedRoute} from "@angular/router";
import {RoutemapService} from "../../../../../core/services/routemap.service";
import {combineLatest, Subscription} from "rxjs";
import {ModelesPlcService} from "../../../../../core/services/conditionnements/plc/modeles-plc.service";
import {ConvivesService} from "../../../../../core/services/entities/convives.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../../core/suppliers/dialog-msg-supplier";
import ModeleConditionnementPlcDTO from "../../../../../core/dtos/conditionnement/plc/modele-conditionnement-plc-dto";
import McPlc__McpCvDTO from "../../../../../core/dtos/conditionnement/plc/conditionnement/plat/mc-plc-mc-cv-dto";
import ModeleConditionnementPlc__PrestationDTO
  from "../../../../../core/dtos/conditionnement/plc/conditionnement/prestation/mc-plc-prestation-dto";
import ModeleConditionnementsPlatConditionnementsVariantesDTO
  from "../../../../../core/dtos/conditionnement/modele-conditionnement-plat-conditionnement-variante-dto";
import McPlcPrestation__McPlc__McpCvDTO
  from "../../../../../core/dtos/conditionnement/plc/conditionnement/plat/mc-plc-prestation-mc-plc-mc-cv-dto";
import {ConviveDTO} from "../../../../../core/dtos/convive-dto";
import {McpConditionnementsVariantesService} from "../../../../../core/services/conditionnements/mcp-conditionnements-variantes.service";
import {compact as _compact, groupBy as _groupBy} from 'lodash';
import {FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-mp-row-parametrage-plc',
  templateUrl: './mp-row-parametrage.component.html',
  styleUrls: ['./mp-row-parametrage.component.scss']
})
export class ModelePlcRowParametrage implements OnInit, OnDestroy {

  openDialog = false;

  hasIDistri = false;

  subOpenDialog: Subscription;
  subRoute: Subscription;

  mcPlcMcpCVList: McPlc__McpCvDTO[];
  mcplcPrestations: ModeleConditionnementPlc__PrestationDTO[];
  mcpConditionnementVarianteList: ModeleConditionnementsPlatConditionnementsVariantesDTO[];
  mcPlcPrestaMcPlcCVList: McPlcPrestation__McPlc__McpCvDTO[];

  prestationsList: ConviveDTO[];

  prestationsSelectedList: ConviveDTO[];

  mcpCvList: any[];
  mcpCvSelectedList: any[];

  modeleConditionnementPlc: ModeleConditionnementPlcDTO;

  form: FormGroup = new FormGroup({});

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-row-parametrage-modeles-plc';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private mcplcSvc: ModelesPlcService,
              private csSvc: ConvivesService,
              private routeMapSvc: RoutemapService,
              private mcpCvSvc: McpConditionnementsVariantesService) {}

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subRoute);
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.initOpenDialogSubscription();
    this.initForm();
    this.initDataFromSupplierSubscription();
  }

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  initDataFromSupplierSubscription = () => {
    this.subRoute = this.route.parent.data
      .subscribe((data: any) => {
        if (data.modelePlcSupplier) {
          this.mcpConditionnementVarianteList = data.modelePlcSupplier.mcpConditionnementVariantesList;
          this.mcplcPrestations = data.modelePlcSupplier.mcplcPrestations;
          this.mcPlcPrestaMcPlcCVList = data.modelePlcSupplier.mcPlcPrestaMcPlcCVList;
          this.mcPlcMcpCVList = data.modelePlcSupplier.mcPlcMcpCVList;
          this.modeleConditionnementPlc = data.modelePlcSupplier.modelePlc;
          this.initDataFromCombineLastest();
        }
      });
  };

  /**
   * Récupération des données concernant les mcp conditionnements variantes et les prestations
   * pour préparer la plistbox.
   * @private
   */
  private initDataFromCombineLastest = (): void => {
    const allMcpCv$ = this.mcpCvSvc.getAllBySites();
    const allPrestations$ = this.csSvc.findAll();

    const combine$ = combineLatest([allMcpCv$, allPrestations$]);

    combine$.subscribe(response => {

      const allMcpCv = response[0].resultList || [];
      this.prestationsList = response[1].resultList || [];
      this.prestationsList = this.prestationsList.filter(p => p.actif);

      let groupedMcpCv = this.initGroupingByMcp(allMcpCv);
      this.mcpCvList = groupedMcpCv;

      // On enlève les conditionnements variantes déjà configurés :
      this.initConditionnementsConfigured();

      // On enlève les déclinaisons déjà configurées :
      this.initPrestationsConfigured();

      this.initForm();
    });
  };

  private initGroupingByMcp = (allMcpCv: ModeleConditionnementsPlatConditionnementsVariantesDTO[]) => {
    const groupingByMcp = _groupBy(allMcpCv, (mcpCv: ModeleConditionnementsPlatConditionnementsVariantesDTO) => mcpCv.modeleConditionnementPlat.libelle);
    let groupedMcpCv = Object.keys(groupingByMcp).map(libelle => {
      const mcpCv = allMcpCv.find(m => m.modeleConditionnementPlat.libelle === libelle);
      if (mcpCv) {

        const items = groupingByMcp[mcpCv.modeleConditionnementPlat.libelle]
          .map(value => {
            return {
              label: `${value.conditionnementVariante.conditionnement.libelle} ${value.conditionnementVariante.variante.libelle}`,
              value: value.id
            }
          });

        return {label: mcpCv.modeleConditionnementPlat.libelle, value: mcpCv.id, items}
      }
      return null;
    });
    return _compact(groupedMcpCv);
  };

  /**
   * Initialise les conditionnements déjà configurés
   * @private
   */
  private initConditionnementsConfigured = (): void => {
    // On enlève les modèles conditionnement plat - conditionnements variantes déjà configurés :
    if (this.mcpConditionnementVarianteList) {
      const configList = this.initGroupingByMcp(this.mcpConditionnementVarianteList);

      this.mcpCvSelectedList = this.utils.flatDeep(
        configList.map(config => {

          const itemsSelected: any[] = [];
          this.mcpCvList.forEach(k =>
            k.items.forEach(item => itemsSelected.push(item))
          );

          const selectedList: any[] = this.utils.preSelectMultiListPair(itemsSelected, config.items);
          return selectedList.map(s => s.value);
        })
      );
    }
  };

  /**
   * Initialise les prestations déjà configurées
   * @private
   */
  private initPrestationsConfigured = (): void => {
    if (this.mcplcPrestations) {
      const prestationsConfigureesList = this.mcplcPrestations.map(m => m.prestation);
      this.prestationsSelectedList = this.utils.preSelectMultiList(this.prestationsList, prestationsConfigureesList);
    }
  };

  private initForm = () => {
    this.form = new FormGroup({
      mcpCv: new FormControl(this.mcpCvSelectedList, [Validators.required]),
      prestations: new FormControl(this.prestationsSelectedList, [Validators.required])
    });
  };

  save = async (): Promise<void> => {
    const result = confirm(`Êtes-vous sûr de vouloir enregistrer la grille de configuration du modèle plc comme ceci ?
        <br/> Pour rappel, le fait de décocher un modèle de conditionnement plat ou une prestation, entraînera la suppression définitive des éléments associés.`,
      'Confirmation');
    const isConfirmed: boolean = await result;
    if(isConfirmed) {
      if (this.form.valid) {
        const mcpCvList = this.form.controls['mcpCv'].value;
        const prestationsList = this.form.controls['prestations'].value;
        this.mcplcSvc.saveRowParametrage(this.modeleConditionnementPlc, mcpCvList, prestationsList)
          .subscribe(() => {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Grille configurée avec succès.');
            this.closeDialog();
            this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GESTION_CONDITIONNEMENTS_MODELE_PLC, this.modeleConditionnementPlc.id, 'parametrage']);
          });
      }
    }
  };

  initOpenDialogSubscription = (): void => {
    this.subOpenDialog = this.mcplcSvc.creationRowParametrageAsked$.subscribe(response => {
      this.openDialog = true;
    });
  };

  canModify = (modelePlc: ModeleConditionnementPlcDTO) => modelePlc.site && this.auth2Svc.isSiteLocal(modelePlc.site.id) && this.hasIDistri;

  closeDialog = (): void => {
    this.openDialog = false;
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Associations couplées avec le modèle de conditionnement plc`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: [p1]
    };
    return dms;
  };

}
