<p-card>
  <div class="card-size">

    <p-toolbar styleClass="p-mb-4 mg-t-10">
      <ng-template pTemplate="left">
        <div class="d-flex flex-rowa align-items-center">
          <div class="mg-l-5 mg-r-5">
            <button
              pButton
              class="mg-r-10 cursor"
              icon="fas fa-cog"
              label="GÉRER LA GRILLE"
              pTooltip="Gérer la grille"
              (click)="openCreateRowParametrage()"
              [disabled]="!hasIDistri"
            >
            </button>
          </div>
        </div>
      </ng-template>

    </p-toolbar>

    <dx-data-grid
      [dataSource]="gridParametrage"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(382)"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      (onCellClick)="onCellClick($event)"
      (onCellPrepared)="onCellPrepared($event)"
      (onRowPrepared)="onRowPrepared($event)"
      #grid>
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxi-column dataField="mcpLabel" caption="Modèle de conditionnement plat" [width]="250"  [groupIndex]="0"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="170"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column dataField="varianteLabel" caption="Variante" [width]="170"
                  [filterOperations]="['contains']"
                  [allowFiltering]="true" [fixed]="true" fixedPosition="left">
      </dxi-column>
      <dxi-column *ngFor="let prestation of prestationList"
                  dataField="prestation.id"
                  cellTemplate="prestationCellTemplate"
                  [caption]="prestation.libelle">
      </dxi-column>

      <dxo-summary>
        <dxi-total-item
          *ngFor="let prestation of prestationList"
          name="SelectedRowsSummary"
          showInColumn="prestation">
        </dxi-total-item>
      </dxo-summary>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} conditionnements plc">
      </dxo-pager>

      <div [class]="cell.row.data.prestations[cell.columnIndex][cell.row.data.id].actif ? 'prestation-enabled' : 'prestation-disabled'"
           [title]="getToolTipPrestation(cell)"
           *dxTemplate="let cell of 'prestationCellTemplate'">
        <span [title]="getToolTipPrestation(cell)">&nbsp;</span>
      </div>

    </dx-data-grid>

    <dx-data-grid
      [dataSource]="[footerList]"
      height="100px"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="conditionnementLabel" caption="" [width]="170">
      </dxi-column>
      <dxi-column dataField="varianteLabel" caption="" [width]="170">
      </dxi-column>
      <dxi-column *ngFor="let prestation of prestationList"
                  cellTemplate="queueCellTemplate"
                  [width]="150"
                  [caption]="prestation.libelle">
      </dxi-column>
      <dxi-column dataField="tache" caption="" [width]="250">
      </dxi-column>
      <dxi-column dataField="remarque" caption="" [width]="200">
      </dxi-column>

      <div *dxTemplate="let cell of 'queueCellTemplate'">
        <dx-select-box
          [dataSource]="resteList"
          displayExpr="libelle"
          valueExpr="id"
          (onValueChanged)="changeQueue($event, cell.columnIndex)"
          [value]="queuesConfiguration[cell.columnIndex] && queuesConfiguration[cell.columnIndex].reste ? queuesConfiguration[cell.columnIndex].reste.id : resteList[0].id ">
        </dx-select-box>
      </div>

    </dx-data-grid>


    <div class="d-flex mg-t-10">

      <div class="mr-auto ">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        <span class="mg-r-5 font-12">N'oubliez pas d'enregistrer votre travail</span>
      </div>

      <div id="ctas">
        <yo-button-save
          [yoDisabled]="!hasIDistri"
          (onClick)="save()"
          yoType="submit">
        </yo-button-save>
      </div>
    </div>

  </div>
</p-card>

<yo-mp-row-parametrage-plc></yo-mp-row-parametrage-plc>
