import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericManageDialogService} from "../../../../core/services/generics/generic-manage-dialog.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {SignatureUtilisateurPmsDto} from "../../../../core/dtos/pms/signature-utilisateur-pms-dto";
import {
  SignatureUtilisateurPmsSavedSupplier,
  SignatureUtilisateurPmsService
} from "../../../../core/services/pms/signature-utilisateur-pms.service";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {Title} from "@angular/platform-browser";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-pms-signature-utilisateur-grille',
  templateUrl: './pms-signature-utilisateur-grille.component.html',
  styleUrls: ['./pms-signature-utilisateur-grille.component.scss']
})
export class PmsSignatureUtilisateurGrilleComponent implements OnInit, OnDestroy {
  signaturesUtilisateurList: SignatureUtilisateurPmsDto[] = [];

  hasPms = false;

  subSignatureUtilisateurSaved: Subscription;

  entityName: string;
  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.PMS + '/pms-signatures';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              public gds: GenericDatagridService,
              private i8nSvc: InternationalizationService,
              private signatureUtilisateurPmsService: SignatureUtilisateurPmsService,
              private title: Title) {}

  ngOnInit(): void {
    this.initHasPms();
    this.initSignaturesUtilisateurPms();
    this.signatureUtilisateurSavedSubscription();
    this.initProperty();
  }

  initProperty = (): void => {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
    this.entityName = this.signatureUtilisateurPmsService.getEntityName().toLowerCase();
    this.title.setTitle(this.getTitle());
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSignatureUtilisateurSaved);
  }

  getTitle = (): string => "GESTION DES SIGNATURES UTILISATEUR";

  canEdit = (): boolean => this.hasPms && this.auth2Svc.hasSitesLocaux();

  initSignaturesUtilisateurPms = (): void => {
    this.signatureUtilisateurPmsService.getAll()
      .subscribe(response => {
        this.signaturesUtilisateurList = response.resultList;
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  signatureUtilisateurSavedSubscription = (): void => {
    this.subSignatureUtilisateurSaved = this.signatureUtilisateurPmsService.signatureUtilisateurSaved$
      .subscribe((response: SignatureUtilisateurPmsSavedSupplier) => {
        if (!response.isUpdate) {
          this.signaturesUtilisateurList.push(response.signatureUtilisateur);
        }
      });
  };

  openSignatureUtilisateur = (s: SignatureUtilisateurPmsDto): void => {
    this.signatureUtilisateurPmsService.announceOpenDialog(s);
  };

  deleteValues = async (): Promise<void> => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.signaturesUtilisateurList.find(s => s.id === idSelected))
        .map((signature: SignatureUtilisateurPmsDto) => signature.id);
      this.signatureUtilisateurPmsService.deleteByIds(idsToDelete)
        .subscribe(o => {
          this.signaturesUtilisateurList = this.utils.handleIsDeletableResultWithNum(o.one, idsToDelete, this.signaturesUtilisateurList)
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS,  `La suppression a bien été prise en compte`);
        });
    }
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les signatures utilisateur PMS (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
