import {Component, Input, OnInit} from '@angular/core';
import {StockDTO} from "../../../../core/dtos/stock-dto";
import {UtilsService} from "../../../../core/utils/utils.service";
import {ModelViewMatierePremiere} from "../model-view-matiere-premiere";
import {SearchSupplierWrapper} from "../../../../core/suppliers/wrappers/search-supplier-wrapper";
import {StocksService} from "../../../../core/services/gestion-stock/stocks.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {Auth2Service} from '../../../../core/services/security/auth2.service';


@Component({
  selector: 'yo-lots',
  templateUrl: './lots.component.html',
  styleUrls: ['./lots.component.scss']
})
export class LotsComponent implements OnInit {

  _stocks: StockDTO[] = [];
  showEmpty = false;
  selectedStock: StockDTO;
  cols: any[] = [
    {field: 'actions', header: 'Actions'},
    {field: 'zdsLibelle', header: 'Emplacement'},
    {field: 'lotArticle', header: 'Lot'},
    {field: 'produitArticleLibelle', header: 'Article'},
    {field: 'fournisseur', header: 'Fournisseur'},
    {field: 'dateEntree', header: 'Date Entrée'},
    {field: 'dlc', header: 'DLC'},
    {field: 'quantite', header: 'Quantité en unité de stock'},
    {field: 'quantiteEnKilo', header: 'Quantité en kilo'},
    {field: 'prixUnitaireKilo', header: 'PU H.T au kilo'},
    {field: 'prixUnitaireUS', header: 'PU H.T en US'},
    {field: 'prixUnitaire', header: 'PU H.T en UF'},
    {field: 'modifiePar', header: 'Modifié par'},
    {field: 'dateDerniereModification', header: 'Dernière modification'}
  ];

  @Input() matierePremiere: ModelViewMatierePremiere;

  // criteres de recherche
  @Input() ssw: SearchSupplierWrapper;

  @Input() set stocks(value: StockDTO[]) {
    this._stocks = value;
    this.selectedStock = this.utils.preSelectSingleList(this._stocks, this.selectedStock);
  }

  constructor(public utils: UtilsService,
              public stockSvc: StocksService,
              private auth2Svc: Auth2Service) {
  }

  ngOnInit() {
  }

  onRowSelect($event: any) {
    console.log('onRowSelect', $event);
  }

  openDialogSortie(selectedStock: StockDTO) {
    this.selectedStock = selectedStock;
    // on ouvre le dialog que si un lot est sélectionné
    if (this.utils.isNullOrEmpty(this.selectedStock)) {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, ` Pour réaliser une sortie, il faut sélectionner un lot.`, '', 8000 );
    } else {
      this.stockSvc.announceOpenDialogSortie(this.ssw, this.selectedStock, this.matierePremiere);
    }
  }

  openDialogEditLot(selectedStock: StockDTO) {
    if (this.matierePremiere && selectedStock) {
      this.stockSvc.announceOpenDialogEditLot(this.matierePremiere, selectedStock);
    }
  }

}
