import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CatalogueAchatDTO} from "../../../core/dtos/catalogue-achat-dto";
import {find as _find} from "lodash";
import {UniteDeProduction__SecteurFournisseurDTO} from "../../../core/dtos/unite-de-production__secteur-fournisseur-dto";
import {UtilsService} from "../../../core/utils/utils.service";
import {GroupeAchatCaViewModel} from "../../../core/models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";


@Component({
  selector: 'yo-grille-remplacer-article',
  templateUrl: './grille-remplacer-article.component.html',
  styleUrls: ['./grille-remplacer-article.component.scss']
})
export class GrilleRemplacerArticleComponent implements OnInit {

  _selectedGacaViewModel: GroupeAchatCaViewModel;
  _articlesDisponibles: CatalogueAchatDTO[]= [];
  _udpSfPrefereList: UniteDeProduction__SecteurFournisseurDTO[] = [];

  @Input() set selectedGacaViewModel(value: GroupeAchatCaViewModel) {
    this._selectedGacaViewModel = value;
  }

  get selectedGacaViewModel(): GroupeAchatCaViewModel {
    return this._selectedGacaViewModel;
  }

  @Input() set articlesDisponibles(value: CatalogueAchatDTO[]) {
    this._articlesDisponibles = value;
  }

  get articlesDisponibles(): CatalogueAchatDTO[] {
    return this._articlesDisponibles;
  }

  @Input() unicityIdForGroupeName: number;

  @Input() set udpSfPrefereList(value: UniteDeProduction__SecteurFournisseurDTO[]) {
    this._udpSfPrefereList = value;
  }

  get udpSfPrefereList(): UniteDeProduction__SecteurFournisseurDTO[] {
    return this._udpSfPrefereList
  }

  @Output() updatePrixUnitaireUF = new EventEmitter<UpdateGacaViewModel>();

  constructor(private utils: UtilsService) {}

  ngOnInit(): void {
  }


  isFournisseurPrefere(filialeLibelle: string) {

    const elt = _find(this._udpSfPrefereList, {'fournisseurLibelle': filialeLibelle});

    return !this.utils.isNullOrEmpty(elt);
  }


  updateGacaViewModel($event: GroupeAchatCaViewModel) {

    this.selectedGacaViewModel = $event;
    this.updatePrixUnitaireUF.emit({
      prixUF: this.selectedGacaViewModel.prixUF,
      prixUC: this.selectedGacaViewModel.prixUC,
      idGaca: this.selectedGacaViewModel.id,
      catalogueAchatId: this.selectedGacaViewModel.idCatalogueAchat,
      modificationManuelle: true
    });

  }
}

export interface UpdateGacaViewModel {
  prixUF: number;
  prixUC: number;
  idGaca: number;
  catalogueAchatId: number;
  modificationManuelle: boolean;
}
