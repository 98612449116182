import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {EnvironnementResolverService, EnvironnementSupplier} from '../../environnement-resolver.service';
import {ActivatedRoute} from '@angular/router';
import {EnvironnementDTO} from '../../../core/dtos/environnement-dto';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SiteDTO} from '../../../core/dtos/site-dto';
import {SiteService} from '../../../core/site.service';
import {SelectItem, TreeNode} from 'primeng/api';
import {EnvironnementSitesVisiblesDTO} from '../../../core/dtos/enrironnement-sites-visibles-dto';
import {EnvironnementService} from '../../../core/services/entities/gestionenvironnements/environnement.service';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ContratmenuDTO} from '../../../core/dtos/contratmenu-dto';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {TreeNodeData} from '../../../core/tree-node-data';
import {BaseComponent} from '../../../base-component';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-ficheidentite-environnement',
  templateUrl: './ficheidentite-environnement.component.html',
  styleUrls: ['./ficheidentite-environnement.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FicheidentiteEnvironnementComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {


  sitesStatiques: SelectItem[];
  selectedsitesStatiques: number = -1;

  sitesVisibles: SelectItem[];
  sitesPrincipaux: SelectItem[];
  selectedsitesVisibles: number = -1;
  selectedsitesPrincipaux: number = -1;

  subFicheIdentite: Subscription;
  subDeleteEnvironnement: Subscription;
  subEnvironnement: Subscription;

  // La form englobante
  formGroupCtrl: FormGroup;

  // Champs Environnement
  libelleCtrl: FormControl;
  codeCtrl: FormControl;

  // Sites
  listeAllSitesActifs: SiteDTO[];

  listeAllContratsMenusActifs: ContratmenuDTO[];
  // pnMatrix: Map<number, Map<number, ProduitnomenclatureDTO>>;
  mapContratsMenus: Map<number, ContratmenuDTO[]>;

  /**
   * l'Environnement qu'on est en train d'éditer.
   */
  @Input() yoEnvironnement: any;

  sitePrincipal: SiteDTO = new SiteDTO();

  siteReferent: SiteDTO = null;

  rootContratsMenusSitePrincipaux: TreeNode;
  rootContratsMenusSiteReferent: TreeNode;

  selectedContratsMenusSitePrincipal: TreeNode[] = [];

  selectedContratsMenusSiteReferent: TreeNode[] = [];

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private siteService: SiteService,
              private environnementService: EnvironnementService,
              private environnementResolverService: EnvironnementResolverService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef) {
    super();
  }

  static buildMapContratsMenus = (listeAllSitesActifs: SiteDTO[], listeAllContratsMenusActifs: ContratmenuDTO[]): Map<number, ContratmenuDTO[]> => {

    let map = new Map<number, ContratmenuDTO[]>();
    listeAllSitesActifs.forEach(siteDTO => {
      let contratmenuDTOArray: ContratmenuDTO[] = FicheidentiteEnvironnementComponent.findContratMenuBySiteId(siteDTO.id, listeAllContratsMenusActifs);
      map.set(siteDTO.id, contratmenuDTOArray);
    });

    return map;
  };

  /**
   * Renvoie un tableau contenant les {@link ContratmenuDTO} issus du tableau arrayContratmenuDTO passé en paramètre
   * et dont le champ siteId est égal au paramètre siteId.
   * @param {number} siteId
   * @param {ContratmenuDTO[]} arrayContratmenuDTO
   * @returns {ContratmenuDTO[]}
   */
  static findContratMenuBySiteId = (siteId: number, arrayContratmenuDTO: ContratmenuDTO[]): ContratmenuDTO[] => {
    let contratArray = [];
    arrayContratmenuDTO.forEach(contratmenuDTO => {
      if (contratmenuDTO.site.id == siteId) {
        contratArray.push(contratmenuDTO);
      }

    })
    return contratArray;
  };

  static createTreeNode = (label: string, data: TreeNodeData, selectable: boolean, children: TreeNode[]): TreeNode => {
    let treeNode: TreeNode = {
      label: label,
      data: data,
      children: children,
      selectable: selectable,
      expanded: true
    };
    return treeNode;
  };

  ngOnInit() {
    this.siteReferent = new SiteDTO();
    this.rootContratsMenusSiteReferent = this.createMenusTreeSitesPrincipaux(null);
    this.rootContratsMenusSitePrincipaux = this.createMenusTreeSitesPrincipaux(null);

    this.initialisationFormControls();

    this.ficheIdentiteSubscription();

    this.environnementSubscription();

  }

  environnementSubscription = () => {

    this.subEnvironnement = this.environnementService.environnementSubjectToCreate$.subscribe(() => {
      this.yoEnvironnement = new EnvironnementDTO();
      this.sitesVisibles = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, false);
      this.sitesPrincipaux = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, true);
      this.rootContratsMenusSitePrincipaux = this.createMenusTreeSitesPrincipaux(null);
      this.rootContratsMenusSiteReferent = this.createMenusTreeSitesPrincipaux(null);
    })
  };

  /**
   * Initialisation des contrôles de la form
   */
  initialisationFormControls = (): void => {
    // Initialisation des champs Site
    this.libelleCtrl = this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]);

    this.codeCtrl = this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]);


    // Initialisation de la form
    this.formGroupCtrl = this.fb.group({
      environnementGroup: this.fb.group({
        libelle: this.libelleCtrl,
        code: this.codeCtrl
      }),
    });

  };

  creationListeSitesSelectItemStatiques = (listeSites: SiteDTO[]): SelectItem[] => {
    let liste: SelectItem[] = [];
    listeSites.forEach(siteDTO => {
      liste.push({label: siteDTO.libelle, value: siteDTO.id});
    });
    liste.sort(this.sortSelectItemByLabel);
    return liste;
  };

  creationListeSitesDTOVisibles = (environnementDTO: any, seulementLocaux: boolean): SiteDTO[] => {
    let liste: SiteDTO[] = [];

    for (let environnementSiteVisible of environnementDTO.environnementSitesVisiblesDTOList) {

      if (seulementLocaux) {
        if (environnementSiteVisible.local) {
          liste.push(environnementSiteVisible.site);
        }
      } else {
        if (!environnementSiteVisible.local) {
          liste.push(environnementSiteVisible.site);
        }
      }
    }
    return liste;

  };

  creationListeSitesSelectItemVisibles(environnementDTO: any, seulementLocaux: boolean): SelectItem[] {
    let liste: SelectItem[] = [];

    for (let environnementSiteVisible of environnementDTO.environnementSitesVisiblesDTOList) {

      if (seulementLocaux) {
        if (environnementSiteVisible.local) {
          liste.push({
            label: environnementSiteVisible.site.libelle,
            value: environnementSiteVisible.site.id
          });

        }
      } else {
        if (!environnementSiteVisible.local) {
          liste.push({
            label: environnementSiteVisible.site.libelle,
            value: environnementSiteVisible.site.id
          });

        }

      }

    }
    return liste;
  }

  save = () => {
    this.environnementService.save(this.yoEnvironnement).subscribe(data => {
        let result: ResponseWrapper<boolean> = data;
        if (result.inError) {
          this.yoEnvironnement = result.one;
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.ERROR, `Sauvegarde impossible : ${result.resultMessage}`, '', 8000);
        } else {
          this.yoEnvironnement = result.one;
          this.refreshEnvironmentWhenUserHasSameEnvironment();
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Environnement '${this.yoEnvironnement.libelle}' enregistré.`, '', 5000);
          this.formGroupCtrl.markAsPristine();
          this.environnementResolverService.environnementAnnounceSource.next(this.yoEnvironnement);
        }
      },
      err => {
        this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.INFO, `Impossible d'enregistrer '${this.yoEnvironnement.libelle}'.`, '', 5000);
      });
  };

  refreshEnvironmentWhenUserHasSameEnvironment = () => {
    if(this.auth2Svc.utilisateur.iddefaultenvironnement === this.yoEnvironnement.id)
    this.auth2Svc.changementEnvironnement(this.yoEnvironnement);
  };

  getNextOrdre = (environnementSitesVisiblesDTOArray: EnvironnementSitesVisiblesDTO[]): number => {
    let maxOrdre = 0;
    for (let environnementSitesVisiblesDTO of environnementSitesVisiblesDTOArray) {
      if (environnementSitesVisiblesDTO.ordre > maxOrdre) {
        maxOrdre = environnementSitesVisiblesDTO.ordre;
      }
    }

    return maxOrdre + 1;
  };

  statiquesVersVisibles = (versPrincipaux: boolean) => {
    let index = this.selectedsitesStatiques;

    // Recherche du SiteDTO en fonction de l'index
    let siteDTO: SiteDTO = this.findSiteDTOById(index, this.listeAllSitesActifs);

    // Si trouvé
    if (siteDTO != null) {
      let index2 = this.findIndexBySiteDTO(siteDTO, this.yoEnvironnement.environnementSitesVisiblesDTOList);
      if (index2 < 0) {
        let environnementSitesVisiblesDTO: EnvironnementSitesVisiblesDTO = new EnvironnementSitesVisiblesDTO();
        // let environnementSitesVisiblesDTO = new EnvironnementSitesVisiblesDTO();
        environnementSitesVisiblesDTO.id = 0;
        environnementSitesVisiblesDTO.ordre = this.getNextOrdre(this.yoEnvironnement.environnementSitesVisiblesDTOList);
        environnementSitesVisiblesDTO.site = siteDTO;


        // environnementSitesVisiblesDTO.environnementDTO = this.yoEnvironnement;

        environnementSitesVisiblesDTO.environnementDTO = new EnvironnementDTO();
        environnementSitesVisiblesDTO.environnementDTO.id = this.yoEnvironnement.id;
        environnementSitesVisiblesDTO.environnementDTO.libelle = this.yoEnvironnement.libelle;
        environnementSitesVisiblesDTO.environnementDTO.environnementUtilisateurDTOList = null;
        environnementSitesVisiblesDTO.environnementDTO.environnementSitesVisiblesDTOList = null;

        environnementSitesVisiblesDTO.local = versPrincipaux;

        this.yoEnvironnement.environnementSitesVisiblesDTOList.push(environnementSitesVisiblesDTO);
      } else {
        let environnementSitesVisiblesDTO: EnvironnementSitesVisiblesDTO = this.yoEnvironnement.environnementSitesVisiblesDTOList[index2];
        environnementSitesVisiblesDTO.local = versPrincipaux;
      }
    }

    this.sitesVisibles = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, false);

    this.sitesPrincipaux = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, true);
    this.selectedsitesStatiques = -1;
    this.updateSitePrincipaux();

  };

  findIndexBySiteDTO = (siteDTO: SiteDTO, environnementSitesVisiblesDTOArray: EnvironnementSitesVisiblesDTO[]): number => {
    let index = 0;
    for (let environnementSitesVisiblesDTO of environnementSitesVisiblesDTOArray) {
      if (siteDTO.id == environnementSitesVisiblesDTO.site.id) {
        return index;
      } else {
        index++;
      }

    }
    return -1;

  };


  messageAjouterAuxSitesReferents = (): string => {
    // Recherche du SiteDTO en fonction de l'index
    let siteDTO: SiteDTO = this.findSiteDTOById(this.selectedsitesStatiques, this.listeAllSitesActifs);

    let siteName = '';
    if (siteDTO != null) {
      siteName = siteDTO.libelle;
    }

    return 'Ajout du site \'' + siteName + '\' à la liste des sites référents';

  };

  messageAjouterAuxSitesLocaux = (): string => {
    // Recherche du SiteDTO en fonction de l'index
    let siteDTO: SiteDTO = this.findSiteDTOById(this.selectedsitesStatiques, this.listeAllSitesActifs);

    let siteName = '';
    if (siteDTO != null) {
      siteName = siteDTO.libelle;
    }

    return 'Ajout du site \'' + siteName + '\' à la liste des sites locaux';

  };

  messageRetirerDesSitesLocauxOuReferents = (seulementLocaux): string => {
    // Recherche du SiteDTO en fonction de l'index
    let siteDTO: SiteDTO = null;
    if (seulementLocaux) {
      siteDTO = this.findSiteDTOById(this.selectedsitesPrincipaux, this.listeAllSitesActifs);
    } else {
      siteDTO = this.findSiteDTOById(this.selectedsitesVisibles, this.listeAllSitesActifs);
    }

    let suffixe = '';
    if (seulementLocaux) {
      suffixe = '\' de la liste des sites locaux.';
    } else {
      suffixe = '\' de la liste des sites référents.';
    }

    let siteName = '';
    if (siteDTO != null) {
      siteName = siteDTO.libelle;
    }

    return 'Retrait du site \'' + siteName + suffixe;
  };

  visiblesVersStatiques = (seulementLocaux: boolean) => {

    let index = -1;
    if (seulementLocaux) {
      index = this.selectedsitesPrincipaux;
    } else {
      index = this.selectedsitesVisibles;
    }

    // Recherche du SiteDTO en fonction de l'index
    let siteDTO: SiteDTO = null;
    siteDTO = this.findSiteDTOById(index, this.listeAllSitesActifs);

    // Si trouvé
    if (siteDTO != null) {
      // Il faut retirer ce site de this.yoEnvironnement.environnementSitesVisiblesDTOList
      let index2 = this.findIndexBySiteDTO(siteDTO, this.yoEnvironnement.environnementSitesVisiblesDTOList);

      this.yoEnvironnement.environnementSitesVisiblesDTOList.splice(index2, 1);
      this.checkOrdre(this.yoEnvironnement.environnementSitesVisiblesDTOList);
    }

    this.sitesVisibles = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, false);
    this.sitesPrincipaux = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, true);
    // this.sitesVisibles = this.creationListeSitesSelectItemVisibles2(this.listeAllSitesActifs, this.yoEnvironnement);

    // this.selectedsitesVisibles = [];
    this.selectedsitesVisibles = -1;
    this.selectedsitesPrincipaux = -1;
    this.updateSitePrincipaux();

  };

  /**
   * Vérifie que le tableau d'EnvironnementSitesVisiblesDTO est correctement classé :
   * par champ EnvironnementSitesVisiblesDTO.ordre croissant.
   * @param {EnvironnementSitesVisiblesDTO[]} environnementSitesVisiblesDTOArray
   * @returns {boolean}
   */
  checkOrdre = (environnementSitesVisiblesDTOArray: EnvironnementSitesVisiblesDTO[]): boolean => {

    let maxOrdre = -1;
    for (let environnementSitesVisiblesDTO of environnementSitesVisiblesDTOArray) {
      if (environnementSitesVisiblesDTO.ordre <= maxOrdre) {
        console.log('checkOrdre : ' + false);
        return false;
      } else {
        maxOrdre = environnementSitesVisiblesDTO.ordre;
      }
    }


    return true;

  };

  findSiteDTOById = (id: number, siteDTOArray: SiteDTO[]): SiteDTO => {

    for (let siteDTO of siteDTOArray) {
      if (id == siteDTO.id) {
        return siteDTO;
      }
    }
    ;

    return null;

  };

  /**
   * Mise à jour du champ sitePrincipal en fonction de la liste des Sites visible de l'environnement
   * this.yoEnvironnement.
   */
  updateSitePrincipaux = () => {

    if (this.yoEnvironnement.environnementSitesVisiblesDTOList.length > 0) {

      this.sitePrincipal = this.yoEnvironnement.environnementSitesVisiblesDTOList[0].site;
      let arrayContratsMenus: ContratmenuDTO[] = this.mapContratsMenus.get(this.sitePrincipal.id);
      this.selectedContratsMenusSitePrincipal = [];
      let sitesPrincipauxDTOs: SiteDTO[] = this.creationListeSitesDTOVisibles(this.yoEnvironnement, true);
      this.rootContratsMenusSitePrincipaux = this.createMenusTreeSitesPrincipaux(sitesPrincipauxDTOs);
      sitesPrincipauxDTOs = this.creationListeSitesDTOVisibles(this.yoEnvironnement, false);
      this.rootContratsMenusSiteReferent = this.createMenusTreeSitesPrincipaux(sitesPrincipauxDTOs);

      console.log('updateSitePrincipaux : this.selectedContratsMenusSitePrincipal = [];');


      this.sitesVisibles = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, false);
      this.sitesPrincipaux = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, true);
      this.selectedsitesVisibles = -1;
    } else {
      this.sitePrincipal = new SiteDTO();
      this.siteReferent = new SiteDTO();

      this.sitesVisibles = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, false);
      this.sitesPrincipaux = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, true);
      this.selectedsitesVisibles = -1;

      this.selectedContratsMenusSitePrincipal = [];

      this.rootContratsMenusSitePrincipaux = this.createMenusTreeSitesPrincipaux(null);
      this.rootContratsMenusSiteReferent = this.createMenusTreeSitesPrincipaux(null);


    }
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFicheIdentite);
    this.utils.unsubscribe(this.subDeleteEnvironnement);
    this.utils.unsubscribe(this.subEnvironnement);
  }

  log = () => {
    console.log('change detection');
  };

  createMenusTreeSitesPrincipaux = (siteDTOlist: SiteDTO[]): TreeNode => {

    // La racine de l'arbre qui sera renvoyée par la fonction.
    let root: TreeNode = {
      children: []
    };

    if (this.utils.isCollectionNullOrEmpty(siteDTOlist)) {
      return root;
    }

    this.selectedContratsMenusSitePrincipal = [];
    // console.log("createMenusTreeSitesPrincipaux : this.selectedContratsMenusSitePrincipal = [];");


    let arrayContratsMenus: ContratmenuDTO[] = this.listeAllContratsMenusActifs;


    siteDTOlist.forEach(siteDTO => {
      arrayContratsMenus.forEach(contratmenuDTO => {

        let childrenTreeNodeContratMenuConvives: TreeNode[] = [];


        contratmenuDTO.contratMenuConvives.forEach(contratMenuConviveDTO => {
          // let contratMenuConvive: TreeNode = FicheidentiteEnvironnementComponent.createTreeNode(contratMenuConviveDTO.libelle + "(" + contratMenuConviveDTO.id + ")" + "site id = " + contratmenuDTO.site.id, contratMenuConviveDTO.id.toString(), null);

          if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {
            let treeNodeData: TreeNodeData;

            treeNodeData = new TreeNodeData(contratMenuConviveDTO, 'ContratMenuConviveDTO');
            let contratMenuConvive: TreeNode;

            if (contratMenuConviveDTO.modeleNutritionnel != null) {
              contratMenuConvive = FicheidentiteEnvironnementComponent.createTreeNode('(' + contratMenuConviveDTO.id + ')' +
                contratMenuConviveDTO.convive.libelle +
                ' - Modèle nutri : ' + contratMenuConviveDTO.modeleNutritionnel.libelle + ' ' + contratMenuConviveDTO.site.libelle, treeNodeData, false, null);
            } else {
              contratMenuConvive = FicheidentiteEnvironnementComponent.createTreeNode('(' + contratMenuConviveDTO.id + ')' +
                contratMenuConviveDTO.convive.libelle, treeNodeData, false, null);
            }

            childrenTreeNodeContratMenuConvives.push(contratMenuConvive);
            if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {
              this.selectedContratsMenusSitePrincipal.push(contratMenuConvive);
            }
          }
        });

        if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {

          let treeNodeData: TreeNodeData;
          treeNodeData = new TreeNodeData(contratmenuDTO, 'ContratmenuDTO');

          // let treeNodeContratMenu: TreeNode = FicheidentiteEnvironnementComponent.createTreeNode(contratmenuDTO.libelle + "(" + contratmenuDTO.id + ")" + "site id = " + contratmenuDTO.siteId, contratmenuDTO.id.toString(), childrenTreeNodeContratMenuConvives);
          let treeNodeContratMenu: TreeNode = FicheidentiteEnvironnementComponent.createTreeNode('(' + contratmenuDTO.id + ')' + contratmenuDTO.libelle + ' - ' + contratmenuDTO.code + '(' + contratmenuDTO.site.libelle + ')'
            , treeNodeData, false, childrenTreeNodeContratMenuConvives);

          root.children.push(treeNodeContratMenu);
          if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {
            this.selectedContratsMenusSitePrincipal.push(treeNodeContratMenu);
          }
        }


      });
    });
    return root;
  };

  change = () => {
    this.siteReferent.libelle = '';
    this.siteReferent.id = 0;
  };

  public getMenusEffectifsRepasSLink = (idsContratMenuConviveRepas: number[], strDates: string[]): string => `dolrest/gestionmenus/menusEffectifsRepas?idsContratMenuConviveRepas=${idsContratMenuConviveRepas}&strDates=${strDates}`;

  /**
   * Recherche d'un {@link ContratMenuConviveDTO} dans un tableau de {@link ContratMenuConviveDTO}s connaisssant son id
   * @param {number} id
   * @param {ContratMenuConviveDTO[]} arrayContratMenuConviveDTO
   * @returns {ContratMenuConviveDTO}
   */
  findContratsMenusConvivesDTOByIdFromArrayContratMenuConviveDTO = (id: number, arrayContratMenuConviveDTO: ContratMenuConviveDTO[]): ContratMenuConviveDTO => {
    for (let contratMenuConviveDTO of arrayContratMenuConviveDTO) {
      if (contratMenuConviveDTO.id == id) {
        return contratMenuConviveDTO;
      }

    }
    return null;
  };

  /**
   * Renvoie la différence entre les 2 listes (i.e. le tableau des éléments appartenant au preier tableau mais pas au second.
   * @param {ContratMenuConviveDTO[]} oldArray
   * @param {ContratMenuConviveDTO[]} newArray
   * @returns {ContratMenuConviveDTO[]}
   */
  diff = (oldArray: ContratMenuConviveDTO[], newArray: ContratMenuConviveDTO[]): ContratMenuConviveDTO[] => {
    let array: ContratMenuConviveDTO[] = [];
    if (oldArray != null) {
      oldArray.forEach(contratMenuConviveDTO => {
        let contratMenuConvive: ContratMenuConviveDTO = this.findContratsMenusConvivesDTOByIdFromArrayContratMenuConviveDTO(contratMenuConviveDTO.id, newArray);
        if (contratMenuConvive == null) {
          array.push(contratMenuConviveDTO);
        }
      })
    }
    return array;
  };


  confirmDeleteEnvironnement = async (environnementDTO: EnvironnementDTO) => {
    const result = confirm('Etes vous sûr de vouloir supprimer l\'environnement \'' + environnementDTO.libelle + '\' ?',
      'Suppression de l\'environnement');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.delete(environnementDTO);
    }
  };

  delete = (environnementDTO: EnvironnementDTO): Subscription => {
    this.subDeleteEnvironnement = this.environnementService.delete(environnementDTO.id)
      .subscribe(
        data => {
          let result : ResponseWrapper<boolean> = data;
          if(result.inError){
            this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.ERROR,  `Suppression impossible : ${result.resultMessage}`, '', 8000);
          }else{
            this.environnementService.announceEnvironnementToDelete(environnementDTO);
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Environnement ${environnementDTO.libelle} supprimé.`, '', 8000);
            this.utils.sidenav = false;
            this.utils.setTitle('Gestion des environnements');
          }
        },
        error => {
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.ERROR, `Impossible de supprimer l'environnement ${environnementDTO.libelle}.Vérifiez qu'il n'est pas utilisé dans l'application`, '', 8000);
        }
      );
    return this.subDeleteEnvironnement;
  };


  ficheIdentiteSubscription = () => {
    this.subFicheIdentite = this.route.data.subscribe((data: { environnementSupplier: EnvironnementSupplier }) => {

      this.sitePrincipal = new SiteDTO();
      this.sitePrincipal.libelle = '';
      this.sitePrincipal.id = 0;


      this.siteReferent = new SiteDTO();
      this.siteReferent.libelle = '';
      this.siteReferent.id = 0;


      this.sitesVisibles = [];
      this.sitesPrincipaux = [];

      this.selectedsitesVisibles = -1;

      this.sitesStatiques = [];

      this.selectedsitesStatiques = -1;

      this.listeAllSitesActifs = data.environnementSupplier.listeAllSitesActifs;

      // Réinitialisation à un arbre vide.
      this.rootContratsMenusSitePrincipaux = this.createMenusTreeSitesPrincipaux(null);

      this.listeAllContratsMenusActifs = data.environnementSupplier.listeAllContratsMenusActifs;

      // Elimination des Contrats menus n'ayant pas de contrat menu convive sous jacent.
      this.listeAllContratsMenusActifs = this.listeAllContratsMenusActifs.filter(contratMenuDTO =>
        contratMenuDTO.contratMenuConvives.length > 0
      );

      this.mapContratsMenus = FicheidentiteEnvironnementComponent.buildMapContratsMenus(this.listeAllSitesActifs, this.listeAllContratsMenusActifs);
      if (null == data.environnementSupplier.environnementDTO) {

        this.yoEnvironnement = new EnvironnementDTO();
        this.sitesVisibles = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, false);
        this.sitesPrincipaux = this.creationListeSitesSelectItemVisibles(this.yoEnvironnement, true);

        this.utils.setTitle('Fiche d\'identité');
      } else {
        this.yoEnvironnement = data.environnementSupplier.environnementDTO;
        this.updateSitePrincipaux();
        this.utils.setTitle('Fiche d\'identité', this.yoEnvironnement.libelle);
        data.environnementSupplier.environnementDTO = undefined;
      }
      this.sitesStatiques = this.creationListeSitesSelectItemStatiques(this.listeAllSitesActifs);
      this.selectedsitesStatiques = -1;
    });
  };

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

}
