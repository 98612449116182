import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ContratmenuDTO} from '../../core/dtos/contratmenu-dto';
import {UtilsService} from '../../core/utils/utils.service';
import {ContratMenuService} from '../../core/services/gestioncontrats/contrat-menu.service';
import {Subscription} from 'rxjs';
import {find as _find, remove as _remove} from 'lodash'
import {Auth2Service} from '../../core/services/security/auth2.service';
import {ContratMenuConviveDTO} from '../../core/dtos/contratmenuconvive-dto';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {ContratsMenusConvivesService} from '../../core/services/gestioncontrats/contrats-menus-convives.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {Router} from '@angular/router';
import {GenericManageDialogService} from '../../core/services/generics/generic-manage-dialog.service';
import {ViewModelContrat} from './contrat-viewmodel';
import {ResponseWrapper} from '../../core/suppliers/wrappers/response-wrapper';
import {ObjectDTO} from '../../core/dtos/object-dto';
import {HELP_FOLDERS, MESSAGE_CREATION_OBJET_IMPOSSIBLE, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {OverlayPanel} from 'primeng/overlaypanel';
import {DxDataGridComponent} from "devextreme-angular";
import {confirm} from "devextreme/ui/dialog";


@Component({
  selector: 'yo-grille-contrats',
  templateUrl: './grille-contrats.component.html',
  styleUrls: ['./grille-contrats.component.scss']
})
export class GrilleContratsComponent implements OnInit, OnDestroy {

  /**
   * La p-overlayPanel pour la duplication d'une prestation
   */
  @ViewChild('opDuplication') opDuplication: OverlayPanel;
  @ViewChild('grid') grid: DxDataGridComponent;

  loading: boolean = false;
  totalRecords: number = 0;

  contratsMenus: ContratmenuDTO[];
  viewModelContratList: ViewModelContrat[];

  displayDialogContratForm: boolean = false;

  offreAlimentaireSelected: ContratmenuDTO;
  prestationSelected: ContratMenuConviveDTO;
  prestations: ContratMenuConviveDTO[] = [];

  subContratsMenus: Subscription;
  subSavedContratMenu: Subscription;
  subDeletedContratMenu: Subscription;
  subSavedContratMenuConvive: Subscription;
  subDeletedContratMenuConvive: Subscription;

  pathFile: string = HELP_FOLDERS.CONTRATS + '/contrats';

  constructor(public utils: UtilsService,
              public contratMenuSvc: ContratMenuService,
              private contratMenuConviveSvc: ContratsMenusConvivesService,
              private routeMapSvc: RoutemapService,
              private gmds: GenericManageDialogService,
              private router: Router,
              public auth2Svc: Auth2Service,
              private gfs: GenericFormService,
              public gds: GenericDatagridService) {
  }

  private refreshViewModelList = (contratsMenus: ContratmenuDTO[]): ViewModelContrat[] => {
    const vmContratList = this.contratMenuSvc.mapDtoListToViewModelList(contratsMenus);
    this.totalRecords = vmContratList.length;

    return vmContratList;
  };


  ngOnInit() {

    //Chargement des objets pour la datagrid
    this.contratsMenusSubscription();
    //Abonnement à la réponse d'enregistrement retournée par le back
    this.savedContratMenuSubscription();
    //Abonnement au contrat menu supprimé
    this.deletedContratMenuSubscription();
    // Abonnement au contrat menu convive créé ou modifié
    this.savedContratMenuConviveSubscription();
    // Abonnement au contrat menu convive supprimé
    this.deletedContratMenuConviveSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subContratsMenus);
    this.utils.unsubscribe(this.subDeletedContratMenu);
    this.utils.unsubscribe(this.subSavedContratMenuConvive);
    this.utils.unsubscribe(this.subDeletedContratMenuConvive);
  }

  /**
   * Chargement des objets pour la datagrid
   */
  contratsMenusSubscription = () => {
    this.subContratsMenus = this.contratMenuSvc.objects$.subscribe(contratsMenus => {
      this.contratsMenus = [...contratsMenus];
      this.viewModelContratList = this.refreshViewModelList(this.contratsMenus);
    });
  };

  /**
   * Abonnement à la réponse d'enregistrement retournée par le back
   */
  savedContratMenuSubscription = () => {
    this.subSavedContratMenu = this.contratMenuSvc.contratMenuCreated$.subscribe((instanceObj: any) => {
      if (instanceObj.one.id && !this.contratsMenus.find(cm => cm.id === instanceObj.one.id)) {
        this.contratsMenus.push(instanceObj.one);
        this.viewModelContratList = this.contratMenuSvc.mapDtoListToViewModelList(this.contratsMenus);
        this.grid.instance.refresh();
      }
    });
  };

  /**
   * Abonnement au contrat menu supprimé
   */
  deletedContratMenuSubscription = () => {
    this.subDeletedContratMenu = this.contratMenuConviveSvc.deletedDto$.subscribe(response => {
      this.gds.getAll(this.contratMenuSvc.getEntityName())
        .subscribe(contrats => {
          this.contratsMenus = contrats.resultList;
          this.viewModelContratList = this.contratMenuSvc.mapDtoListToViewModelList(this.contratsMenus);
          this.grid.instance.refresh();
        });
    });
  };

  /**
   *   Abonnement au contrat menu convive créé ou modifié
   */
  savedContratMenuConviveSubscription = () => {
    this.subSavedContratMenuConvive = this.contratMenuConviveSvc.savedDto$.subscribe(response => {
      this.contratsMenus = this.refreshDatagridPrimeNgAfterCmcSave(response, this.contratsMenus);
      this.viewModelContratList = this.refreshViewModelList(this.contratsMenus);
    });
  };

  /**
   * Abonnement au contrat menu convive supprimé
   */
  deletedContratMenuConviveSubscription = () => {
    this.subDeletedContratMenuConvive = this.gfs.deletedId$.subscribe(response => {
      this.contratsMenus = this.refreshDatagridPrimeNgAfterCmcDelete(response, this.contratsMenus);
      this.viewModelContratList = this.refreshViewModelList(this.contratsMenus);
      this.utils.sidenav = false;
    });
  };

  refreshDatagridPrimeNgAfterCmcDelete = (response: ResponseWrapper<number>, contratsMenus: ContratmenuDTO[]): ContratmenuDTO[] => {
    contratsMenus = _remove(contratsMenus, cmc => cmc.id !== response.one);
    return contratsMenus;
  };

  refreshDatagridPrimeNgAfterCmcSave = (response: ResponseWrapper<ObjectDTO>, contratsMenus: ContratmenuDTO[]): ContratmenuDTO[] => {
    if (!this.utils.isNullOrEmpty(response)) {
      const cmc = response.one as ContratMenuConviveDTO;

      if (!this.utils.isNullOrEmpty(cmc)) {
        const contratMenu = _find(contratsMenus, {'id': cmc.contratMenuId});

        // si pas de contrat menu convive on rajoute
        if (this.utils.isCollectionNullOrEmpty(contratMenu.contratMenuConvives)) {
          contratMenu.contratMenuConvives = [];
          contratMenu.contratMenuConvives.push(cmc);
        }
        // si on a des contrats menus convives sur le contrat menu, on affecte le cmc
        else {
          let found = false;
          contratMenu.contratMenuConvives = contratMenu.contratMenuConvives.map(item => {
            if (item.id === cmc.id) {
              item = cmc;
              found = true;
            }
            return item;
          });

          if (!found) {
            contratMenu.contratMenuConvives.unshift(cmc);
          }
        }

        //on reaffecte le contrat menu
        contratsMenus = contratsMenus.map(item => {
          if (item.id === cmc.contratMenuId) {
            item = contratMenu;
          }
          return item;
        })
      }
    }
    return contratsMenus;
  };


  openEditDialog = (vmContrat: ViewModelContrat) => {
    const contratMenu = _find(this.contratsMenus, {'id': vmContrat.id});
    this.displayDialogContratForm = true;
    this.contratMenuSvc.announceContratsMenuEdit(contratMenu);
  };

  openCreateDialog = () => {
    this.displayDialogContratForm = true;
    this.contratMenuSvc.announceContratsMenuCreate(this.contratMenuSvc.createEmptyDTO());
  };


  openDeleteDialog = async (vmContrat: ViewModelContrat) => {
    const res = await confirm("La suppression de l'offre alimentaire est irréversible, toutes les données concernant l'offre seront perdues. Confirmez-vous la suppression de cette offre alimentaire ?", "Suppression d'une offre alimentaire");
    if (res) {
      this.contratMenuSvc.deleteOffreAlimentaire(vmContrat.id)
        .subscribe(() => {
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Suppression Réussie');
          let r = new ResponseWrapper<number>();
          r.one = vmContrat.id;
          this.contratsMenus = this.refreshDatagridPrimeNgAfterCmcDelete(r, this.contratsMenus);
          this.viewModelContratList = this.refreshViewModelList(this.contratsMenus);
        });
    }
  };


  openContratMenuConvive = (cmc: ContratMenuConviveDTO, vmContrat: ViewModelContrat) => {

    const contratMenu = _find(this.contratsMenus, {'id': vmContrat.id});
    this.contratMenuSvc.openContratMenuConvive(cmc, contratMenu);
  };

  canCreate = (): boolean => {
    let response = this.auth2Svc.hasSitesLocaux();
    return response;
  };

  /**
   * Tooltip associé au bouton 'CRÉER UN CONTRAT'
   */
  tooltipBoutonCreer = (): string => {
    let response: string;
    if (this.canCreate()) {
      response = "";
    } else {
      response = MESSAGE_CREATION_OBJET_IMPOSSIBLE;
    }
    return response;
  };

  /**
   * Indique si l'utilisateur connecté peut créer un {@link ContratmenuDTO}.
   * @param viewModelContrat : {@link ContratmenuDTO} concerné.
   */
  canCreateContratMenuConvive = (viewModelContrat: ViewModelContrat): boolean => this.auth2Svc.isSiteLocal(viewModelContrat.siteId);

  /**
   * ouverture p-overlayPanel pour la duplication d'une prestation
   * @param $event
   * @param rowData
   */
  openPanelDuplication = ($event, rowData: ViewModelContrat) => {
    this.prestations = [...rowData.contratMenuConvives];

    if (this.prestations.length === 1) {
      this.prestationSelected = this.prestations[0];
    } else {
      this.prestationSelected = undefined;
    }

    if (this.contratsMenus.length === 1) {
      this.offreAlimentaireSelected = this.contratsMenus[0];
    } else {
      this.offreAlimentaireSelected = undefined;
    }
    this.opDuplication.toggle($event);
  };

  /**
   * duplication d'une prestation sur une autre offre alimentaire
   */
  duplicatePrestation = () => {

    this.contratMenuConviveSvc.duplicatePrestation(this.prestationSelected, this.offreAlimentaireSelected)
      .subscribe((response: ResponseWrapper<ContratMenuConviveDTO>) => {

        if (!this.utils.isResponseSupplierError(response)) {
          const msg = `Prestation dupliquée avec succès.`;
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, msg, '', 3000);
          this.addNewCmcDtoToGrid(response.one);
        }
      });
    this.opDuplication.hide();
  };

  addNewCmcDtoToGrid = (newCmc: ContratMenuConviveDTO) => {
    this.viewModelContratList.forEach((viewModelContrat: ViewModelContrat) => {
      if (viewModelContrat.id === newCmc.contratMenuId) {
        viewModelContrat.contratMenuConvives.push(newCmc);
      }
    })
  };

  help = () => undefined;

  onCloseDialogContrat = (response: any) => {
    this.displayDialogContratForm = false;
    if (!this.utils.isNullOrEmpty(response)) {
      this.contratsMenus = this.refreshDatagridPrimeNgAfterCmcDelete(response, this.contratsMenus);
      this.viewModelContratList = this.refreshViewModelList(this.contratsMenus);
    }
  };
}
