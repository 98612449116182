import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {LotMarcheDTO} from '../../dtos/gerstion-marche/lot-marche-dto';
import {SiteDTO} from '../../dtos/site-dto';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {ProduitDeclinaisonService} from '../entities/produit-declinaison.service';
import {PREDICAT_OPERATOR, PREDICAT_TYPE, PREDICAT_VALUE} from '../../constants';
import {UnitesDeProductionService} from '../entities/unites-de-production.service';
import {LotMarcheContextWrapper} from '../../suppliers/wrappers/lot-marche-context-wrapper';
import {FournisseurDTO} from '../../dtos/fournisseur-d-t-o';
import {CatalogueAchatDTO} from '../../dtos/catalogue-achat-dto';
import {FK_LMUPCA, LotmarcheUpCaService} from '../entities/lotmarche-up-ca.service';
import {DevextremeService, FilterItem} from '../technique/devextreme.service';
import {LotMarcheIncoherenceDTO} from '../../dtos/lot-marche-incoherence-dto';
import {HttpService} from '../technique/http.service';

export const URL_GET_ALL_LOT_MARCHE = `dolrest/gestionmarche/get-all-lot-marche`;
export const URL_GET_LOT_MARCHE_BY_ID = `dolrest/gestionmarche/get-lot-marche-by-id`;
export const URL_POST_SAVE_LOT_MARCHE = `dolrest/gestionmarche/save-lot-marche`;
export const URL_GET_CATALOGUEACHAT_WITH_LOT_MARCHE_LIST = `dolrest/gestionarticles/produit/catalogueachats/lotmarche`;
export const URL_GET_FOURNISSEUR_BY_LOT_MARCHE_ID = `dolrest/gestionmarche/get-fournisseur-filiale-by-lot-marche-id`;
export const URL_GET_PD_BY_LOT_MARCHE_ID = `dolrest/gestionmarche/get-produit-decli-by-lot-marche-id`;
export const URL_GET_UDP_BY_LOT_MARCHE_ID = `dolrest/gestionmarche/get-unite-prod-by-lot-marche-id`;
export const URL_POST_SAVE_CONTEXT_LOT_MARCHE = `dolrest/gestionmarche/save-context-lot-marche`;
export const URL_GET_CATALOGUE_ACHAT_LIST = `dolrest/gestionmarche/get-catalogue-achat-by-lot-marche-id`;
export const URL_POST_SAVE_ARTICLES_LOT_MARCHE = `dolrest/gestionmarche/save-catalogue-achat`;
export const URL_GET_SELECTED_CATALOGUE_ACHAT_LIST = `dolrest/gestionmarche/get-selected-catalogue-achat-by-lot-marche-id`;
export const URL_DELETE_LOT_MARCHE = `dolrest/gestionmarche/delete-lot-marche`;
export const URL_INIT_LMUPCA_LIST = `dolrest/gestionmarche/init-lmupca-list`;
export const URL_GET_INCOHERENCE_LIST = `dolrest/gestionmarche/get-incoherence-lot-marche`;
export const URL_POST_RESOLVE_INCOHERENCE = `dolrest/gestionmarche/resolve-incoherence`;
export const URL_DELETE_CATALOGUE_ACHAT_LINK_TO_LOT_MARCHE =`dolrest/gestionmarche/delete-catalogue-achat-link-to-lot-marche`;


export enum TAB_NAME_LM {
  info,
  context,
  articles,
  engagement,
  incoherence
}

@Injectable()
export class GestionLotMarcheService {

  protected optionsSites: SiteDTO[] = [];

  private subjectDisplaySearchDenree = new Subject<boolean>();
  displaySearchDenree$ = this.subjectDisplaySearchDenree.asObservable();

  private subjectDisplayPrioritizationFournisseur = new Subject<any>();
  displayPrioritizationFournisseur$ = this.subjectDisplayPrioritizationFournisseur.asObservable();

  private subjectOpenDialogLotMarche = new Subject<any>();
  openDialogLotMarche$ = this.subjectOpenDialogLotMarche.asObservable();

  private subjectCloseDialogLotMarche = new Subject<boolean>();
  closeDialogLotMarche$ = this.subjectCloseDialogLotMarche.asObservable();


  protected subjectLotMarcheDTO = new Subject<any>();
  lotMarcheDto$ = this.subjectLotMarcheDTO.asObservable();

  private subjectSelectedCatalogueAchatList = new Subject<any>();
  selectedCatalogueAchatList$ = this.subjectSelectedCatalogueAchatList.asObservable();

  private subjectCaListSearchArticles = new Subject<any>();
  caListSearchArticles$ = this.subjectCaListSearchArticles.asObservable();

  private subjectCreateArticle = new Subject<any>();
  createArticle$ = this.subjectCreateArticle.asObservable();

  constructor(
    public httpSvc: HttpService,
    public utils: UtilsService,
    public gds: GenericDatagridService,
    private dxSvc: DevextremeService,
    public udpSvc: UnitesDeProductionService,
    public produitDecliSvc: ProduitDeclinaisonService,
    public lmupcaSvc: LotmarcheUpCaService,
    public auth2Svc: Auth2Service) {
    this.optionsSites = this.auth2Svc.utilisateur.siteListLocaux;
  }

  announceDisplayPrioritizationFournisseur = (isDisplay: boolean, fournisseurList: FournisseurDTO[]) => {
    const wrapper: any = {
      isDisplay: isDisplay,
      fournisseurList: fournisseurList
    };

    this.subjectDisplayPrioritizationFournisseur.next(wrapper);
  };

  announceDisplaySearchDenree = (isDisplay: boolean) => {
    this.subjectDisplaySearchDenree.next(isDisplay);
  };

  announceOpenDialogLotMarche = (lotMarcheDto: LotMarcheDTO, tabName: number) => {
    const wrapper: any = {
      lotMarche: lotMarcheDto,
      tabName: tabName
    };
    this.subjectOpenDialogLotMarche.next(wrapper);
  };

  announceCloseDialogLotMarche = (isclosed: boolean) => {
    this.subjectCloseDialogLotMarche.next(isclosed);
  };

  announceLotMarche = (lotMarcheDto: LotMarcheDTO, tabName: number) => {
    const wrapper: any = {
      lotMarche: lotMarcheDto,
      tabName: tabName
    };
    this.subjectLotMarcheDTO.next(wrapper);
  };

  announceSelectedCatalogueAchatList = (selectedCatalogueAchatList: CatalogueAchatDTO[]) => {
    this.subjectSelectedCatalogueAchatList.next(selectedCatalogueAchatList);
  };

  announceCaListSearchArticles = (data: any[]) => {
    this.subjectCaListSearchArticles.next(data);
  };

  announceCreateArticle = (data: LotMarcheDTO) => {
    this.subjectCreateArticle.next(data);
  };


  /**
   * Recuperation de tous les lot marchés
   * @param idMarche
   */
  getAllLotMarcheByIdMarche = (idMarche: number) => {

    const params = new HttpParams()
      .set('idMarche', idMarche + '');

    return this.httpSvc.get(URL_GET_ALL_LOT_MARCHE, params);
  };


  /**
   * Recuperation d'un lot marché par son Id
   * @param idLotMarche
   */
  getById = (idLotMarche: number) => {

    const params = new HttpParams().set('idLotMarche', idLotMarche + '');

    return this.httpSvc.get(URL_GET_LOT_MARCHE_BY_ID, params);
  };

  /**
   * Récupération de la liste unité de prodcution  par un lot marché
   * @param idLotMarche
   */
  getUniteProdByLmId = (idLotMarche: number) => {

    const params = new HttpParams()
      .set('idLotMarche', idLotMarche + '');

    return this.httpSvc.get(URL_GET_UDP_BY_LOT_MARCHE_ID, params);
  };

  /**
   * Récupération de la liste  produit déclinaison par un lot marché
   * @param idLotMarche
   */
  getProdDecliByLmId = (idLotMarche: number) => {

    const params = new HttpParams().set('idLotMarche', idLotMarche + '');

    return this.httpSvc.get(URL_GET_PD_BY_LOT_MARCHE_ID, params);
  };

  /**
   * Recuperation d'un lot marché par son Id
   * @param idLotMarche
   */
  getFournisseurByLmId = (idLotMarche: number) => {

    const params = new HttpParams()
      .set('idLotMarche', idLotMarche + '');

    return this.httpSvc.get(URL_GET_FOURNISSEUR_BY_LOT_MARCHE_ID, params);
  };


  /**
   * sauvegarde d'un lot marché
   * @param lotMarche
   */
  saveLotMarche = (lotMarche: LotMarcheDTO) => this.httpSvc.post(URL_POST_SAVE_LOT_MARCHE, lotMarche);

  saveContextLotmarche = (lmContextWrapper: LotMarcheContextWrapper) => this.httpSvc.post(URL_POST_SAVE_CONTEXT_LOT_MARCHE, lmContextWrapper);

  saveArticleLotMarche = (idLotMarche: number, catalogueAchatList: CatalogueAchatDTO[]) => {

    const params = new HttpParams().set('idLotMarche', idLotMarche + '');

    return this.httpSvc.post(URL_POST_SAVE_ARTICLES_LOT_MARCHE, catalogueAchatList, params);
  };

  getCatalogueAchatList = (idLotMarche: number) => {

    const params = new HttpParams().set('idLotMarche', idLotMarche + '');

    return this.httpSvc.get(URL_GET_CATALOGUE_ACHAT_LIST, params);
  };

  getIncoherenceList = (idLotMarche: number) => {

    const params = new HttpParams()
      .set('idLotMarche', idLotMarche + '');

    return this.httpSvc.get(URL_GET_INCOHERENCE_LIST, params);
  };

  getSelectedCatalogueAchatList = (idLotMarche: number) => {

    const params = new HttpParams().set('idLotMarche', idLotMarche + '');

    return this.httpSvc.get(URL_GET_SELECTED_CATALOGUE_ACHAT_LIST, params);
  };

  deleteLotMarche = (idLotMarche: number) => {

    const params =  new HttpParams()
      .set('idLotMarche', idLotMarche + '');

    return this.httpSvc.delete(URL_DELETE_LOT_MARCHE, params);
  };

  deleteCatalogueAchatLinkToLotMarche = (idCatalogueAchat: number, idLotMarche: number) => this.httpSvc.delete(`${URL_DELETE_CATALOGUE_ACHAT_LINK_TO_LOT_MARCHE}?idCatalogueAchat=${idCatalogueAchat}&idLotMarche=${idLotMarche}`);

  resolveIncoherence = (lotMarcheIncoherence: LotMarcheIncoherenceDTO, idLotMarche: number) => {

    const params = new HttpParams()
      .set('uniteDeProductionId', lotMarcheIncoherence.uniteDeProduitId + '')
      .set('secteurFournisseurId', lotMarcheIncoherence.secteurFournisseurId + '')
      .set('idLotMarche', idLotMarche + '');

    return this.httpSvc.post(URL_POST_RESOLVE_INCOHERENCE, undefined, params);
  };

  /**
   * Récupération des denrées
   * @param query
   * @param udpIdList
   */
  filterDenrees = (query: string): GenericRequestSupplier | any => {

    const grs = new GenericRequestSupplier(this.produitDecliSvc.getEntityName());
    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'produitdeclinaison.libelle';
    predicat1.operator = PREDICAT_OPERATOR.Contains;
    predicat1.type = PREDICAT_TYPE.String;
    predicat1.value = query;


    search.predicats.push(predicat1);

    const predicat2 = new Predicat();
    predicat2.path = 'produitdeclinaison.produit.site.id';
    predicat2.operator = PREDICAT_OPERATOR.In;
    predicat2.type = PREDICAT_TYPE.Integer;
    predicat2.ids = this.auth2Svc.utilisateur.sites.map(site => site.id);

    search.predicats.push(predicat2);

    const predicat3 = new Predicat();
    predicat3.path = 'produitdeclinaison.actif';
    predicat3.operator = PREDICAT_OPERATOR.Equals;
    predicat3.type = PREDICAT_TYPE.Boolean;
    predicat3.value = PREDICAT_VALUE.True;

    search.predicats.push(predicat3);

    const predicat4 = new Predicat();
    predicat4.path = 'produitdeclinaison.produit.typeProduit.fabrique';
    predicat4.operator = PREDICAT_OPERATOR.Equals;
    predicat4.type = PREDICAT_TYPE.Boolean;
    predicat4.value = PREDICAT_VALUE.False;

    search.predicats.push(predicat4);

    grs.search = search;
    grs.size = 50;

    return grs;
  };

  initLmupCaList = (idLotMarche: number) => {

    const params = new HttpParams().set('idLotMarche', idLotMarche + '');

    return this.httpSvc.post(URL_INIT_LMUPCA_LIST, {}, params);

  };

  filterLmupCaList = (idLotMarche: number, page: number, pageSize: number, filters: FilterItem[], sorts: Sort[]) => {

    const grs = new GenericRequestSupplier(this.lmupcaSvc.getEntityName());
    const search = new Search();


    const rootPath = this.lmupcaSvc.getEntityName().toLowerCase();

    const predicat1 = new Predicat();
    predicat1.path = `${rootPath}.lotMarche__uniteDeProduction.lotMarche.id`;
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.Integer;
    predicat1.value = idLotMarche + '';

    search.predicats = [predicat1];

    if (!this.utils.isCollectionNullOrEmpty(filters)) {


      for (const filter of filters) {

        let path = '';
        switch (filter.column) {
          case FK_LMUPCA.udpLibelle:
            path = `${rootPath}.lotMarche__uniteDeProduction.uniteDeProduction.libelle`;
            break;
          case FK_LMUPCA.catalogueAchatLibelle:
            path = `${rootPath}.catalogueAchat.produitArticle.libelle`;
            break;
          case FK_LMUPCA.catalogueAchatFournisseurLibelle:
            path = `${rootPath}.catalogueAchat.secteurFournisseur.fournisseur.libelle`;
            break;
          case FK_LMUPCA.catalogueAchatSecteurLibelle:
            path = `${rootPath}.catalogueAchat.secteurFournisseur.secteur.libelle`;
            break;
          case FK_LMUPCA.valeurEngagement:
            path = `${rootPath}.valeurEngagement`;
            break;
          case FK_LMUPCA.volumeEngagement:
            path = `${rootPath}.volumeEngagement`;
            break;
        }


        const predicat = new Predicat();
        predicat.path = path;
        let operator = this.dxSvc.dxToGrsOperator(filter.operator);
        predicat.operator = operator;
        predicat.type = operator === PREDICAT_OPERATOR.LessThanEquals || operator === PREDICAT_OPERATOR.GreaterThanEquals ? PREDICAT_TYPE.Integer : PREDICAT_TYPE.String;
        predicat.value = filter.value + '';

        search.predicats.push(predicat);
      }
    }

    grs.search = search;

    grs.page = page;
    grs.size = pageSize;

    if (!this.utils.isCollectionNullOrEmpty(sorts)) {
      for (const sort of sorts) {
        switch (sort.path) {
          case FK_LMUPCA.catalogueAchatFournisseurLibelle :
            sort.path = `${rootPath}.catalogueAchat.secteurFournisseur.fournisseur.libelle`;
            break;
          case FK_LMUPCA.catalogueAchatSecteurLibelle :
            sort.path = `${rootPath}.catalogueAchat.secteurFournisseur.secteur.libelle`;
            break;
          case FK_LMUPCA.udpLibelle :
            sort.path = `${rootPath}.lotMarche__uniteDeProduction.uniteDeProduction.libelle`;
            break;
          case FK_LMUPCA.catalogueAchatLibelle :
            sort.path = `${rootPath}.catalogueAchat.produitArticle.libelle`;
            break;
          case FK_LMUPCA.volumeEngagement :
            sort.path = `${rootPath}.volumeEngagement`;
            break;
          case FK_LMUPCA.valeurEngagement :
            sort.path = `${rootPath}.valeurEngagement`;
            break;
        }
      }
    }

    grs.search.sorts = sorts;

    return grs;
  };
}
