import {Component} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {UpModeleConditionnnementPlatSupplier} from "./up-modele-conditionnement-plat-resolver.service";
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {UniteDeProduction__ModeleConditionnementPlatDto} from "../../../../core/dtos/unite-de-production-modele-conditionnement-plat-dto";
import {GestionUniteProduction__ModeleConditionnementPlatService} from "../../../../core/services/gestion-unites-productions/unite-production__modele-conditionnement-plat.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-up-modele-conditionnement-plat',
  templateUrl: './up-modele-conditionnement-plat.component.html',
  styleUrls: ['./up-modele-conditionnement-plat.component.scss']
})
export class UpModeleConditionnementPlatComponent {

  subscriptionRoute: Subscription;
  subElementsSaved: Subscription;

  uniteDeProduction: UniteDeProductionDTO;

  udpMcpList: UniteDeProduction__ModeleConditionnementPlatDto[] = [];

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production-modele-conditionnement-plat';

  constructor(public utils: UtilsService,
              private udpMcpSvc: GestionUniteProduction__ModeleConditionnementPlatService,
              private route: ActivatedRoute) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.initDataRoute();
    this.conditionnementVarianteSavedSubscription();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subElementsSaved);
  }

  initDataRoute() {
    this.subscriptionRoute = this.route.data.subscribe((data: { upModeleConditionnnementPlatSupplier: UpModeleConditionnnementPlatSupplier }) => {
      this.udpMcpList = data.upModeleConditionnnementPlatSupplier.udpMcpList;
      this.uniteDeProduction = data.upModeleConditionnnementPlatSupplier.uniteDeProduction
    });
  }

  createAssociation() {
    this.udpMcpSvc.announceOpenDialogAjout(this.uniteDeProduction.id);
  }

  public help(): DialogMsgSupplier {
    const dms = new DialogMsgSupplier();
    dms.title = `Association unité de production - modèle conditionnement plat`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  }

  conditionnementVarianteSavedSubscription(): void {
    this.subElementsSaved = this.udpMcpSvc.elementsSaved$
      .subscribe((associations: UniteDeProduction__ModeleConditionnementPlatDto[]) => {
        associations.forEach(a => {
          if (!this.udpMcpList.find(it => it.idModeleConditionnementPlat === a.idModeleConditionnementPlat && it.idUniteDeProduction === a.idUniteDeProduction)) {
            this.udpMcpList.push(a);
          }
        });
      });
  }

  deleteValues = async () => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation');
    const isConfirmed: boolean = await result;
    if (isConfirmed) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.udpMcpList.find(u => u.id === idSelected))
        .map((u: UniteDeProduction__ModeleConditionnementPlatDto) => u.id);
      this.udpMcpSvc.deleteByIds(idsToDelete)
        .subscribe(res => {
          const o = res.one;
          if (o.deletable) {
            this.udpMcpList = this.udpMcpList.filter(u => !idsToDelete.includes(u.id));
          } else {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, o.messageList[0]);
          }
        });
    }
  };

}
