import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdministrationRoutingModule} from './administration-routing.module';
import {AdministrationComponent} from './administration.component';
import {SitesComponent} from './sites/sites.component';
import {EnvironnementsComponent} from './environnements/environnements.component';
import {UtilisateursComponent} from './utilisateurs/utilisateurs.component';
import {UtilisateursConnexionsComponent} from './utilisateurs-connexions/utilisateurs-connexions.component';
import {SharedModule} from '../shared/shared.module';
import {GrillesitesComponent} from './sites/grillesites/grillesites.component';
import {FicheidentiteSiteComponent} from './sites/ficheidentite-site/ficheidentite-site.component';
import {FeaturesComponent} from './features/features.component';
import {GrilleenvironnementsComponent} from './environnements/grilleenvironnements/grilleenvironnements.component';
import {FicheidentiteEnvironnementComponent} from './environnements/ficheidentite-environnement/ficheidentite-environnement.component';
import {ConvivesComponent} from './convives/convives.component';
import {FamillesGemrcnComponent} from './familles-gemrcn/familles-gemrcn.component';
import {FamillesPlansAlimentairesComponent} from './familles-plans-alimentaires/familles-plans-alimentaires.component';
import {RepasComponent} from './repas/repas.component';
import {DecoupagesRepasComponent} from './decoupages-repas/decoupages-repas.component';
import {TypesProduitComponent} from './types-produit/types-produit.component';
import {FamillesProduitComponent} from './familles-produit/familles-produit.component';
import {AllergenesComponent} from './allergenes/allergenes.component';
import {FicheidentiteUtilisateurComponent} from './utilisateurs/ficheidentite-utilisateur/ficheidentite-utilisateur.component';
import {GrilleutilisateursComponent} from './utilisateurs/grilleutilisateurs/grilleutilisateurs.component';
import {GrilleutilisateursconnexionsComponent} from './utilisateurs-connexions/grilleutilisateursconnexions/grilleutilisateursconnexions.component';
import {DeclinaisonsComponent} from './declinaisons/declinaisons.component';
import {UnitesDeMesuresComponent} from './unites-de-mesures/unites-de-mesures.component';
import {ZonesDeProductionComponent} from './zones-de-production/zones-de-production.component';
import {EquipesComponent} from './equipes/equipes.component';
import {ZonesDeStockageComponent} from './zones-de-stockage/zones-de-stockage.component';
import {SecteursComponent} from './secteurs/secteurs.component';
import {TaxesComponent} from './taxes/taxes.component';
import {AppellationsComponent} from './appellations/appellations.component';
import {ParametresComponent} from './parametres/parametres.component';
import {LitigesComponent} from './litiges/litiges.component';
import {MotifsNonConformiteComponent} from './motifs-non-conformite/motifs-non-conformite.component';
import {ProfilsComponent} from './profils/profils.component';
import {TexturesComponent} from './textures/textures.component';
import {RegimesAlimentairesComponent} from './regimes-alimentaires/regimes-alimentaires.component';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StepsModule} from 'primeng/steps';
import {
  DxColorBoxModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxGalleryModule,
  DxListModule,
  DxLookupModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTagBoxModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeListModule,
  DxTreeViewModule
} from 'devextreme-angular';
import {DialogFormFamillesProduitComponent} from './familles-produit/dialog-form-famille-produit/dialog-form-famille-produit.component';
import {TooltipModule} from 'primeng/tooltip';
import {TableModule} from 'primeng/table';
import {CardModule} from 'primeng/card';
import {CheckboxModule} from 'primeng/checkbox';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {ToolbarModule} from 'primeng/toolbar';
import {MultiSelectModule} from 'primeng/multiselect';
import {GrilleConditionnementsComponent} from './conditionnements/grille-conditionnements.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DropdownModule} from 'primeng/dropdown';
import {VariantesComponent} from './conditionnements/variantes/grille-variantes/variantes.component';
import {RestesComponent} from './conditionnements/restes/grille-restes/restes.component';
import {ResteComponent} from './conditionnements/restes/reste/reste.component';
import {VarianteComponent} from './conditionnements/variantes/variante/variante.component';
// tslint:disable-next-line:import-spacing
import {OrigineGridComponent} from './origines/origine-grid.component';
import {DialogOrigineComponent} from './origines/dialog-origine/dialog-origine.component';
import {ConditionnementComponent} from './conditionnements/conditionnement.component';
import {ConditionnementsVariantesComponent} from './conditionnements/conditionnements-variantes/grille/conditionnements-variantes.component';
import {ConditionnementVarianteComponent} from './conditionnements/conditionnements-variantes/conditionnement-variante/conditionnement-variante.component';

import {StockMouvementStatutComponent} from "./stocks/statuts/statut/statut.component";
import {GrilleStocksMouvementsStatutComponent} from "./stocks/statuts/grille-statuts/grille-statuts.component";
import {InputTextareaModule} from "primeng/inputtextarea";
import {GrilleTacheComponent} from "./taches/grille-taches.component";
import {TacheComponent} from "./taches/tache.component";
import {PmsUniteGrilleComponent} from "./pms/unite/grille/pms-unite-grille.component";
import {PmsUniteDialogComponent} from "./pms/unite/dialog/pms-unite-dialog.component";
import {PmsSeuilGrilleComponent} from "./pms/seuil/grille/pms-seuil-grille.component";
import {PmsSeuilDialogComponent} from "./pms/seuil/dialog/pms-seuil-dialog.component";
import {PmsEquipementGrilleComponent} from "./pms/equipement/grille/pms-equipement-grille.component";
import {PmsEquipementDialogComponent} from "./pms/equipement/dialog/pms-equipement-dialog.component";
import {PmsZoneANettoyerGrilleComponent} from "./pms/zone-a-nettoyer/grille/pms-zone-a-nettoyer-grille.component";
import {PmsZoneANettoyerDialogComponent} from "./pms/zone-a-nettoyer/dialog/pms-zone-a-nettoyer-dialog.component";
import {PmsEquipementANettoyerGrilleComponent} from "./pms/equipement-a-nettoyer/grille/pms-equipement-a-nettoyer-grille.component";
import {PmsEquipementANettoyerDialogComponent} from "./pms/equipement-a-nettoyer/dialog/pms-equipement-a-nettoyer-dialog.component";
import {PmsSignatureUtilisateurGrilleComponent} from "./pms/signature-utilisateur/grille/pms-signature-utilisateur-grille.component";
import {PmsSignatureUtilisateurDialogComponent} from "./pms/signature-utilisateur/dialog/pms-signature-utilisateur-dialog.component";
import {PmsTypeUtilisateurGrilleComponent} from "./pms/type-utilisateur/grille/pms-type-utilisateur-grille.component";
import {PmsTypeUtilisateurDialogComponent} from "./pms/type-utilisateur/dialog/pms-type-utilisateur-dialog.component";
import {GridEnvironnementPlcComponent} from "./portail/environnement-plc/grid/grid-environnement-plc.component";
import {SidebarEnvironnementPlcComponent} from "./portail/environnement-plc/sidebar/sidebar-environnement-plc.component";
import {DialogAddPlcComponent} from "./portail/environnement-plc/sidebar/dialog-add-plc/dialog-add-plc.component";
import {PmsTypeTacheGrilleComponent} from "./pms/type-tache/grille/pms-type-tache-grille.component";
import {PmsTypeTacheDialogComponent} from "./pms/type-tache/dialog/pms-type-tache-dialog.component";
import {PmsTypeEquipementGrilleComponent} from "./pms/type-equipement/grille/pms-type-equipement-grille.component";
import {PmsTypeEquipementDialogComponent} from "./pms/type-equipement/dialog/pms-type-equipement-dialog.component";
import {PmsTacheGrilleComponent} from "./pms/tache/grille/pms-tache-grille.component";
import {PmsTacheDialogComponent} from "./pms/tache/dialog/pms-tache-dialog.component";
import {RadioButtonModule} from "primeng/radiobutton";
import {ConfigurationPortailPlcGridComponent} from "./portail/configuration-portail-plc-grid/configuration-portail-plc-grid.component";
import {ConfigurationPortailPlcFormComponent} from "./portail/configuration-portail-plc-grid/configuration-portail-plc-form/configuration-portail-plc-form.component";
import {InputNumberModule} from "primeng/inputnumber";

@NgModule({
    imports: [
        CommonModule,
        AdministrationRoutingModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule,
        CardModule,
        TableModule,
        CheckboxModule,
        PanelModule,
        ListboxModule,
        TabMenuModule,
        DialogModule,
        ToastModule,
        ButtonModule,
        StepsModule,
        TooltipModule,
        DxTreeListModule,
        DxDropDownBoxModule,
        ToolbarModule,
        DxDataGridModule,
        DxTemplateModule,
        OverlayPanelModule,
        DropdownModule,
        InputTextareaModule,
        MultiSelectModule,
        RadioButtonModule,
        InputNumberModule,
        DxSelectBoxModule,
        DxTagBoxModule,
        DxNumberBoxModule,
        DxPopupModule,
        DxFileUploaderModule,
        DxTextBoxModule,
        DxFormModule,
        DxListModule,
        DxLookupModule,
        DxColorBoxModule,
        DxGalleryModule,
        DxTreeViewModule,
        DxToolbarModule
    ],
  entryComponents: [

  ],
  declarations: [AdministrationComponent,
    SitesComponent,
    EnvironnementsComponent,
    UtilisateursComponent,
    UtilisateursConnexionsComponent,
    GrillesitesComponent,
    FicheidentiteSiteComponent,
    FeaturesComponent,
    GrilleenvironnementsComponent,
    FicheidentiteEnvironnementComponent,
    ConvivesComponent,
    FamillesGemrcnComponent,
    FamillesPlansAlimentairesComponent,
    RepasComponent,
    DecoupagesRepasComponent,
    TypesProduitComponent,
    FamillesProduitComponent,
    AllergenesComponent,
    FicheidentiteUtilisateurComponent,
    GrilleutilisateursComponent,
    GrilleutilisateursconnexionsComponent,
    DeclinaisonsComponent,
    UnitesDeMesuresComponent,
    ZonesDeProductionComponent,
    EquipesComponent,
    ZonesDeStockageComponent,
    SecteursComponent,
    TaxesComponent,
    AppellationsComponent,
    ParametresComponent,
    LitigesComponent,
    MotifsNonConformiteComponent,
    ProfilsComponent,
    TexturesComponent,
    RegimesAlimentairesComponent,
    DialogFormFamillesProduitComponent,
    DialogFormFamillesProduitComponent,
    OrigineGridComponent,
    DialogOrigineComponent,
    GrilleConditionnementsComponent,
    VariantesComponent,
    RestesComponent,
    ResteComponent,
    VarianteComponent,
    ConditionnementComponent,
    ConditionnementsVariantesComponent,
    ConditionnementVarianteComponent,
    StockMouvementStatutComponent,
    GrilleStocksMouvementsStatutComponent,
    TacheComponent,
    GrilleTacheComponent,
    PmsUniteGrilleComponent,
    PmsUniteDialogComponent,
    PmsSeuilGrilleComponent,
    PmsSeuilDialogComponent,
    PmsEquipementGrilleComponent,
    PmsEquipementDialogComponent,
    PmsZoneANettoyerGrilleComponent,
    PmsZoneANettoyerDialogComponent,
    PmsEquipementANettoyerGrilleComponent,
    PmsEquipementANettoyerDialogComponent,
    PmsSignatureUtilisateurGrilleComponent,
    PmsSignatureUtilisateurDialogComponent,
    PmsTypeUtilisateurGrilleComponent,
    PmsTypeUtilisateurDialogComponent,
    GridEnvironnementPlcComponent,
    SidebarEnvironnementPlcComponent,
    DialogAddPlcComponent,
    PmsTypeUtilisateurDialogComponent,
    PmsTypeTacheGrilleComponent,
    PmsTypeTacheDialogComponent,
    PmsTypeEquipementGrilleComponent,
    PmsTypeEquipementDialogComponent,
    PmsTacheGrilleComponent,
    PmsTacheDialogComponent,
    ConfigurationPortailPlcGridComponent,
    ConfigurationPortailPlcFormComponent
    ],
  exports: [AdministrationComponent, SitesComponent, EnvironnementsComponent,
    UtilisateursComponent, GrillesitesComponent,
    FicheidentiteSiteComponent,
    GrilleenvironnementsComponent,
    FicheidentiteEnvironnementComponent],
  providers: []
})
export class AdministrationModule {
}
