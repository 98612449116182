import {Component, OnDestroy, OnInit} from '@angular/core';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {combineLatest, Subscription, timer} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, REGLES_PREFERES} from '../../../core/constants';
import {UpArticlesPreferesSupplier} from './up-articles-preferes-resolver.service';
import {UniteDeProductionReglePrefereService} from '../../../core/services/entities/unite-de-production__regle-prefere.service';
import {MenuItem} from 'primeng/api';
import {GestionUniteProductionService} from "../../../core/services/gestion-unites-productions/gestion-unite-production.service";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {
  ProgressionComputeUdpInterface,
  UniteDeProduction__secteurFournisseurService
} from "../../../core/services/entities/unite-de-production__secteur-fournisseur.service";
import {confirm} from "devextreme/ui/dialog";


@Component({
  selector: 'yo-up-articles-preferes',
  templateUrl: './up-articles-preferes.component.html',
  styleUrls: ['./up-articles-preferes.component.scss'],
  animations: [
    trigger('animateFilialesButton', [

      transition('*=>' + REGLES_PREFERES.FILIALES, animate('800ms', keyframes([
        style({opacity: 0.1, offset: 0.1,}),
        style({opacity: 0.5, offset: 0.3, height: '38px', width: '38px'}),
        style({opacity: 1, offset: 0.5}),
        style({opacity: 0.5, offset: 0.7}),
        style({opacity: 1, offset: 1, height: '34px', width: '34px'}),
      ]))),
    ]),
  ]
})
export class UpArticlesPreferesComponent implements OnInit, OnDestroy {

  subIsDisabledButtonComputePrefere: Subscription;
  subIsDisabledCalculPrixPlat: Subscription;
  subRoute: Subscription;
  uniteDeProduction: UniteDeProductionDTO;

  upAps: UpArticlesPreferesSupplier;

  itemsComputeMenu: MenuItem[];
  isDisabledCalculPrixPlatBtn: boolean = false;

  currentStateRegle = '';

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production-articles-preferes';

  /**
   * Barre de progression des traitements asynchrones.
   */
  progression: number = 0;

  /**
   * Temps passé depuis le début du traitement
   */
  timeSpend: number = null;
  isTimeRunning: boolean = false;
  timeToDisplay: string = '';

  constructor(public gds: GenericDatagridService,
              public utils: UtilsService,
              private route: ActivatedRoute,
              private udpSecteurFournisseurSvc: UniteDeProduction__secteurFournisseurService,
              private udpReglePrefereSvc: UniteDeProductionReglePrefereService,
              private gestionUniteProductionSvc: GestionUniteProductionService,
              private udpSvc: UnitesDeProductionService) {
  }

  ngOnInit() {
    this.subscriptionRouteData();
    this.allSubscription();
    this.initComputeMenu();

    timer(0, 1000).subscribe(() => {
      if(this.isTimeRunning) {
        this.timeSpend++;
        this.timeToDisplay = this.getTimeSpend();
      }
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subIsDisabledCalculPrixPlat);
    this.utils.unsubscribe(this.subIsDisabledButtonComputePrefere);
  }

  getTimeSpend = (): string => `${this.timeSpend} seconde(s) écoulée(s) depuis le début du traitement...`;

  allSubscription = (): void => {
    this.subIsDisabledCalculPrixPlat = this.udpSecteurFournisseurSvc.isDisabledButtonComputeMealPrice$.subscribe((response: ProgressionComputeUdpInterface) => {
      this.isDisabledCalculPrixPlatBtn = response.isDisabled;
      this.initComputeMenu();
    });
  };

  subscriptionRouteData = (): void => {
    const all$ = combineLatest([this.route.parent.data, this.route.data]);
    this.subRoute = all$.subscribe(data => {
      this.uniteDeProduction = data[0].uniteDeProductionSupplier.uniteDeProduction;
      this.upAps = data[1].upArticlesPreferesSupplier;
    });
  };

  initComputeMenu = (): void => {
    this.itemsComputeMenu = [{
      label: `Choix des calculs :`,
      items: [
        {
          label: 'Mettre à jour les articles préférés',
          command: () => this.calculerArticlesPreferes(true)
        },
        {
          label: 'Réinitialiser les articles préférés',
          command: () => this.calculerArticlesPreferes(false)
        },
        {
          label: 'Mettre à jour les prix des plats',
          command: () => this.calculerPrixPlatUdp(),
          disabled: this.isDisabledCalculPrixPlatBtn
        }
      ]
    }];
  };

  changeReglePrefere = ($event: any): void => {
    this.upAps.upReglePrefere.reglePrefereId = this.upAps.selectedReglePrefere.id;
    this.upAps.upReglePrefere.reglePrefereCode = this.upAps.selectedReglePrefere.code;
    this.currentStateRegle = this.upAps.upReglePrefere.reglePrefereCode;

    this.udpReglePrefereSvc.updateReglePrefere(this.upAps.upReglePrefere).subscribe(response => this.upAps.upReglePrefere = response.one);
  };


  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();

    dms.title = `Sélection des articles préférés`;

    let p2: Paragraphe = new Paragraphe();
    let p3: Paragraphe = new Paragraphe();
    let p4: Paragraphe = new Paragraphe();
    let p5: Paragraphe = new Paragraphe();
    let p6: Paragraphe = new Paragraphe();
    p2.title = `Etape 1`;
    p3.title = `Etape 2`;
    p4.title = `Etape 3`;
    p5.title = `Etape 4 (facultative)`;
    p6.title = `Etape 5 (facultative)`;

    p2.lines = [
      `Sélectionner le mode de mise à jour. Sous quelle règle souhaitez-vous privilégier un article par rapport à un autre.`,
    ];
    p3.lines = [
      `Seulement si le mode "Filiales préférées" a été sélectionné à l'étape 1 :  Sélectionner les filiales fournisseurs à prioriser via le bouton "Camion"`,
    ];
    p4.lines = [
      `Lancer la mise à jour des articles préférés`,
    ];
    p5.lines = [
      `Vous pouvez sélectionner manuellement un article préféré au sein d'une denrée. La ligne de l'article géré manuellement paraîtra en rose.`,
    ];
    p6.lines = [
      `Vous pouvez réinitialiser toutes les modifications manuelles grâce au bouton de réinitialisation.`,
    ];


    dms.content = {
      intro: `Les articles préférés sont les articles à privilégier au sein d'une déclinaison denrée.`,
      paragraphes: [p2, p3, p4, p5, p6]
    };


    return dms;
  }

  /**
   * affiche la sélection des filiales préférées
   * @param $event
   */
  displayFilialesPreferees = ($event: MouseEvent): void => {
    this.udpSvc.announceActionSelectionFilialesPreferees(this.uniteDeProduction);
  };

  calculerArticlesPreferes = async (allowSelectManualItemPrefered: boolean): Promise<void> => {
    const question = !allowSelectManualItemPrefered ? 'Souhaitez-vous réinitialiser tous les articles préférés (Toutes les modifications manuelles seront écrasées) ?' : 'Souhaitez-vous lancer le calcul des prix des articles pour l\'unité de production courante ?';
    let res = await confirm(question, "Calcul du prix des articles");
    if (res) {
      this.upAps.upReglePrefere.reglePrefereId = this.upAps.selectedReglePrefere.id;
      this.upAps.upReglePrefere.reglePrefereCode = this.upAps.selectedReglePrefere.code;
      this.udpReglePrefereSvc.calculerArticlesPreferes(this.upAps.upReglePrefere, allowSelectManualItemPrefered)
        .subscribe(() => {
          this.initComputeMenu();
          this.udpReglePrefereSvc.announceRefreshGridArticlesPreferes();
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Le calcul du prix des articles a été réalisé avec succès.`, '', 8000);
        });

    }
  };

  calculerPrixPlatUdp = async (): Promise<void> => {
    let res = await confirm("Souhaitez-vous lancer le calcul de mise à jour du prix des plats pour l'unité de production courante ?", "Calcul du prix des plats");
    if (res) {
      this.gestionUniteProductionSvc.computePriceMealFromUdp(this.uniteDeProduction.id)
        .subscribe((response: ResponseWrapper<boolean>) => {
          this.isDisabledCalculPrixPlatBtn = true;
          this.initComputeMenu();
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Le calcul du prix des plats a été réalisé avec succès.`, '', 8000);
      });
    }
  };
}
