<p-dialog
  [(visible)]="displayDialog"
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [style]="{'min-width':'calc(80vh)'}"
  [contentStyle]="{'overflow':'visible'}">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-search mg-r-5"></i>
      <span class="mg-l-5">RECHERCHE DES ARTICLES</span>
    </div>
  </ng-template>


  <div class="search-element d-flex flex-row align-items-center" [style]="{'margin-bottom':'10px','margin-top':'10px'}">
    <i class="fas fa-box-open mg-r-5"></i><strong class="mg-r-5 inline">selection : </strong>
    <div [style.width.px]="utils.getWindowAvailableWidth(415)">
      <p-chips (keydown)="utils.onChangeDisabledInputChips($event)" [(ngModel)]="selectedCatalogueAchat"
               (onRemove)="onRemoveChipCatalogueAchat($event)">
        <ng-template let-item pTemplate="item">
          <i style="margin-right:2em; padding-bottom: 2px;vertical-align: middle"> {{item.produitArticle.libelle}}
            ({{item.fournisseur.libelle}})</i>
        </ng-template>
      </p-chips>
    </div>
  </div>

  <dx-data-grid
    [dataSource]="catalogueAchatList"
    keyExpr="id"
    [(selectedRowKeys)]="selectedRowKeysCatalogueAchat"
    [height]="utils.getWindowAvailableHeight(350)"
    [width]="utils.getWindowAvailableWidth(300)"
    (onSelectionChanged)="onSelectionChangedCatalogueAchat($event)"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #gridCatalogueAchat>

    <!--  COLONNES-->
    <dxi-column dataField="produitDeclinaisonId" alignment="left" caption="Denrée" [groupIndex]="0"
                groupCellTemplate="produitDeclinaisonGroupCellTemplate"
                [allowFiltering]="true">

    </dxi-column>
    <dxi-column dataField="fournisseur" alignment="left" caption="Fournisseur" [groupIndex]="1"
                groupCellTemplate="fournisseurGroupCellTemplate"
                [allowFiltering]="true" cellTemplate="fournisseurCellTemplate">
    </dxi-column>

    <dxi-column dataField="produitArticle" alignment="left" caption="Article"
                [allowFiltering]="true" cellTemplate="produitArticleCellTemplate">
    </dxi-column>

    <dxi-column dataField="referenceArticle" alignment="left" caption="Référence fournisseur" [allowFiltering]="true"></dxi-column>

    <dxi-column dataField="uniteDeStockage" alignment="left" caption="Unité de stockage"
                cellTemplate="uniteDeStockageCellTemplate"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="ratioUniteStockageUniteBase" alignment="left" caption="Ratio(US)"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="uniteDeFacturation" alignment="left" caption="Unité de facturation"
                cellTemplate="uniteDeFacturationCellTemplate"
                [allowFiltering]="true">
    </dxi-column>

    <dxi-column dataField="ratioUniteFacturationUniteBase" alignment="left" caption="Ratio(UF)"
                [allowFiltering]="true">
    </dxi-column>

    <!--  TEMPLATE-->
    <div *dxTemplate="let cell of 'produitDecliCellTemplate'">
      {{cell.row.data.produitArticle.produitDeclinaison.libelle}}
    </div>

    <div *dxTemplate="let cell of 'produitArticleCellTemplate'">
      {{cell.row.data.produitArticle.libelle}}
    </div>

    <div class="d-flex justify-content-between"  *dxTemplate="let cell of 'fournisseurCellTemplate'">
      <label class="font-12">
        {{cell.row.data.fournisseur.libelle}}
      </label>
      <div class="size-icon" *ngIf="!utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)" [pTooltip]="'Hérité du founisseur : ' +cell.row.data.fournisseurOwnerLibelle">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <strong class="fa-stack-1x custom-letter">H</strong>
          </span>
      </div>
    </div>

    <div *dxTemplate="let cell of 'produitDeclinaisonGroupCellTemplate'">
      Denrée : {{getProduitDeclinaisonLibelle(cell)}}
    </div>

    <div *dxTemplate="let cell of 'fournisseurGroupCellTemplate'">
      Fournisseur : {{getFournisseurLibelle(cell)}}
    </div>


    <div *dxTemplate="let cell of 'uniteDeStockageCellTemplate'">
      {{cell.row.data.uniteDeStockage.abreviation}}
    </div>

    <div *dxTemplate="let cell of 'uniteDeFacturationCellTemplate'">
      {{cell.row.data.uniteDeFacturation.abreviation}}
    </div>

    <!--  OPTIONS-->
    <dxo-search-panel
      [visible]="true">
    </dxo-search-panel>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
    <dxo-selection [mode]="'multiple'"></dxo-selection>
    <dxo-pager
      [showPageSizeSelector]="false"
      [showNavigationButtons]="true"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} articles(s)">
    </dxo-pager>
  </dx-data-grid>


  <ng-template pTemplate="footer">
    <hr>
    <div class="d-flex">
      <div class="mr-auto">
        <label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Selectionner les articles que vous
          voulez ajouter au lot marché </label>
      </div>
      <div class="mg-r-5">
        <button pButton icon="fas fa-plus" (click)="sendArticlesBasket()"
                [disabled]="selectedCatalogueAchat.length === 0" pTooltip="Affection du contenu du panier"
                tooltipPosition="top"
                showDelay="500" class="p-button-success">
        </button>
      </div>
      <div class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="p-button-secondary">
        </button>
      </div>
    </div>
  </ng-template>

</p-dialog>
