import {SiteDTO} from '../../dtos/site-dto';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {MarcheDTO} from '../../dtos/gerstion-marche/marche-dto';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export const URL_POST_SAVE_MARCHE = `dolrest/gestionmarche/save-marche`;
export const URL_GET_ALL_MARCHE = `dolrest/gestionmarche/get-all-marche`;
export const URL_GET_MARCHE_BY_ID = `dolrest/gestionmarche/get-marche-by-id`;
export const URL_DELETE_MARCHE = `dolrest/gestionmarche/delete-marche`;
export const URL_GET_TABLEAU_DE_BORD = `dolrest/gestionmarche/get-tableau-de-bord`;

export enum TAB_NAME_M {
  info,
  lotMarche,
  tableauDeBord
}


@Injectable()
export class GestionsMarcheService{

  protected optionsSites: SiteDTO[] = [];

  protected subjectMarcheDTO = new Subject<MarcheDTO>();
  marcheDto$ = this.subjectMarcheDTO.asObservable();

  constructor(public http: HttpClient,
              public utils: UtilsService,
              public auth2Svc: Auth2Service) {
    this.optionsSites = this.auth2Svc.utilisateur.siteListLocaux;
  }

  announceMarche(marcheDto :MarcheDTO, tabName: number){
    const wrapper: any = {
      marche: marcheDto,
      tabName: tabName
    };
    this.subjectMarcheDTO.next(wrapper)
  }

  /**
   * Sauvegarde un marché
   * @param marche
   */
  saveMarche(marche: MarcheDTO) {
    return this.http.post(URL_POST_SAVE_MARCHE, marche).pipe(
      catchError(err => this.utils.handleError(err)));
  }

  /**
   * Recuperation de tous les marchés
   * @param marche
   */
  getAllMarche() {
    return this.http.get(URL_GET_ALL_MARCHE).pipe(
      catchError(err => this.utils.handleError(err)));
  }

  /**
   * Recuperation de tous les marchés
   * @param marche
   */
  getMarcheById(idMarche: number) {
    return this.http.get(URL_GET_MARCHE_BY_ID, {
      params: new HttpParams()
        .set('idMarche', idMarche + '')
    }).pipe(
      catchError(err => this.utils.handleError(err)));
  }


  deleteMarcheById(idMarche: number) {
  return this.http.delete(URL_DELETE_MARCHE, {
    params: new HttpParams()
      .set('idMarche', idMarche + '')
    }).pipe(
      catchError(err => this.utils.handleError(err)));
  }


  /**
   * Récupérer le tableau de bord d'un marché
   * @param idMarche
   */
  getTableauDeBord(idMarche: number) {
    return this.http.get(URL_GET_TABLEAU_DE_BORD, {
      params: new HttpParams()
        .set('idMarche', idMarche + '')
    }).pipe(
      catchError(err => this.utils.handleError(err)));
  }

  displayGaugeLabel(value: number, label: string, gt1000: string) {

    if(value>1000){
      return `${label}<br>${gt1000}`;
    }

    return label;
  }

  getGaugeThreshold(maxValue: number) {

    const oneQuarter = maxValue * 0.25;
    const twoQuarter = maxValue * 0.6;
    const threeQuarter = maxValue * 0.8;

    const threshold = {};

    threshold[0] = {color: '#f2940a'};
    threshold[oneQuarter] = {color: '#f2cf07'};
    threshold[twoQuarter] = {color: '#b3e795'};
    threshold[threeQuarter] = {color: '#55d284'};

    return threshold;

  }

  displayGaugeAppend(label1000: string, label: string, value: number) {

    if (value > 1000) {
      return label1000;
    }

    return label;
  }

  displayGaugeValue(value: number) {

    if (value > 1000) {
      return (value / 1000).toFixed(2);
    }

    return value.toFixed(2);

  }



}
