import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MenusToolbarService} from '../../../../core/services/gestionmenus/menus-toolbar.service';
import {UtilsService} from '../../../../core/utils/utils.service';
import {PreferencesUtilisateurService} from '../../../../core/services/preferences-utilisateur.service';
import {MenusPlanning2Service} from '../../../../core/services/gestionmenus/menus-planning2.service';
import {DATEPICKER_FR_INLINE, MSG_KEY, MSG_SEVERITY, OEM, OETDP} from '../../../../core/constants';
import {cloneDeep as _cloneDeep} from 'lodash'
import * as moment from 'moment';
import {catchError, debounceTime, switchMap} from 'rxjs/operators';
import {SelectItem} from 'primeng/api';


@Component({
  selector: 'yo-validation-menu',
  templateUrl: './validation-menu.component.html',
  styleUrls: ['./validation-menu.component.scss']
})
export class ValidationMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  subValidationMenus: Subscription;
  subValidationMenuMonthChange: Subscription;

  displayDialog: boolean;
  localeFr = DATEPICKER_FR_INLINE;


  startDate: Date;
  stopDate: Date;
  selectedDates: Date[];
  disabledDays: number[] = [];
  datesCreationMenus: Date[] = [];
  datesSaisieEffectifs: Date[] = [];
  datesNonDisponibles: Date[] = [];
  dateMin: Date;

  OEM = OEM;
  OETDP = OETDP;
  optionsEcraserMenu: SelectItem[] = [
    {label: 'E-', value: this.OEM.AUCUN_ECRASEMENT},
    {label: 'E', value: this.OEM.ECRASER_MENUS_SANS_MODIFICATION_MANUELLE},
    {label: 'E+', value: this.OEM.ECRASER_MENUS_AVEC_MODIFICATIONS_MANUELLES},

  ];

  optionsEcraserTauxDePrise: SelectItem[] = [
    {label: 'TDP-', value: OETDP.AUCUN_ECRASEMENT},
    {label: 'TDP+', value: OETDP.ECRASER},
  ];

  optionEcraserTauxDePrise = this.OETDP.AUCUN_ECRASEMENT;
  optionEcraserMenu = this.OEM.AUCUN_ECRASEMENT;

  isValidationMenuOk: boolean;

  constructor(public menusToolbarSvc: MenusToolbarService,
              public mp2Svc: MenusPlanning2Service,
              public utils: UtilsService,
              public prefUserSvc: PreferencesUtilisateurService) {}

  ngOnInit() {

    // A l'ouverture du dialog
    this.subValidationMenus = this.menusToolbarSvc.displayValidationMenusDialog$.subscribe(response => {

      this.displayDialog = response;

      this.startDate = new Date();
      this.stopDate = new Date();

      this.optionEcraserMenu = this.OEM.AUCUN_ECRASEMENT;
      this.optionEcraserTauxDePrise=this.OETDP.AUCUN_ECRASEMENT;

      if (!this.utils.isNullOrEmpty(this.mp2Svc.planningMenus)) {
        if (this.mp2Svc.planningMenus.selectedDateMenu) {
          this.startDate = _cloneDeep(this.mp2Svc.planningMenus.selectedDateMenu);
          this.stopDate = _cloneDeep(this.mp2Svc.planningMenus.selectedDateMenu);
        }
        if (!this.utils.isCollectionNullOrEmpty(this.mp2Svc.planningMenus.dateList)) {
          this.startDate = _cloneDeep(moment(this.mp2Svc.planningMenus.dateList[0].date).toDate());
          this.stopDate = _cloneDeep(moment(this.mp2Svc.planningMenus.dateList[this.mp2Svc.planningMenus.dateList.length - 1].date).toDate());
        }

      }

      this.selectedDates = [this.startDate, this.stopDate];
      this.isValidationMenuOk = this.validDates(this.selectedDates);

      this.menusToolbarSvc.announceValidationMenuMonthChange(null);


    });

    // Au changement de mois sur le calendrier
    this.subValidationMenuMonthChange = this.menusToolbarSvc.validationMenuMonthChange$.pipe(
      debounceTime(100),
      switchMap(event => this.mp2Svc.changeMonth(this.mp2Svc.planningMenus.contratMenuConviveList[0],  this.mp2Svc.planningMenus.selectedDateMenu, event)),
      catchError(err => this.utils.handleError(err))
    ).subscribe(response => {

      // console.log('subValidationMenuMonthChange subscribe');
      this.disabledDays = response.disabledDays;
      this.datesSaisieEffectifs = response.datesSaisieEffectifs;
      this.datesCreationMenus = response.datesCreationMenus;
      this.dateMin = response.dateMin;
    });

  }

  closeDialog = () => {
    this.displayDialog = false;
  };

  ngOnDestroy(): void {

    this.utils.unsubscribe(this.subValidationMenus);
    this.utils.unsubscribe(this.subValidationMenuMonthChange);
  }

  changeDates = ($event: any) => {
    console.log('validation-menu changeDates', $event);

    this.isValidationMenuOk = this.validDates(this.selectedDates);

    this.menusToolbarSvc.announceValidationMenuMonthChange($event);

  };

  validDates = (dates: Date[]) => {

    if (this.utils.isCollectionNullOrEmpty(dates) || dates.length !== 2
      || this.utils.isNullOrEmpty(dates[0])
      || this.utils.isNullOrEmpty(dates[1])
    ) {
      return false;
    }

    return true;

  };

  validerMenus = (valid: boolean) => {


    // console.log('validation-menu validerMenus');

    this.isValidationMenuOk = this.validDates(this.selectedDates);

    if (this.isValidationMenuOk) {

      // console.log('validation-menu validerMenus, on peut valider');

      this.mp2Svc.validerMenus(this.selectedDates, this.mp2Svc.planningMenus.contratMenuConviveList[0].id,this.optionEcraserMenu, this.optionEcraserTauxDePrise, valid).subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {
          this.closeDialog();

          if (valid) {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Période validée avec succès.', '', 5000);
          } else {
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Période annulée avec succès.', '', 5000);
          }

          // recharger le planning des menus
          this.menusToolbarSvc.announcePrestations(this.mp2Svc.planningMenus.contratMenuConviveList);
        }

      });
    }
  };

  getMonthMenusCompoDates = ($event: any) => {

    this.menusToolbarSvc.announceValidationMenuMonthChange($event);

  };

  ngAfterViewInit(): void {

  }


}
