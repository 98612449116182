import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {RoutemapService} from '../../../../core/services/routemap.service';

import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {GenericManageDialogService} from '../../../../core/services/generics/generic-manage-dialog.service';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {StockMouvementStatutDTO} from "../../../../core/dtos/stock-mouvement-statut-dto";
import {
  StatutSavedSupplier,
  StockMouvementStatutService
} from "../../../../core/services/gestion-stock/stock-mouvement-statut.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-grille-statuts',
  templateUrl: './grille-statuts.component.html',
  styleUrls: ['./grille-statuts.component.scss']
})
export class GrilleStocksMouvementsStatutComponent implements OnInit, OnDestroy {

  statutsList: StockMouvementStatutDTO[] = [];

  hasGestion = false;

  subStatutSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/status';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              public gds: GenericDatagridService,
              private smsSvc: StockMouvementStatutService,
              private i18nSvc: InternationalizationService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always'
  }

  ngOnInit(): void {
    this.initVariantes();
    this.initHasGestionIDistri();
    this.varianteSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subStatutSaved);
  }

  initVariantes = (): void => {
    this.smsSvc.getAll()
      .subscribe(response => {
        this.statutsList = response.resultList;
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasStocks$.subscribe(response => this.hasGestion = response);
  };

  canEdit = (): boolean => this.auth2Svc.hasSitesLocaux() && this.hasGestion;

  deleteValues = async (): Promise<void> => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionné(s) ?', 'Confirmation');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.statutsList.find(v => v.id === idSelected))
        .map((s: StockMouvementStatutDTO) => s.id);
      this.smsSvc.deleteByIds(idsToDelete)
        .subscribe(o => {
          const response = o.one;
          if (response.error) {
            const partsErrors: string[] = response.error.split(' ');
            if (partsErrors.length > 1 && partsErrors[1] && partsErrors[1].trim().length > 0) {
              const msgError: string = this.i18nSvc.getLabelFromCode(partsErrors[0], [partsErrors[1]], 'fr');
              this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.WARN, msgError);
            }
          }
          if (response.idsDeleted) {
            this.statutsList = this.statutsList.filter(v => !response.idsDeleted.includes(v.id));
          }
        });
    }
  };

  varianteSavedSubscription = (): void => {
    this.subStatutSaved = this.smsSvc.statutSaved$
      .subscribe((response: StatutSavedSupplier) => {
        if (!response.isUpdate) {
          this.statutsList.push(response.statut);
        } else {
          const idxToReplace = this.statutsList.findIndex(s => s.id === response.statut.id);
          this.statutsList[idxToReplace] = response.statut;
          console.log(this.statutsList);
        }
      });
  };

  open = (s: StockMouvementStatutDTO): void => {
    this.smsSvc.announceOpenDialogStatut(s);
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les statuts de stocks de mouvements`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
