import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {RoutemapService} from '../../../core/services/routemap.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ActivatedRoute} from '@angular/router';
import {UniteDeProductionSupplier} from '../unite-production-resolver.service';
import {Observable, Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {cloneDeep as _cloneDeep, orderBy as _orderBy, sortBy as _sortBy} from 'lodash'
import {FS_ROUTES, HELP_FOLDERS, JOURS_SEMAINES, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {SecteurDTO} from '../../../core/dtos/secteur-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {SecteurFournisseurDTO} from '../../../core/dtos/secteurfournisseur-dto';
import {SecteursFournisseursService} from '../../../core/services/entities/secteurs-fournisseurs.service';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../../core/dtos/unite-de-production__secteur-fournisseur-dto';
import {
  FournisseurLivraisonsAffectationSupplier,
  UniteDeProduction__secteurFournisseurService
} from '../../../core/services/entities/unite-de-production__secteur-fournisseur.service';
import {UniteDeProduction__LivraisonDTO} from '../../../core/dtos/unite-de-production__livraison-dto';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {MenuItem} from 'primeng/api';
import {UniteDeProductionReglePrefereService} from "../../../core/services/entities/unite-de-production__regle-prefere.service";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {confirm} from "devextreme/ui/dialog";


@Component({
  selector: 'yo-up-livraisons',
  templateUrl: './up-livraisons.component.html',
  styleUrls: ['./up-livraisons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpLivraisonsComponent implements OnInit, OnDestroy {

  subscriptionRoute: Subscription;
  subSidenav: Subscription;
  subUdpLivraison: Subscription;

  hasAdministration$: Observable<boolean>;

  joursSemaineList = _cloneDeep(JOURS_SEMAINES);
  selectedJourSemaineList: { value, viewValue }[] = [];

  secteurList: SecteurDTO[] = [];
  selectedSecteurList: SecteurDTO[] = [];

  displayedColumns = ['info', 'fournisseur'];

  secteurFournisseurList: SecteurFournisseurDTO[] = [];
  selectedSecteurFournisseurList: SecteurFournisseurDTO[] = [];

  affectationsSupplier: FournisseurLivraisonsAffectationSupplier;
  livraisonsOfUDP: UniteDeProduction__LivraisonDTO[] = [];

  form: FormGroup = new FormGroup({});
  formJoursLivraisonUDP: FormGroup = new FormGroup({});

  uniteDeProduction: UniteDeProductionDTO;

  navUpLivraison: MenuItem[] = [];
  activeItem: MenuItem;
  displayNavFournisseurs: boolean = true;

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production-livraisons';

  constructor(public udpSvc: UnitesDeProductionService,
              private auth2Svc: Auth2Service,
              public udpSecteurFournisseurSvc: UniteDeProduction__secteurFournisseurService,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private gfs: GenericFormService,
              public gds: GenericDatagridService,
              private cd: ChangeDetectorRef,
              public secteursFournisseursSvc: SecteursFournisseursService,
              private udpReglePrefereSvc: UniteDeProductionReglePrefereService,
              public utils: UtilsService) {
  }


  ngOnInit() {
    this.hasAdministration$ = this.auth2Svc.hasAdministration$;
    this.initNavUpLivraison();
    // abonnement à l'annonce de l'ouverture du sidenav, cas où le router n'est pas reloadé, on veut quand meme reinit
    this.sideNavSubscription();
    // chargement des données initiales
    this.routeSubscription();
    // reaffectation de livraisonsOfUDP
    this.subscriptionUdpLivraison();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subSidenav);
    this.utils.unsubscribe(this.subUdpLivraison);
  }

  /**
   * chargement des données initiales
   */
  routeSubscription = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { uniteDeProductionSupplier: UniteDeProductionSupplier }) => {
        this.uniteDeProduction = data.uniteDeProductionSupplier.uniteDeProduction;
        this.secteurList = data.uniteDeProductionSupplier.secteurList;
        this.secteurFournisseurList = [];
        if (!this.utils.isCollectionNullOrEmpty(data.uniteDeProductionSupplier.secteurFournisseurList)) {
          this.secteurFournisseurList = _sortBy(data.uniteDeProductionSupplier.secteurFournisseurList, ['secteur.code', 'fournisseurLibelle']);
        }
        this.livraisonsOfUDP = data.uniteDeProductionSupplier.livraisonsOfUDP;
        this.initForm(this.uniteDeProduction);
      });
  };

  subscriptionUdpLivraison = () => {
    this.subUdpLivraison = this.udpSecteurFournisseurSvc.udpLivraison$.subscribe(data => {
      this.livraisonsOfUDP = [...data];
    });
  };

  /**
   * abonnement à l'annonce de l'ouverture du sidenav, cas où le router n'est pas reloadé, on veut quand meme reinit
   */
  sideNavSubscription = () => {
    this.subSidenav = this.routeMapSvc.sidenav$.subscribe(sidenav => {
      //on reinitialise les formgroups
      this.initForm(this.uniteDeProduction);
      // console.log('subParent sidenav');
    });
  };


  initNavUpLivraison = () => {
    this.navUpLivraison = [
      {
        label: 'Fournisseur',
        command: (event) => {
          this.displayNavFournisseurs = true;
        }
      },
      {
        label: 'Planning livraison',
        command: (event) => {
          this.displayNavFournisseurs = false;
        }
      },
    ];
    this.activeItem = this.navUpLivraison[0];
  };

  /**
   * Initialiser les formulaires
   */
  initForm = (uniteDeProduction: UniteDeProductionDTO) => {
    //initialiser les jours semaines de l'unite de production
    this.selectedJourSemaineList = [];
    if (!this.utils.isNullOrEmpty(uniteDeProduction) && !this.utils.isCollectionNullOrEmpty(uniteDeProduction.uniteDeProduction__jourLivraisonList)) {
      this.joursSemaineList.map(item => {
        uniteDeProduction.uniteDeProduction__jourLivraisonList.map(udpjs => {
          if (udpjs.numeroJourSemaine === item.value) {
            this.selectedJourSemaineList.push(item);
          }
        })
      });
    }
    // initialiser les secteurs
    this.selectedSecteurList = this.gfs.initMatSelectionList(uniteDeProduction.uniteDeProduction__secteurList, this.secteurList, 'idSecteur', 'id');
    this.formJoursLivraisonUDP = new FormGroup({
      joursSemaineUDP: new FormControl(this.selectedJourSemaineList, Validators.required),
      secteursUDP: new FormControl(this.selectedSecteurList, Validators.required),
      secteursFournisseursUDP: new FormControl(this.selectedSecteurFournisseurList)
    });

    // initialiser les secteurFournisseur
    this.getSecteursFournisseurs(this.selectedSecteurList);
    this.form = new FormGroup({
      formJoursLivraisonUDP: this.formJoursLivraisonUDP
    });
    this.cd.markForCheck();
  };

  /**
   * Récupérer les secteurFournisseur rattachées aux secteurs sélectionnés
   * Mettre à jour côté front les affectations en enlevant les fournisseurs qui ne font plus partie de la sélection
   * Enlever les secteurFournisseur  sélectionnées qui étaitent rattachées aux anciens secteurs
   */
  private getSecteursFournisseurs = (secteurs: SecteurDTO[]) => this.secteursFournisseursSvc.findSecteursFournisseursBySecteurs(secteurs)
    .toPromise()
    .then(response => {
      let resultList = _sortBy(response.resultList, ['secteur.code', 'fournisseurLibelle', 'fournisseurLibelle']);
      this.secteurFournisseurList = resultList;

      //on alimente la liste des secteurs fournisseurs (etape 3)
      // this.secteurFournisseurDatasource = new MatTableDataSource(resultList);

      //Si pas de secteur sélectionné , pas d'affectation possible (etape 4)
      if (this.utils.isCollectionNullOrEmpty(secteurs)) {
        this.selectedSecteurFournisseurList = [];
      } else {
        //On n'affiche que les secteurs fournisseurs de l'unité de prod qui correspondent à la liste des secteurs sélectionnés
        if (!this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {

          this.selectedSecteurFournisseurList = resultList.filter(item => {
            for (let livOfUDP of this.livraisonsOfUDP) {
              if (livOfUDP.idSecteurFournisseur === item.id) {
                return true;
              }
            }
            return false;
          });

        }
      }
      // mise à jour de la liste des fournisseurs selectionnes
      this.formJoursLivraisonUDP.controls['secteursFournisseursUDP'].setValue(this.selectedSecteurFournisseurList);
      // on notifie la matrice d'affectation
      this.affectationsSupplier = new FournisseurLivraisonsAffectationSupplier(this.selectedJourSemaineList, this.selectedSecteurFournisseurList, this.uniteDeProduction, this.livraisonsOfUDP, false);
      this.cd.markForCheck();
    });


  /**
   * Affecter les secteurs fournisseurs aux unité de production
   * Methode pour la grille d'affectation côté front
   * @param {SecteurFournisseurDTO} row
   * @return {FournisseurLivraisonsAffectationSupplier}
   */
  addToAffectationFournisseurs = event => {
    this.selectedSecteurFournisseurList = event.value ? event.value : [];
    this.selectedSecteurFournisseurList = _sortBy(this.selectedSecteurFournisseurList, 'fournisseurLibelle');
    //on notifie la matrice d'affectation des secteurs fournisseurs à afficher
    //todo rzerzerzezerzerzer
    this.affectationsSupplier = new FournisseurLivraisonsAffectationSupplier(this.selectedJourSemaineList, this.selectedSecteurFournisseurList, this.uniteDeProduction, this.livraisonsOfUDP, true);
  };

  /**
   * Lorsque la grille d'affectations change, elle emet la liste des secteurs fournisseurs rattachés à l'unité de production
   * @param {UniteDeProduction__SecteurFournisseurDTO[]} secteursFournisseursOfUDP
   */
  onChangeAffectations = (livraisonsOfUDP: UniteDeProduction__LivraisonDTO[]) => {
    this.livraisonsOfUDP = livraisonsOfUDP;
  };

  onChangeSecteurs = event => {
    this.selectedSecteurList = event.value ? event.value : [];
    //récupérer les secteurFournisseurs des secteurs selectionnés et affecter ces secteurs à l'unité de production s'il sont déjà connus
    this.getSecteursFournisseurs(this.selectedSecteurList);
    //notifier la matrice
    this.affectationsSupplier = new FournisseurLivraisonsAffectationSupplier(this.selectedJourSemaineList, this.selectedSecteurFournisseurList, this.uniteDeProduction, this.livraisonsOfUDP, false);
  };

  onChangeJoursSemaine = event => {
    this.selectedJourSemaineList = event.value ? event.value : [];
    this.selectedJourSemaineList = _orderBy(this.selectedJourSemaineList, 'value');
    this.affectationsSupplier = new FournisseurLivraisonsAffectationSupplier(this.selectedJourSemaineList, this.selectedSecteurFournisseurList, this.uniteDeProduction, this.livraisonsOfUDP, false);
  };

  saveLivraisons = () => {
    if (this.displayNavFournisseurs) {
      this.setAffectations();
    }
    if (this.form.valid) {
      this.uniteDeProduction = this.udpSvc.prepareJoursLivraisonDispo(this.uniteDeProduction, this.selectedJourSemaineList);
      this.uniteDeProduction = this.udpSvc.prepareSecteurs(this.uniteDeProduction, this.selectedSecteurList);
      this.uniteDeProduction.uniteDeProduction__secteurFournisseurList = this.udpSvc.prepareSecteursFournisseurs(this.uniteDeProduction, this.livraisonsOfUDP);
      this.udpSvc.saveLivraisons(this.uniteDeProduction, this.livraisonsOfUDP).subscribe(async response => {

        if (!this.utils.isResponseSupplierError(response)) {
          //annoncer que la livraison a été sauvegardée. La grille des unites de prod doit être abonnée pour faire la mise à jour
          this.udpSvc.announceSavedDTO(response);
          this.uniteDeProduction = response.one;
          // récuperation des udp_livraison mise a jour
          // this.loadUdpLivraison(this.uniteDeProduction);
          this.initForm(this.uniteDeProduction);
          await this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GUP_UPRO, this.uniteDeProduction.id, 'livraisons']);
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Enregistrement des livraisons de l'unité de production "${this.uniteDeProduction.libelle.toUpperCase()}"' effectué.`, '', 3000);

          // Demande de mise a jour des articles preférés
          const result = confirm('Souhaitez vous lancer la mise à jour des articles préférés ?', 'Confirmation');
          const isConfirmed: boolean = await result;
          if (isConfirmed) {
            this.gds.search(this.udpReglePrefereSvc.filterByIdUniteDeProduction(this.uniteDeProduction.id)).subscribe(response => {
              this.udpReglePrefereSvc.calculerArticlesPreferes(response.resultList[0], false).subscribe();
            });
          }
        }
      });
    } else {
      //Afficher les erreurs de validation
      this.gfs.validateAllFormFields(this.form);
    }
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Paramétrage des jours de livraison`;
    dms.logo = 'fa fa-question-circle  yoni-color';


    const p1: Paragraphe = new Paragraphe();
    p1.title = `Etape 1`;
    p1.lines = [
      ` Sélectionner les jours de livraison.`,
    ];

    const p2: Paragraphe = new Paragraphe();
    p2.title = `Etape 2`;
    p2.lines = [
      `Sélectionner les secteurs auxquels l'unité de production doit être rattachée.`,
      `Un secteur est une zone géographique.`,
    ];

    const p3: Paragraphe = new Paragraphe();
    p3.title = `Etape 3`;
    p3.lines = [
      `Sélectionner les fournisseurs pouvant livrer sur les secteurs sélectionnés.`,
    ];

    const p4: Paragraphe = new Paragraphe();
    p4.title = `Etape 4`;
    p4.lines = [
      `Affecter les jours de livraison aux fournisseurs choisis.`,
    ];

    const p5: Paragraphe = new Paragraphe();
    p5.title = ``;
    p5.lines = [
      `<i class="fa fa-info-circle yoni-color"></i>&nbsp;Si vous vous êtes trompé, aller sur un autre onglet et revenez sur 'LIVRAISONS' pour recharger l'état initial.`,
      `N'oubliez pas d'enregistrer&nbsp;<i class="fa fa-smile-o yoni-color"></i>.`,

    ];

    dms.content = {
      intro: `Les livraisons se paramètrent en 4 étapes.`,
      paragraphes: [p1, p2, p3, p4, p5]
    };

    return dms;
  };

  tooltipSecteurFournisseur = (secteurFournisseur: SecteurFournisseurDTO): string => {
    let response: string = secteurFournisseur.fournisseurLibelle;
    if (!secteurFournisseur.actif) {
      response += ' ' + 'Attention : le secteur fournisseur est inactif';
    }
    if (!secteurFournisseur.fournisseurActif) {
      response += ' ' + 'Attention : la filiale est inactive';
    }

    let response2: string = '\nId secteur-fournisseur : ' + secteurFournisseur.id +
      '\nFournisseur            : ' + secteurFournisseur.fournisseurLibelle + ', id=' + secteurFournisseur.fournisseurId +
      '\nFiliale                : ' + secteurFournisseur.fournisseurLibelle + ', id= ' + secteurFournisseur.fournisseurId +
      '\nSecteur                : ' + secteurFournisseur.secteur.libelle + ', id=' + secteurFournisseur.secteur.id +
      '\nActif                  : ' + secteurFournisseur.actif;

    // A décommenter si on veut afficher les infos contenues dans reponse2
    // response += response2;
    // let responseSite: string = " (Site : " + secteurFournisseur.site.libelle + ")";
    let responseSite: string = ' (Site : ' + secteurFournisseur.secteur.site.libelle + ')';
    response += responseSite;

    return response;
  };

  /**
   * Renvoie le nombre de secteurs fournisseurs sélectionnés.
   */
  getNbSecteursFournisseursActifs = (): number => {
    let response: number = this.selectedSecteurFournisseurList.length;
    return response;
  };

  /**
   *
   * @param {UniteDeProduction__SecteurFournisseurDTO[]} secteursFournisseursOfUDP
   * @param {{value; viewValue}[]} joursSemaine
   */
  setAffectations = () => {

    if (this.utils.isCollectionNullOrEmpty(this.livraisonsOfUDP)) {
      this.livraisonsOfUDP = [];
    }
    //Lignes secteurs fournisseurs rattaché à l'unité de production
    this.affectationsSupplier.secteursFournisseurs = _sortBy(this.affectationsSupplier.secteursFournisseurs, ['secteur.code', 'fournisseurLibelle', 'fournisseurLibelle']);

    //Init des Checkboxes
    // console.log('this.livraisonsOfUDP before', this.livraisonsOfUDP);
    let newLivraisonsOfUDP: UniteDeProduction__LivraisonDTO[] = [];
    for (let row of this.affectationsSupplier.secteursFournisseurs) {
      for (let col of this.affectationsSupplier.joursSemaine) {

        console.log('joursSemaine ', col);
        console.log('this.livraisonsOfUDP ', this.livraisonsOfUDP.filter(x => x.idSecteurFournisseur === 34));
        let elt = this.utils.getFirstElement(this.livraisonsOfUDP.filter(item => {
          if (item.idSecteurFournisseur === row.id && item.numeroJourSemaine === col.value) {

            if (item.actif === undefined) {
              item.actif = true;
            }
            return true;
          }
          return false;
        }));

        if (this.utils.isNullOrEmpty(elt)) {

          elt = new UniteDeProduction__LivraisonDTO();
          elt.idSecteurFournisseur = row.id;
          elt.numeroJourSemaine = col.value;
          elt.actif = this.affectationsSupplier.newIsChecked;

          // fix redmine 1330  doit être une date, par defaut on positionne à 16h00
          elt.delaiLivraisonHeureLimite = this.utils.setHour(new Date(), 16);

          elt.delaiLivraisonJours = 0;
        }
        newLivraisonsOfUDP.push(elt);
      }
    }
    this.livraisonsOfUDP = [...newLivraisonsOfUDP];
  };

  loadUdpLivraison(uniteDeProduction :UniteDeProductionDTO){
    this.udpSecteurFournisseurSvc.getUdpLivraisonList(uniteDeProduction.id).subscribe((response :ResponseWrapper<UniteDeProduction__LivraisonDTO>) =>{
      this.livraisonsOfUDP = response.resultList;
    });
  }

}
