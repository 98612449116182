import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BonCfDTO} from '../../../core/dtos/boncfs-dto';
import {BonCfDetailDTO} from '../../../core/dtos/boncf-detail-dto';
import {ValueDTO} from '../../../core/dtos/value-dto';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {cloneDeep as _cloneDeep, find as _find, uniqBy as _uniqBy} from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {CommandesService} from '../../../core/services/gestion-commandes/commandes.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {WorkflowsService} from '../../../core/services/entities/workflows.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {DomSanitizer} from '@angular/platform-browser';
import {
  BCF_MAIL_STATUT,
  BCF_STATUT,
  DATEPICKER_FR,
  HELP_FOLDERS,
  MSG_KEY,
  MSG_SEVERITY,
  RECEPTION_STATUT,
  TYPES_CODES
} from '../../../core/constants';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {SearchSupplier} from '../../../core/suppliers/search-supplier';
import {switchMap} from 'rxjs/operators';
import {saveAs as fs_saveAs} from 'file-saver';
import {UniqueCodeService} from '../../../core/services/unique-code.service';
import {BonReceptionService} from '../../../core/services/entities/bon-reception.service';
import {BonReceptionDTO} from '../../../core/dtos/bon-reception-dto';
import {FormGroup} from '@angular/forms';
import {FormFieldBaseSupplier} from '../../../core/suppliers/form-fieldbase-supplier';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {combineLatest, of, Subscription} from 'rxjs';
import {ReceptionStatutService} from '../../../core/services/entities/reception-statut.service';
import {DialogMsgSupplier} from '../../../core/suppliers/dialog-msg-supplier';
import {BoncfService} from '../../../core/services/entities/boncf.service';
import {MailHistoService} from '../../../core/services/entities/mail-histo.service';
import {Menu} from 'primeng/menu';
import {AppellationsService} from "../../../core/services/entities/appellations.service";
import {LitigesService} from "../../../core/services/entities/litiges.service";
import {OrigineService} from "../../../core/services/entities/origines-service";
import {DxDataGridComponent} from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import {Sort} from "../../../core/suppliers/generics/generic-request-supplier";
import {DevextremeService, FilterItem} from "../../../core/services/technique/devextreme.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-bc-a-receptionner',
  templateUrl: './bc-a-receptionner.component.html',
  styleUrls: ['./bc-a-receptionner.component.scss']
})
export class BcAReceptionnerComponent implements OnInit, OnDestroy {

  subSavedBonReception: Subscription;
  subCreateBonReception: Subscription;
  commandesAReceptionner: BonCfDTO[];

  totalRecords = 0;
  loadingTable: boolean = false;

  pathFile: string = HELP_FOLDERS.COMMANDES_FOURNISSEURS + '/bon-a-receptionner';

  /**
   * Selection des actions sur le bon de réception
   */
  brlActions: MenuItem[] = [
    {
      label: 'Voir articles',
      icon: 'fas fa-th-large',
      command: (event) => this.bonReceptionSvc.announceBonReceptionGridDxView(this.selectedBonReception)
    },
    {
      label: `Calculé lors du choose action`,
      icon: 'fas fa-trash',
      command: (event) => this.deleteBonReception(this.selectedBonReception)
    }
  ];


  BONCF_MAIL_STATUT = BCF_MAIL_STATUT;
  receptionStatutCode = RECEPTION_STATUT;

  items: MenuItem[] = [{label: 'Supprimer'}];

  // dialog edit
  bonCf: BonCfDTO;
  lignesBonCf: BonCfDetailDTO[];
  displayEditDialog: boolean = false;
  dialogTitle = 'Détails';

  // items pour le filtre de sélection des listes de besoin
  filterItemsListeBesoin: ValueDTO[];
  // items pour le filtre de sélection des unités de production
  filterItemsUdp: ValueDTO[];
  // items pour le filtre de sélection des fournisseurs
  filterItemsFournisseur: ValueDTO[];

  filterDatesLivraisons: Date[];

  fr: any;

  // Bon de reception dialog
  formBonReception: FormGroup = new FormGroup({});
  selectedBonCf: BonCfDTO;
  selectedBonReception: BonReceptionDTO;
  displayCreateBonReception: boolean;
  fieldsBonReception: FormFieldBaseSupplier<any>[] = [];

  // articles reception dialog
  displayDialogArticlesReception: boolean = false;

  // article reception responsive formulaire
  displayDialogArticlesReceptionForm: boolean = false;

  isActionDisabled: boolean = true;

  event: LazyLoadEvent;

  nextSteps: MenuItem[] = [
    {
      label: `Passer les commandes sélectionnées à l'étape suivante :`,
      items: [
        {
          label: 'Réceptionnées',
          icon: 'fas fa-angle-double-right',
          command: (event) => this.updateToCommandeReceptionnee()
        }
      ]
    }
  ];

  optionsPrint: MenuItem[] = [
    {
      label: 'Imprimer les commandes sélectionnées',
      icon: 'fas fa-file-invoice',
      command: (event) => this.printPDFCommandes()
    },
    {
      label: 'Imprimer les bons de réception sélectionnés',
      icon: 'fas fa-shipping-fast',
      command: (event) => this.printPDFBonsReceptions()
    }
  ];

  @ViewChild("grid") grid: DxDataGridComponent;

  dataSource: CustomStore;
  allMode: string;
  checkBoxesMode: string;

  listesBesoinsIdsSelected: number[] = [];
  udpIdsSelected: number[] = [];
  fournisseursIdsSelected: number[] = [];

  subMailsBonCfEnvoyes: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public auth2Svc: Auth2Service,
              public commandesSvc: CommandesService,
              private bonReceptionSvc: BonReceptionService,
              private gds: GenericDatagridService,
              public wkfSvc: WorkflowsService,
              private uniqueCodeSvc: UniqueCodeService,
              private gfs: GenericFormService,
              private receptionStatutSvc: ReceptionStatutService,
              public utils: UtilsService,
              public domSanitizer: DomSanitizer,
              private bonCfSvc: BoncfService,
              public appellationSvc: AppellationsService,
              public litigeSvc: LitigesService,
              public origineSvc: OrigineService,
              public mailHistoSvc: MailHistoService,
              private i8nSvc: InternationalizationService,
              private dxSvc: DevextremeService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }


  initFormBonReception(bonReception: BonReceptionDTO) {
    this.fieldsBonReception = this.bonReceptionSvc.getFields(bonReception);
    this.formBonReception = this.gfs.toFormGroup(this.fieldsBonReception);
  }

  ngOnInit() {
    this.loadingTable = true;
    this.fr = DATEPICKER_FR;

    this.mailsBonCfEnvoyesSubscription();
    this.refreshDataSvc();
    this.initFilters();
    this.initCustomStore();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSavedBonReception);
    this.utils.unsubscribe(this.subCreateBonReception);
    this.utils.unsubscribe(this.subMailsBonCfEnvoyes);
  }

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  onChangeFilterDateLivraison = (): void => {
    if (this.grid)
      this.grid.instance.refresh();
  };

  onChangeFilterFournisseurs = ($event): void => {
    if (this.grid) {
      this.fournisseursIdsSelected = $event.value;
      this.grid.instance.refresh();
    }
  };

  onChangeFilterUdps = ($event): void => {
    if (this.grid) {
      this.udpIdsSelected = $event.value;
      this.grid.instance.refresh();
    }
  };

  onChangeFilterListesBesoins = ($event): void => {
    if (this.grid) {
      this.listesBesoinsIdsSelected = $event.value;
      this.grid.instance.refresh();
    }
  };

  refreshDataSvc = (): void => {
    this.appellationSvc.getAllFromEnvironnement();
    this.litigeSvc.getAllFromEnvironnement();
    this.origineSvc.getAllFromEnvironnement();
  }

  initFilters = (): void => {
    this.commandesSvc.getFilterItemsListeBesoin(BCF_STATUT.COMMANDE_A_RECEPTIONNER).subscribe(response => {
      this.filterItemsListeBesoin = response.resultList;
    });
    this.commandesSvc.getFilterItemsUdp(BCF_STATUT.COMMANDE_A_RECEPTIONNER).subscribe(response => {
      this.filterItemsUdp = response.resultList;
    });
    this.commandesSvc.getFilterItemsFfs(BCF_STATUT.COMMANDE_A_RECEPTIONNER).subscribe(response => {
      this.filterItemsFournisseur = response.resultList;
    });
  };

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const ssWrapper = new SearchSupplierWrapper();
        const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

        ssWrapper.filtersMap['statutCode'] = new SearchSupplier(BCF_STATUT.COMMANDE_A_RECEPTIONNER);
        if (!this.utils.isCollectionNullOrEmpty(this.filterDatesLivraisons)) {
          const startDate = this.filterDatesLivraisons[0].getTime();
          let stopDate = _cloneDeep(startDate);
          if (this.filterDatesLivraisons[1]) {
            stopDate = this.filterDatesLivraisons[1].getTime();
          }
          ssWrapper.filtersMap['startDateLivraison'] = new SearchSupplier(startDate);
          ssWrapper.filtersMap['stopDateLivraison'] = new SearchSupplier(stopDate);
        }

        if (this.listesBesoinsIdsSelected && this.listesBesoinsIdsSelected.length)
          ssWrapper.filtersMap['listesBesoins'] = new SearchSupplier(undefined, this.listesBesoinsIdsSelected);
        if (this.udpIdsSelected && this.udpIdsSelected.length)
          ssWrapper.filtersMap['udps'] = new SearchSupplier(undefined, this.udpIdsSelected);
        if (this.fournisseursIdsSelected && this.fournisseursIdsSelected.length)
          ssWrapper.filtersMap['ffs'] = new SearchSupplier(undefined, this.fournisseursIdsSelected);

        let urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(pageSize, page, sorts);
        if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id') {
          // Si je coche tout => Il faut omettre la pagination
          urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(null, null, sorts);
        }

        return this.commandesSvc.searchCommandes(ssWrapper, urlPaginationParams).toPromise().then(response => {
          this.commandesAReceptionner = response.resultList;

          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;

          return {
            data: response.resultList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => { return null; }
    });
  }

  deleteBonCfs = async (): Promise<void> => {
    let rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();

    const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesAReceptionner.find(p => id === p.id));
    // on ne doit pas supprimer les bons de commandes des sites secondaires
    const bonCfsToDelete = selectedBonCfs.filter(boncf => this.auth2Svc.isSiteLocal(boncf.site.id));
    const bonCfsToNoNotDelete = selectedBonCfs.filter(boncf => !this.auth2Svc.isSiteLocal(boncf.site.id));

    if (!this.utils.isCollectionNullOrEmpty(bonCfsToNoNotDelete)) {
      let summary = `Vous ne pouvez pas supprimer la/les commande(s) ${bonCfsToNoNotDelete.map(item => item.numeroBcf).join(', ')}. La/Les commande(s) appartiennent à un site référent.`;
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, summary, '', 3000);
    }

    if (!this.utils.isCollectionNullOrEmpty(bonCfsToDelete)) {
      this.dxSvc.dxDeleteItemsConfirmation(rowsSelected, () => {
        this.commandesSvc.delete(bonCfsToDelete).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.grid.instance.refresh();
          }
        });
      });
    }
  };

  updateToCommandeReceptionnee = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.commandesSvc.updateCommandesStatut(rowsSelected, BCF_STATUT.COMMANDE_RECEPTIONNEE).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            if (response.one && response.one.error) {
              const err: string = response.one.error;
              const args: string[] = err.split(',');
              const msgWarning: string = this.i8nSvc.getLabelFromCode(args[0], ["réceptionnée(s)", args[1]], 'fr');
              this.utils.showMsg(MSG_KEY.BC_A_RECEPTIONNER, MSG_SEVERITY.WARN, msgWarning);
            }
            this.grid.instance.refresh();
          }
        });
      });
  };

  openObject: (bonCf: BonCfDTO) => void = (bonCf: BonCfDTO) => {
    this.bonCf = undefined;
    this.commandesSvc.getLignesCommandeFournisseur(bonCf.id).subscribe(response => {
      this.lignesBonCf = [];
      if (!this.utils.isResponseSupplierError(response)) {
        this.displayEditDialog = true;
        this.dialogTitle = this.commandesSvc.getCfTitle(bonCf);
        this.lignesBonCf = response.resultList;
        this.bonCf = _cloneDeep(bonCf);
      }
    });
  };

  printPDFCommandes = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesAReceptionner.find(p => id === p.id));
        this.commandesSvc.printPDFCommandes(selectedBonCfs).subscribe(response => {
          let blob = new Blob([response], {type: 'application/pdf'});
          fs_saveAs(blob, 'commandes-confirmees.pdf');
        });
      });
  };

  printPDFBonsReceptions = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.bonReceptionSvc.printPDFBonsReceptions(rowsSelected).subscribe(response => {
          let blob = new Blob([response], {type: 'application/pdf'});
          fs_saveAs(blob, 'commandes-confirmees.pdf');
          this.grid.instance.refresh();
        });
      });
  }

  sendMail = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(async rowsSelected => {
        const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesAReceptionner.find(p => id === p.id));
        const result = confirm('Confirmez-vous l\'envoi de des commandes sélectionnées aux fournisseurs ?', 'Envoi d\'un mail');
        const isDeleted: boolean = await result;
        if(isDeleted) {
          this.commandesSvc.sendMailToFournisseurs(selectedBonCfs);
          this.utils.showMsg(MSG_KEY.BC_A_RECEPTIONNER, MSG_SEVERITY.SUCCESS, 'Commandes envoyées par mails aux fournisseurs avec succès.');
        }
      });
  };

  mailsBonCfEnvoyesSubscription: () => void = () => {
    this.subMailsBonCfEnvoyes = this.commandesSvc.mailsBonCfEnvoyes$
      .pipe(
        switchMap(bonsCfDTO => {
          return this.gds.search(this.commandesSvc.filterStatusMails(bonsCfDTO));
        }),
        switchMap(response => {
          this.commandesSvc.updateStatusBonsCommandesFromRefresh(response.resultList, this.commandesAReceptionner, true);
          return of(response.resultList);
        }),
        switchMap((mailsEnvoyes) => this.commandesSvc.refreshStatusMailsPeriodically(mailsEnvoyes, this.commandesAReceptionner))
      )
      .subscribe(response => {
        const mails = _uniqBy(response.resultList, mail => mail.extraInfos);
        this.commandesSvc.updateStatusBonsCommandesFromRefresh(mails, this.commandesAReceptionner, false);
        this.grid.instance.refresh();
      });
  };


  createBonReception: ($event, bonCf: BonCfDTO) => void = ($event, bonCf: BonCfDTO) => {

    this.initFormBonReception(this.selectedBonReception);
    this.selectedBonCf = bonCf;

    const uniqueNumeroBonReception$ = this.uniqueCodeSvc.generateUniqueCode(TYPES_CODES.NUMERO_BON_RECEPTION);
    const filterStatutNouveau = this.receptionStatutSvc.filterStatut(RECEPTION_STATUT.NOUVEAU);
    const receptionStatutNouveau$ = this.gds.search(filterStatutNouveau);
    const all$ = combineLatest([uniqueNumeroBonReception$, receptionStatutNouveau$]);

    // on recupere un code unique calculé par le back pour le numéro du bon de réception
    this.subCreateBonReception = all$.subscribe(response => {
      if (!this.utils.isResponseSupplierError(response[0]) && !this.utils.isResponseSupplierError(response[1])) {
        const numeroBonReception = response[0].one;
        const receptionStatut = response[1].resultList[0];
        this.selectedBonReception = this.bonReceptionSvc.initDTO(numeroBonReception, this.selectedBonCf, receptionStatut);
        this.initFormBonReception(this.selectedBonReception);
        this.displayCreateBonReception = true;
      }
    });
  };


  initBonReception = (): void => {

    this.gfs.validateAllFormFields(this.formBonReception);

    if (this.formBonReception.valid) {
      this.selectedBonReception.dateLivraison = this.formBonReception.value.dateLivraison;
      this.selectedBonReception.livreur = this.formBonReception.value.livreur;
      this.selectedBonReception.immatriculation = this.formBonReception.value.immatriculation;
      this.selectedBonReception.temperatureCamion = this.formBonReception.value.temperatureCamion;
      this.selectedBonReception.numeroBonLivraison = this.formBonReception.value.numeroBonLivraison.toUpperCase();

      this.bonReceptionSvc.init(this.selectedBonReception).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.selectedBonReception = response.one;
          const summary = `Bon de réception ${this.selectedBonReception.numeroBonReception} initialisé avec succès.`;
          this.utils.showMsg(MSG_KEY.DIALOG_CREATE_BON_RECEPTION, MSG_SEVERITY.SUCCESS, summary, '', 3000);
          this.selectedBonReception.dateLivraison = this.utils.convertNumberDateToDate(this.selectedBonReception.dateLivraison);
          // mise à jour de la grille
          this.grid.instance.refresh();
          // mise à jour du formulaire avec le nouvel id
          this.initFormBonReception(this.selectedBonReception);
          // fermer le dialog courant
          this.displayCreateBonReception = false;
          // aller sur le dialog de réception des articles vue grille
          this.bonReceptionSvc.announceBonReceptionGridDxView(this.selectedBonReception);
        }
      })
    }
  };

  /**
   * Mettre à jour un BonReception d'une commande fournisseur
   * @param bonReception
   */
  updateCommandesFournisseurs = (bonReception: BonReceptionDTO): void => {
    this.grid.instance.refresh();
    if (!this.utils.isCollectionNullOrEmpty(this.commandesAReceptionner)) {
      for (let cr of this.commandesAReceptionner) {
        if (!this.utils.isCollectionNullOrEmpty(cr.bonReceptionList)) {
          const bonReceptions = cr.bonReceptionList.map(item => {
            if (item.id === bonReception.id) {
              return bonReception;
            }
            return item;
          });
          cr.bonReceptionList = bonReceptions;
        }
      }
    }
  };

  onBonCommandSaved = (): void => {
    this.grid.instance.refresh();
  }

  chooseBrlAction = (menu: Menu, $event, bonReception: BonReceptionDTO): void => {
    this.selectedBonReception = bonReception;
    // model[2] ==> action de suppression
    menu.model[1].label = `Supprimer le bon de réception ${this.selectedBonReception.numeroBonReception}`;
    menu.model[1].disabled = this.utils.isNullOrEmpty(this.selectedBonReception.receptionStatut) || this.selectedBonReception.receptionStatut.code === RECEPTION_STATUT.RECEPTION_PARTIELLE || this.selectedBonReception.receptionStatut.code === RECEPTION_STATUT.TERMINE;
    menu.show($event);
  };

  /**
   * On ne supprime un bon de reception que si ses articles réceptionnés ne sont pas déjà utilisés dans un plan de production.
   * @param bonReception
   */
  deleteBonReception = async (bonReception: BonReceptionDTO): Promise<void> => {
    const result = confirm(`Supprimer le bon de réception ${bonReception.numeroBonReception} ?`, 'Suppression du bon de réception');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.bonReceptionSvc.delete(bonReception).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          const resDelete = response.additionalProperties['DELETE_FORBIDDEN'];
          if (!this.utils.isNullOrEmpty(resDelete)) {
            this.utils.showMsg(MSG_KEY.BC_A_RECEPTIONNER, MSG_SEVERITY.ERROR, `${resDelete}`, '', 8000);
          } else {
            this.utils.showMsg(MSG_KEY.BC_A_RECEPTIONNER, MSG_SEVERITY.SUCCESS, `Bon de réception ${bonReception.numeroBonReception} supprimé.`);
            this.grid.instance.refresh();
          }
        }
      });
    }
  };

  isEditableBonReception = (isEditableByAuth2Svc: boolean, bonReceptionList: BonReceptionDTO[]): boolean => {
    const result: BonReceptionDTO = _find(bonReceptionList, (item: BonReceptionDTO) => item.receptionStatut.code === RECEPTION_STATUT.TERMINE);
    return isEditableByAuth2Svc && this.utils.isNullOrEmpty(result);
  };


  help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Gérer les commandes fournisseurs`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    return dms;
  };


  help2 = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Gérer les commandes fournisseurs`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    return dms;
  };

  getMontantReceptionHT = (bonCfDTO: BonCfDTO): number => {
    if (bonCfDTO.bonReceptionList.length === 0) {
      return 0;
    } else {
      return bonCfDTO.montantHTReception + bonCfDTO.francoDePortReception;
    }
  };

  openHistoriqueMails = (bonCf: BonCfDTO) => {
    this.bonCfSvc.announceHistoriqueMailBonCf(bonCf);
  };
}

