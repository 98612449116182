import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {startCase as _startCase} from 'lodash';
import {catchError} from 'rxjs/operators';
import {RoutemapService} from '../routemap.service';
import {UniteDeProduction__denreeInterditeDto} from '../../dtos/unite-de-production__denree-interdite-dto';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {Observable, Subject} from 'rxjs/index';

export const URL_POST_DENREE_INTERDITE_SAVE = `dolrest/gestion-unites-de-production/denree-interdite/save`;
export const URL_GET_DENREE_INTERDITE_BY_UDP = `dolrest/gestion-unites-de-production/denree-interdite/getByIdUdp`;
export const URL_GET_DELETE_DENREE_INTERDITE = `dolrest/gestion-unites-de-production/denree-interdite/deleteOne`;

@Injectable({
  providedIn: 'root'
})
export class UniteDeProduction__denreesInterditesService extends GenericHandler<UniteDeProduction__denreeInterditeDto> {


  private subjectOpenDialogAjoutDenree = new Subject<DialogStockAjoutDenreeSupplier>();
  openDialogAjoutDenree$ =this.subjectOpenDialogAjoutDenree.asObservable();

  private subjectOpenDialogModifierDenree = new Subject<DialogStockModifierDenreeSupplier>();
  openDialogModifierDenree$ =this.subjectOpenDialogModifierDenree.asObservable();

  private subjectLoadDenreesByUdp = new Subject<UniteDeProduction__denreeInterditeDto>();
  loadDenreesByUdp$ = this.subjectLoadDenreesByUdp.asObservable();

  constructor(
    private routeMapSvc: RoutemapService,
    utils: UtilsService, auth2Svc: Auth2Service, router: Router,
    http: HttpClient, title: Title) {
    super(utils, auth2Svc, router, http, title);

  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  getAllFromEnvironnement = (): void => {
  };

  createEmptyDTO = (): UniteDeProduction__denreeInterditeDto => {
    const udpDi = new UniteDeProduction__denreeInterditeDto();
    udpDi.id = 0;
    return udpDi;
  };

  getCreateNewObjectLabel = (): string => "";

  getEntityName = (): string => "UniteDeProduction__DenreeInterdite";

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => undefined;

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => undefined;

  getTitle = (): string => "GESTION DES DENRÉES INTERDITES PAR UNITÉ DE PRODUCTION";


  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LE PROCESSUS '${data.libelle.toUpperCase()}'`;

  save = (denreeInterdite: UniteDeProduction__denreeInterditeDto) => {
    return this.http.post<ResponseWrapper<boolean>>(URL_POST_DENREE_INTERDITE_SAVE, denreeInterdite).pipe(
      catchError(error => this.utils.handleError(error)));
  };

  getByIdUdp = (idUdp: number): Observable<ResponseWrapper<UniteDeProduction__denreeInterditeDto>> => {
    return this.http.get<ResponseWrapper<UniteDeProduction__denreeInterditeDto>>(URL_GET_DENREE_INTERDITE_BY_UDP, {
      params: new HttpParams().set('idUniteDeProduction', idUdp + '')
    }).pipe(
      catchError(error => this.utils.handleError(error)));
  };


  /**
   * Supprimer la denrée interdite
   * @param idDenreeInterdite
   */
  delete = (idDenreeInterdite: number): Observable<ResponseWrapper<number>> => {

    return this.http.get(URL_GET_DELETE_DENREE_INTERDITE, {
      params: new HttpParams().set('idDenreeInterdite', idDenreeInterdite + '')
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };


  announceOpenDialogAjoutDenreeInterdite = (idUniteDeProduction: number) => {
    const dialogAjoutDenreeSupplier = new DialogStockAjoutDenreeSupplier(idUniteDeProduction);
    this.subjectOpenDialogAjoutDenree.next(dialogAjoutDenreeSupplier);
  };


  announceOpenDialogModifierDenreeInterdite = (udpDiDto: UniteDeProduction__denreeInterditeDto) => {
    const dialogModifierDenreeSupplier = new DialogStockModifierDenreeSupplier(udpDiDto);
    this.subjectOpenDialogModifierDenree.next(dialogModifierDenreeSupplier);
  };

  announceLoadDenreesByUdp = () => {
    this.subjectLoadDenreesByUdp.next();
  };
}


export class DialogStockAjoutDenreeSupplier{
  idUniteDeProduction: number;

  constructor(idUniteDeProduction: number) {
    this.idUniteDeProduction = idUniteDeProduction;
  }
}

export class DialogStockModifierDenreeSupplier{
  uniteDeProductionDenreeInterditeDto: UniteDeProduction__denreeInterditeDto;

  constructor(uniteDeProductionDenreeInterditeDto: UniteDeProduction__denreeInterditeDto) {
    this.uniteDeProductionDenreeInterditeDto = uniteDeProductionDenreeInterditeDto;
  }
}
