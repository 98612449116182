import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {UtilisateurTokenSetMdpDTO} from '../../dtos/utilisateur-token-set-mdp-dto';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor(private http: HttpClient,
              private utils: UtilsService) {
  }

  resetPasswordStep1 = (email: string) => {
    const url = `dolrest/utilisateurAuth/resetPassword/${email}`;
    return this.http.get(url).pipe(
      catchError(error => this.utils.handleError(error, true))
    );

  };

  sendNewPassword = (email: string, token: string, passWord: string) => {

    const url = `dolrest/utilisateurAuth/setPasswordFromToken/${email}`;

    let utilisateurTokenSetMdpDTO: UtilisateurTokenSetMdpDTO = new UtilisateurTokenSetMdpDTO(token, passWord);

    return this.http.post(url, utilisateurTokenSetMdpDTO).pipe(catchError(error => this.utils.handleError(error)));

  };

}
