import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {ArticleSupplier} from 'app/gestion-produits/produit/article-produit/articlesresolver.service';
import {ProduitDTO} from '../../../core/dtos/produit-dto';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {DialogMsgSupplier} from '../../../core/suppliers/dialog-msg-supplier';
import {CatalogueAchatDTO} from '../../../core/dtos/catalogue-achat-dto';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {CataloguesAchatsService, PROVENANCE} from '../../../core/services/entities/catalogues-achats.service';
import {ViewModelCatalogueAchat} from './catalogueachat-viewmodel';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {UniqueCodeService} from '../../../core/services/unique-code.service';
import {FP_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {IsDeletableObject} from "../../../core/models/is-deletable-object";
import {GroupeAchatCaViewModel} from "../../../core/models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";
import {UniteDeProductionCatalogueAchatService} from "../../../core/services/entities/unite-de-production__catalogue-achat.service";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'app-article-produit',
  templateUrl: './article-produit.component.html',
  styleUrls: ['./article-produit.component.scss']
})
export class ArticleProduitComponent implements OnInit, OnDestroy {
  get canEdit(): (row: ViewModelCatalogueAchat) => boolean {
    return this._canEdit;
  }

  set canEdit(value: (row: ViewModelCatalogueAchat) => boolean) {
    this._canEdit = value;
  }

  cols: any[] = [
    {field: 'declinaison', header: 'Déclinaison'},
    {field: 'actions', header: 'Actions'},
    // {field: 'site', header: 'Site'},
    {field: 'article', header: 'Article'},
    {field: 'pDeclinaison', header: 'P. décl'},
    {field: 'pDeclinaisonId', header: 'ID P. décl'},
    {field: 'fournisseur', header: 'Filiale fournisseur / secteur fournisseur'},
    {field: 'codeApi', header: 'Code api'},
    {field: 'referenceArticle', header: 'Référence art.'},
    {field: 'referenceFournisseur', header: 'Référence art fourn.'},
    {field: 'prixAchat', header: `Prix d'achat`},
    {field: 'uniteFacturation', header: `Unité de facturation`},
    {field: 'uniteCommande', header: `Unité de commande`},
    {field: 'uniteDeStockage', header: `Unité de stockage`},
    {field: 'actif', header: 'Actif'},
  ];

  loading: boolean = false;
  totalRecords: number;

  subParent: Subscription;
  subDeletedCatalogueAchat: Subscription;
  subSavedCatalogueAchat: Subscription;

  viewModelCatalogueAchatList: ViewModelCatalogueAchat[] = [];
  catalogueAchatList: CatalogueAchatDTO[] = [];
  produit: ProduitDTO;
  uniteDeProductionList: UniteDeProductionDTO[] = [];
  uniteDeProductionSelected: UniteDeProductionDTO;

  rowGroupMetadata: any;

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits-article-produit';

  constructor(
    private route: ActivatedRoute,
    public auth2Svc: Auth2Service,
    public gfs: GenericFormService,
    public cataloguesAchatsSvc: CataloguesAchatsService,
    public gds: GenericDatagridService,
    public utils: UtilsService,
    private uniqueCodeSvc: UniqueCodeService,
    private udpCaSvc: UniteDeProductionCatalogueAchatService
  ) {
  }

  ngOnInit() {
    // Au chargement de la page
    this.routeInitSubscription();

    //Abonnement à la réponse d'enregistrement retournée par le back
    this.savedCatalogueAchatSubscription();

    //Abonnement à la réponse de suppression d'un fournisseur filiale retournée par le back
    this.deletedCatalogueAchatSubscription();
  }

  /**
   * Abonnement à la réponse d'enregistrement retournée par le back
   */
  savedCatalogueAchatSubscription = () => {
    this.subSavedCatalogueAchat = this.cataloguesAchatsSvc.savedDto$.subscribe(response => {
      const prodId = this.produit.id; const udpId = this.uniteDeProductionSelected.id;
      const current = this;
      setTimeout(function(){ current.getAndSetCatalogueAchat(); }, 100);
    });
  };

  /**
   * Abonnement à la réponse de suppression d'un fournisseur filiale retournée par le back
   */
  deletedCatalogueAchatSubscription = () => {
    this.subDeletedCatalogueAchat = this.cataloguesAchatsSvc.deleteArticle$.subscribe(response => {
      const prodId = this.produit.id; const udpId = this.uniteDeProductionSelected.id;
      const current = this;
      setTimeout(function(){ current.getAndSetCatalogueAchat(); }, 100);
    });
  };

  /**
   * Abonnement à la route parente
   */
  routeInitSubscription = () => {
    this.subParent = this.route.data
      .subscribe((data: { articlesSupplier: ArticleSupplier }) => {

        // PRODUIT
        this.produit = data.articlesSupplier.produit;
        this.utils.setTitle(`${this.produit.libelle} Articles`);

        //UNITE DE PRODUCTION
        this.uniteDeProductionList = [...data.articlesSupplier.uniteDeProductionList];
        this.uniteDeProductionSelected = this.utils.isNullOrEmpty(this.uniteDeProductionSelected) ? this.auth2Svc.preselectSingleByFirstLocalSite(this.uniteDeProductionList) : this.uniteDeProductionSelected;

        if (!this.utils.isNullOrEmpty(this.uniteDeProductionSelected)) {
          this.getAndSetCatalogueAchat();
        } else {
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.ERROR, 'Erreur de configuration d\'environnement', 'Il n\'existe aucune unité de production dans votre environnement.');
        }
      });
  };


  getAndSetCatalogueAchat = (callback?: any) => {
    this.cataloguesAchatsSvc.getCatalogueAchatByFilters({
      idsUniteProduction: [this.uniteDeProductionSelected.id],
      idsProduit: [this.produit.id],
    })
      .subscribe((response: ResponseWrapper<CatalogueAchatDTO>) => {

        this.catalogueAchatList = response.resultList;

        // ARTICLES DU CATALOGUE
        this.setViewModelCatalogueAchatList([], 'step 3');
        this.setCatalogueAchatList(this.utils.isNullOrEmpty(response.resultList) ? [] : response.resultList, 'step 3');
        this.setViewModelCatalogueAchatList(this.cataloguesAchatsSvc.mapDtosToViewModels(this.catalogueAchatList), 'step 4');

        this.totalRecords = this.viewModelCatalogueAchatList.length;
        this.rowGroupMetadata = this.utils.updateRowGroupMetaData(this.viewModelCatalogueAchatList, 'declinaison');

        if (callback) callback();
      });

  };

  openEditObject = (vmCatalogueAchat: ViewModelCatalogueAchat) => {
    let catalogueAchat: CatalogueAchatDTO;
    const route = 'infos';
    let isNew = true;

    // ARTICLE EXISTANT
    if (!this.utils.isNullOrEmpty(vmCatalogueAchat)) {
      isNew = false;
      catalogueAchat = this.utils.getFirstElement(this.catalogueAchatList.filter(item => item.id === vmCatalogueAchat.id));

      //champ necessaire pour afficher dans la boite de dialog de creation
      catalogueAchat.libelle = vmCatalogueAchat.article;
    }

    // on demande l'ouverture du dialogue
    this.cataloguesAchatsSvc.announceOpenDialogArticle({
      catalogueAchat,
      produit: this.produit,
      uniteDeProduction: this.uniteDeProductionSelected,
      route,
      rootRoute: [`${FP_ROUTES.GPR}`, 'produit', this.produit.typeProduit.fabrique, this.produit.id, 'articles', route],
      isNew,
      bodyHeight: 1000,
      routeProvenance: PROVENANCE.PRODUIT
    });

  };

  openDeleteObject = async (vmCatalogueAchat: ViewModelCatalogueAchat) => {
    const catalogueAchat: CatalogueAchatDTO = this.utils.getFirstElement(this.catalogueAchatList.filter(item => item.id === vmCatalogueAchat.id));
    const result = confirm(`Etes vous sûr de vouloir supprimer l'article ${catalogueAchat.produitArticle.libelle} du fournisseur ${catalogueAchat.fournisseur.libelle} ?`,
      'Suppression d\'article');
    const isConfirmed: boolean = await result;
    if (isConfirmed) {
      this.cataloguesAchatsSvc.deleteCatalogueAchat(catalogueAchat.id).subscribe((response: ResponseWrapper<IsDeletableObject>) => {
        this.utils.deleteMessage(response, MSG_KEY.SIDEBAR);
      });
    }
  };

  help = (): DialogMsgSupplier => undefined;


  sort = (sortField: string, sortOrder: number): string => undefined;

  parameters = (filters: any): string => undefined;

  loadLazy = (event: LazyLoadEvent) => {
  };

  refresh = () => {
  };

  save = () => {
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subParent);
    this.utils.unsubscribe(this.subDeletedCatalogueAchat);
    this.utils.unsubscribe(this.subSavedCatalogueAchat);

  }

  canModify = (viewModelCatalogueAchat: ViewModelCatalogueAchat): boolean => {
    if (this.utils.isNullOrEmpty(this.uniteDeProductionSelected)) {
      return false;
    } else {
      return this.auth2Svc.isSiteLocal(this.uniteDeProductionSelected.site.id);
    }
  };

  canCreateArticle = (): boolean => {
    if (this.utils.isNullOrEmpty(this.uniteDeProductionSelected)) {
      return false;
    } else {
      return this.auth2Svc.isSiteLocal(this.uniteDeProductionSelected.site.id);
    }
  };

  setViewModelCatalogueAchatList = (viewModelCatalogueAchatList: ViewModelCatalogueAchat[], step: string): void => {
    this.viewModelCatalogueAchatList = [...viewModelCatalogueAchatList];
  };

  setCatalogueAchatList = (catalogueAchatList: CatalogueAchatDTO[], step: string): void => {
    this.catalogueAchatList = [...catalogueAchatList];
  };


  onChangeSelectedUniteDeProduction = ($event: any) => {
    this.getAndSetCatalogueAchat();
  };

  updatePrefere = (groupeAchatCa: GroupeAchatCaViewModel, viewModelCatalogueAchatList: ViewModelCatalogueAchat[], rowData: ViewModelCatalogueAchat, prefere: boolean) => {

    if (this.canModify(rowData) && prefere && !this.utils.isCollectionNullOrEmpty(this.viewModelCatalogueAchatList)) {
     // récuperation des catalogue acaht rattaché au meme produit déclinaison
      const codeProduitDeclinaison = rowData.produitDeclinaisonCode;
      let elts = viewModelCatalogueAchatList.filter(item => item.produitDeclinaisonCode === codeProduitDeclinaison);
      if (elts) {
        this.utils.showMsg(MSG_KEY.SIDEBAR_CENTER, MSG_SEVERITY.WARN, `Mise à jour de l'article prefere en cours...`);
        elts = elts.map((item: ViewModelCatalogueAchat) => {

          item.groupeAchatCaList.forEach( (gca: GroupeAchatCaViewModel)=>{
            gca.prefere = false;
            if (gca.id === groupeAchatCa.id) {
              gca.prefere = true;
            }
          });
          return item;
        });
        this.udpCaSvc.updatePrefere(groupeAchatCa.id,this.uniteDeProductionSelected.id).subscribe( data=>{
          this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `L'article préféré pour la denrée ${rowData.produitDeclinaisonCode} ${rowData.produitDeclinaisonLibelle} a été modifié avec succès.`, '', 8000);
        });
      }
    }
  };

  private _canEdit = (row : ViewModelCatalogueAchat) => {
    if(this.utils.isNullOrEmpty(row.fournisseurOwnerLibelle)){
      return row.articleSiteId === this.uniteDeProductionSelected.site.id;
    }else{
      return false;
    }
  };

  isDisabledDeleteBtn = (row : ViewModelCatalogueAchat) => {
    if(this.utils.isNullOrEmpty(row.fournisseurOwnerLibelle)){
      return row.articleSiteId !== this.uniteDeProductionSelected.site.id;
    }else{
      return true;
    }
  };
}



