import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ProduitsService} from '../../core/services/entities/produits.service';
import {ProduitAllergeneDTO} from '../../core/dtos/produit-allergene-dto';
import {ProduitAppellationDTO} from '../../core/dtos/produit-appellation-dto';
import {MenusCompositionService} from '../../core/services/gestionmenus/menus-composition.service';
import {FamilleNutritionnelleProduitDTO} from '../../core/dtos/famillenutritionelle-produit-dto';
import {FicheTechniqueLigneDTO} from '../../core/dtos/fiche-technique-ligne-dto';
import {ProduitApportNutritionnelDTO} from '../../core/dtos/produit-apportnutritionnel-dto';
import {ApportNutritionnelDTO} from '../../core/dtos/apport-nutritionnel-dto';
import {ProduitDeclinaisonDTO} from '../../core/dtos/produit-declinaison-dto';
import {ProduitDeclinaisonService} from '../../core/services/entities/produit-declinaison.service';
import {combineLatest} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CODE_INFO_INCOMPLETE} from '../../core/services/entities/allergenes-service.service';
import {ApportsNutritionnelsService} from '../../core/services/entities/apports-nutritionnels.service';
import {MenusToolbarService} from "../../core/services/gestionmenus/menus-toolbar.service";
import {InfosMenuCompoSupplier} from "../../core/suppliers/gestionmenus/info-menu-compo-supplier";
import {Subscription} from "rxjs/index";

@Component({
  selector: 'yo-infos-menucompo',
  templateUrl: './infos-menucompo.component.html',
  styleUrls: ['./infos-menucompo.component.scss']
})
export class InfosMenucompoComponent implements OnInit, OnDestroy {

  displayDialog: boolean = false;

  subDialogInfoMenu: Subscription;
  subData: Subscription;

  infosMenuCompoSupplier: InfosMenuCompoSupplier;

  produitApportsNutris: ProduitApportNutritionnelDTO[];
  produitAllergenes: ProduitAllergeneDTO[];
  produitAppellations: ProduitAppellationDTO[];
  famillesGemrcn: FamilleNutritionnelleProduitDTO[];
  ingredients: FicheTechniqueLigneDTO[];
  isAllergeneInfoIncomplete: boolean;

  // Apports Nutris
  apportsNutritionnels: ApportNutritionnelDTO[];
  kcalPortion: number;

  // approts nutris graphe
  apportsNutriGraphData: number[] = [];
  apportsNutrisGraph: any;
  apportsNutriGraphLabels: string[] = [];
  apportsNutrisGraphOptions: any = {};

  activeIndex = 0;

  cols: any[] = [
    {field: 'libelle', header: ''},
    {field: 'teneur100g', header: 'POUR 100g'},
    {field: 'teneurPortion', header: 'PORTION'}
  ];

  produitDeclinaison: ProduitDeclinaisonDTO;
  widthDialog = '60%';

  refreshImage: number = new Date().getTime();

  constructor(public utils: UtilsService,
              public menuCompoSvc: MenusCompositionService,
              public produitsSvc: ProduitsService,
              private cd: ChangeDetectorRef,
              private menuToolBarSvc: MenusToolbarService,
              public domSanitizer: DomSanitizer,
              private produitDeclinaisonService: ProduitDeclinaisonService,
              private apportNutriService: ApportsNutritionnelsService) {
  }


  ngOnInit() {
    this.initData();
    this.produitsSvc.savedDto$.subscribe(() => this.refreshImage = new Date().getTime());
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subData);
    this.utils.unsubscribe(this.subDialogInfoMenu);
    this.activeIndex = 0;
  }

  initData = () => {
    if (window.innerWidth <= 1650) {
      this.widthDialog = '75%';
    }
    this.subDialogInfoMenu = this.menuToolBarSvc.dialogInfoMenu$.subscribe((data: InfosMenuCompoSupplier) => {

      this.infosMenuCompoSupplier = data;
      this.ingredients = [];

      const ingredients$ = this.menuCompoSvc.getIngredients(this.infosMenuCompoSupplier.menuCompo.id);
      const apportsNutritionnelsObligatoires$ = this.apportNutriService.getApportNutritionnelsObligatoiresActifsBySiteNull();

      const produitDeclinaison$ = this.produitDeclinaisonService.getProduitDeclinaison(this.infosMenuCompoSupplier.menuCompo.produitDeclinaisonId);
      const all$ = combineLatest([ingredients$, apportsNutritionnelsObligatoires$, produitDeclinaison$]);

      this.activeIndex = 0;
      this.displayDialog = true;

      all$.pipe(
        catchError(err => this.utils.handleError(err))
      )
        .subscribe(response => {
          this.ingredients = response[0].resultList;
          this.apportsNutritionnels = response[1];
          this.produitDeclinaison = response[2];

          if (!this.utils.isNullOrEmpty(this.infosMenuCompoSupplier) && !this.utils.isNullOrEmpty(this.infosMenuCompoSupplier.menuCompo)) {

            // allergenes
            if (!this.utils.isCollectionNullOrEmpty(this.infosMenuCompoSupplier.menuCompo.produitAllergeneDTOList)) {
              this.produitAllergenes = this.infosMenuCompoSupplier.menuCompo.produitAllergeneDTOList.filter(item => item.actif);
              if (!this.utils.isCollectionNullOrEmpty(this.produitAllergenes)) {
                this.isAllergeneInfoIncomplete = !!this.produitAllergenes.filter(item => item.allergeneCode === CODE_INFO_INCOMPLETE);
              }
            }

            // appellations
            if (!this.utils.isCollectionNullOrEmpty(this.infosMenuCompoSupplier.menuCompo.produitAppellationDTOList)) {
              this.produitAppellations = this.infosMenuCompoSupplier.menuCompo.produitAppellationDTOList.filter(item => item.actif);
            }

            // familles gemrcn
            if (!this.utils.isCollectionNullOrEmpty(this.infosMenuCompoSupplier.menuCompo.familleNutritionnelleProduitDTOList)) {
              this.famillesGemrcn = this.infosMenuCompoSupplier.menuCompo.familleNutritionnelleProduitDTOList;
            }

            // apports nutris
            if (!this.utils.isCollectionNullOrEmpty(this.infosMenuCompoSupplier.menuCompo.produitApportNutriDTOList)) {

              this.produitApportsNutris = this.infosMenuCompoSupplier.menuCompo.produitApportNutriDTOList;

              if (this.produitApportsNutris) {
                this.kcalPortion = this.getKcalPortion(this.produitApportsNutris, this.produitDeclinaison);
                this.produitApportsNutris.forEach(item => item.apportNutritionnel.libelle = item.apportNutritionnel.libelle.replace('(g/100g)', '').trim());
              }
            }

            if (!this.utils.isCollectionNullOrEmpty(this.apportsNutritionnels)) {
              this.apportsNutritionnels = this.apportsNutritionnels.filter(item => !item.libelle.toLowerCase().startsWith('energie'));
              if (this.apportsNutritionnels) {
                this.apportsNutriGraphLabels = [];
                this.apportsNutritionnels.forEach(item => {
                  item.libelle = item.libelle.replace('(g/100g)', '').trim();
                  this.apportsNutriGraphLabels.push(item.libelle);
                });
              }
            }
            this.apportsNutriGraphData = this.getApportsNutrisGraphData(this.produitApportsNutris, this.apportsNutritionnels);
          }
          this.cd.markForCheck();
        });
    });
  };

  customizeTooltip = (args: any) => ({
    text: `${args.argumentText} : ${args.originalValue} `,
  });

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 1650) {
      this.widthDialog = '75%';
    } else {
      this.widthDialog = '60%';
    }
  };

  getKcalPortionLabel = () => {
    if (this.kcalPortion > 0) {
      return this.kcalPortion + ' kcal / portion';
    }
  };

  getApportsNutrisGraphData(produitApportsNutris: ProduitApportNutritionnelDTO[], apportsNutris: ApportNutritionnelDTO[]): any[] {

    let data = [];

    if (!this.utils.isCollectionNullOrEmpty(produitApportsNutris) && !this.utils.isCollectionNullOrEmpty(apportsNutris)) {

      // repartition pour 100g
      for (let pan of produitApportsNutris) {
        for (let an of apportsNutris) {
          if (pan.apportNutritionnel.libelle === an.libelle) {
            const teneur = pan.teneur ? pan.teneur : 0;
            data.push({ label: an.libelle, value: parseFloat(teneur.toFixed(2))});
          }
        }
      }
    }

    return data;
  }

  openDetailsProduit = (fabrique: boolean, idProduit: number) => {
    const idSiteContratMenu: number = this.infosMenuCompoSupplier?.cellule?.contratMenuSiteId;
    this.menuCompoSvc.announceRoutingSidebar(fabrique, idProduit, idSiteContratMenu);
    this.closeDialog();
  };


  labelAllergenes = () => {
    let label = '';

    if (!this.utils.isCollectionNullOrEmpty(this.produitAllergenes)) {
      const paArr = this.produitAllergenes.filter(all => all.allergeneCode != CODE_INFO_INCOMPLETE);
      if (!this.utils.isCollectionNullOrEmpty(paArr)) {
        label = paArr.map(all => all.allergeneLibelle).join(', ') + '.';
      } else {
        label = 'Aucun.';
      }

    }
    return label;
  };

  labelAppellations = () => {
    let label = '';

    if (!this.utils.isCollectionNullOrEmpty(this.produitAppellations)) {
      label = this.produitAppellations.map(app => app.appellationLibelle).join(', ') + '.';
    }
    return label;
  };

  getUnite = (apportNutritionnelDTO: ApportNutritionnelDTO): string => {
    let libelleConst: string = apportNutritionnelDTO.libelle;
    let indexSlash: number = libelleConst.lastIndexOf("/");
    let indexParenthese: number = libelleConst.lastIndexOf("(");
    let unite = libelleConst.substring(indexParenthese + 1, indexSlash);
    return unite;
  };

  getTeneur = (produitApportsNutris: ProduitApportNutritionnelDTO[], apportNutri: ApportNutritionnelDTO): number => {

    let teneur = 0;

    for (let pan of produitApportsNutris) {
      if (pan.apportNutritionnel.libelle === apportNutri.libelle) {
        teneur = pan.teneur;
        break;
      }
    }
    return teneur;
  };


  getQuantite = (produitDecli: ProduitDeclinaisonDTO, apportNutri: ApportNutritionnelDTO, produitApportsNutris: ProduitApportNutritionnelDTO[]): string => {

    let teneur = this.getTeneur(produitApportsNutris, apportNutri);

    if (this.utils.isNullOrEmpty(teneur)) {
      return null;
    } else {

      let quantite = 0;

      // La quantité est le produit du ratio technique(valeur pour 1 kg) multiplié par la teneur (pour 100 g) multiplié par 10 (parce que 1kg = 10 fois 100 g.
      quantite = produitDecli.ratioUniteTechnique * teneur * 10;

      return quantite.toFixed(2);
    }
  };

  getQuantitePortion = (produitDecli: ProduitDeclinaisonDTO) => {
    if (!this.utils.isNullOrEmpty(produitDecli)) {
      return this.produitDeclinaison.ratioUniteTechnique * 1000;
    }

    return 0;
  };

  private getKcalPortion = (produitApportsNutris: ProduitApportNutritionnelDTO[], produitDeclinaison: ProduitDeclinaisonDTO) => {
    let kcalPortion = 0;
    if (!this.utils.isCollectionNullOrEmpty(produitApportsNutris) && !this.utils.isNullOrEmpty(produitDeclinaison)) {

      const collection = produitApportsNutris.filter(item => item.apportNutritionnel.libelle.toLowerCase().includes('kcal'));
      if (!this.utils.isCollectionNullOrEmpty(collection)) {
        const quantitePortion = this.getQuantitePortion(produitDeclinaison);
        const ratio = quantitePortion / 100;
        kcalPortion = parseInt((collection[0].teneur * ratio).toFixed(0));
      }
    }
    return kcalPortion;
  };

  closeDialog = () => {
    this.activeIndex = 0;
    this.displayDialog = false;
  };

  activeIndexChange = event => {
    // index 4 = voir la fiche technique, on declenche la navigation
    if (event === 4) {
      const idSiteContratMenu: number = this.infosMenuCompoSupplier?.cellule?.contratMenuSiteId;
      this.menuCompoSvc.announceRoutingSidebar(this.infosMenuCompoSupplier.menuCompo.fabrique, this.infosMenuCompoSupplier.menuCompo.produitId, idSiteContratMenu);
      this.closeDialog();
    }
  };
}

