import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ProduitDTO} from "../../../../../core/dtos/produit-dto";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import CustomStore from "devextreme/data/custom_store";
import {Subscription} from "rxjs";
import {UdpMcpProduitsService} from "../../../../../core/services/conditionnements/plat/udp-mcp-produits.service";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {UnitedemesureDTO} from "../../../../../core/dtos/unitedemesure-dto";
import {DeclinaisonDTO} from "../../../../../core/dtos/declinaison-dto";
import ModeleConditionnementPlatDTO from "../../../../../core/dtos/conditionnement/modele-conditionnement-plat-dto";
import {ProduitsService} from "../../../../../core/services/entities/produits.service";
import {SearchSupplierWrapper} from "../../../../../core/suppliers/wrappers/search-supplier-wrapper";
import {SearchSupplier} from "../../../../../core/suppliers/search-supplier";
import {DevextremeService, FilterItem} from "../../../../../core/services/technique/devextreme.service";
import {FamillesProduitService} from "../../../../../core/services/entities/familles-produit.service";
import {FamilleProduitDTO} from "../../../../../core/dtos/famille-produit-dto";
import {SiteDTO} from "../../../../../core/dtos/site-dto";
import {DeclinaisonsService} from "../../../../../core/services/entities/declinaisons.service";
import {TacheDTO} from "../../../../../core/dtos/tache-dto";
import {TachesService} from "../../../../../core/services/taches/taches.service";
import {RegimeAlimentaireService} from "../../../../../core/services/entities/regime-alimentaire.service";
import {RegimeAlimentaireDTO} from "../../../../../core/dtos/regime-alimentaire-dto";
import {DxDataGridComponent, DxNumberBoxComponent, DxTagBoxComponent, DxTextBoxComponent,} from "devextreme-angular";
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {ImplementationModelesConditionnementsPlatsService} from "../../../../../core/services/conditionnements/plat/implementation-modeles-conditionnements-plats.service";
import {BindPlatsMcpModel} from "../../../../../core/models/gestion-conditionnements/bind-plats-mcp-model";
import {Udp_ModeleConditionnementPlat__ProduitDTO} from "../../../../../core/dtos/unite-de-production_modele-conditionnement-plat__produit.dto";

@Component({
  selector: 'yo-dialog-ajout-mcp-plats',
  templateUrl: './dialog-ajout-mcp-plats.component.html',
  styleUrls: ['./dialog-ajout-mcp-plats.component.scss']
})
export class DialogAjoutMcpPlatsComponent implements OnInit, OnDestroy {

  platsList: ProduitDTO[] = [];
  allFamillesProduits: FamilleProduitDTO[] = [];
  uniteAConditionner: UnitedemesureDTO;
  declinaisons: DeclinaisonDTO[] = [];
  sitesUtilisateurConnecte: SiteDTO[] = [];
  allDeclinaisons: DeclinaisonDTO[] = [];
  allTaches: TacheDTO[] = [];
  allRegimes: RegimeAlimentaireDTO[] = [];
  allUdpMcpProduits: Udp_ModeleConditionnementPlat__ProduitDTO[] = [];
  allCodes: string[] = [];

  declinaisonsPoidsFTList: any[] = [];
  declinaisonsNbUCList: any[] = [];

  dataSource: CustomStore;

  subOpenDialog: Subscription;
  subFamillesProduits: Subscription;
  subDeclinaisons: Subscription;
  subTaches: Subscription;
  subRegimes: Subscription;

  openDialog = false;
  hasIDistri = false;
  displayFormNonNourri = false;
  dialogTitle = 'Liaison du modèle avec des plats';

  model: ModeleConditionnementPlatDTO;

  allMode: string;
  checkBoxesMode: string;

  @ViewChild("gridPlatsToAdd") grid: DxDataGridComponent;
  declinaisonIdList: number[] = [];
  tachesIdList: number[] = [];
  regimeIdList: number[] = [];
  familleProduitIdList: number[] = [];
  siteIdList: number[] = [];
  code: string;
  platLibelle: string;

  isActionDisabled: boolean = true;

  @ViewChild("dxDeclinaisons", {static: false }) dxDeclinaisons : DxTagBoxComponent;
  @ViewChild("dxTypeFab", {static: false }) dxTypeFab : DxTagBoxComponent;
  @ViewChild("dxRegime", {static: false }) dxRegime: DxTagBoxComponent;
  @ViewChild("dxFamilleProduit", {static: false }) dxFamilleProduit: DxTagBoxComponent;
  @ViewChild("dxSites", {static: false }) dxSites: DxNumberBoxComponent;
  @ViewChild("dxCode", {static: false }) dxCode: DxTextBoxComponent;
  @ViewChild("dxLabel", {static: false }) dxLabel: DxTextBoxComponent;

  constructor(public utils: UtilsService,
              private udpMcpProduitsSvc: UdpMcpProduitsService,
              private auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private gds: GenericDatagridService,
              private produitSvc: ProduitsService,
              private dxSvc: DevextremeService,
              private familleProduitSvc: FamillesProduitService,
              private declinaisonEntitySvc: DeclinaisonsService,
              private tachesSvc: TachesService,
              private regimeEntitySvc: RegimeAlimentaireService,
              private implMcpSvc: ImplementationModelesConditionnementsPlatsService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFamillesProduits);
    this.utils.unsubscribe(this.subDeclinaisons);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subTaches);
    this.utils.unsubscribe(this.subRegimes)
  }

  ngOnInit(): void {
    this.initOpenDialogSubscription();
    this.initHasGestionIDistri();
    this.loadFilterFamillesProduitsSubscription();
    this.loadFilterDeclinaisonsSubscription();
    this.loadFilterRegimesSubscription();
    this.loadFilterSites();
    this.loadFilterTachesSubscription();
    this.dataSource = this.initCustomStore();
  }

  initOpenDialogSubscription = (): void => {
    this.subOpenDialog = this.udpMcpProduitsSvc.bindWithPlatsAsked$.subscribe(model => {
      this.openDialog = true;
      this.displayFormNonNourri = model.mode === 'NE_NOURRI_PAS_UN_CONVIVE';
      this.model = model;
      this.udpMcpProduitsSvc.findAllByMcpId(this.model.id)
        .subscribe(response => {
           this.allUdpMcpProduits = response.resultList;
           this.grid.instance.refresh();
        });
    });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  closeDialog = (): void => {
    this.dxDeclinaisons.instance.reset();
    this.dxTypeFab.instance.reset();
    this.dxRegime.instance.reset();
    this.dxFamilleProduit.instance.reset();
    this.dxSites.instance.reset();
    this.dxCode.instance.reset();
    this.dxLabel.instance.reset();
    this.grid.instance.deselectAll();
    this.grid.instance.state(null);
    this.openDialog = false;
  };

  loadFilterFamillesProduitsSubscription = (): void => {
    this.subFamillesProduits = this.gds.getAll(this.familleProduitSvc.getEntityName(), this.familleProduitSvc.getSort(), true).subscribe(response => {
      this.allFamillesProduits = response.resultList;
      this.allFamillesProduits = this.familleProduitSvc.removeChildren(this.allFamillesProduits);
    });
  };

  loadFilterDeclinaisonsSubscription = (): void => {
    this.subDeclinaisons = this.gds.getAll(this.declinaisonEntitySvc.getEntityName(), this.declinaisonEntitySvc.getSort(), true).subscribe(response => {
      this.allDeclinaisons = response.resultList;
    });
  };

  loadFilterRegimesSubscription = (): void => {
    this.subRegimes = this.gds.getAll(this.regimeEntitySvc.getEntityName(), this.regimeEntitySvc.getSort(), true).subscribe(response => {
      this.allRegimes = response.resultList;
    });
  };


  loadFilterTachesSubscription = (): void => {
    this.subTaches = this.tachesSvc.getAll()
      .subscribe(response => {
      this.allTaches = response.resultList;
    });
  };

  loadFilterSites = (): void => {
    this.sitesUtilisateurConnecte = this.auth2Svc.utilisateur.sites;
  };

  onChangeDeclinaisonFilter = ($event): void => {
    this.declinaisonIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeTypeFabricationFilter = ($event): void => {
    this.tachesIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeRegimeFilter = ($event): void => {
    this.regimeIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeFamilleProduitFilter = ($event): void => {
    this.familleProduitIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeSiteFilter = ($event): void => {
    this.siteIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeCodeFilter = ($event): void => {
    this.code = $event.value;
    this.grid.instance.refresh();
  };

  onChangePlatLibelleFilter = ($event): void => {
    this.platLibelle = $event.value;
    this.grid.instance.refresh();
  };

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  initCustomStore = (): CustomStore => new CustomStore({
    key: 'id',
    load: async (loadOptions: any) => {
      const sitesIds = this.siteIdList.length ? this.siteIdList : this.auth2Svc.utilisateur.sites.map(item => item.id);

      let pageSize: number = loadOptions.take || this.grid.instance.pageSize();
      let page: number = this.grid.instance.pageIndex();
      const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

      const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();
      ssw.filtersMap['code'] = new SearchSupplier(this.code, undefined);
      ssw.filtersMap['fullTextProduit'] = new SearchSupplier(this.platLibelle, undefined);
      ssw.filtersMap['declinaisonIds'] = new SearchSupplier(undefined, this.declinaisonIdList);
      ssw.filtersMap['familleProduitIds'] = new SearchSupplier(undefined, this.familleProduitIdList);
      ssw.filtersMap['regimeIds'] = new SearchSupplier(undefined, this.regimeIdList);
      ssw.filtersMap['sites'] = new SearchSupplier(undefined, sitesIds);
      ssw.filtersMap['isFabrique'] = new SearchSupplier(true);
      ssw.filtersMap['tachesIdList'] = new SearchSupplier(undefined, this.tachesIdList);

      if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id') {
        // Si je coche tout => Il faut omettre la pagination
        page = null;
        pageSize = null;
      }

      const endUrl = page !== null && pageSize !== null ? `page=${page}&size=${pageSize}` : '';
      return this.produitSvc.search(endUrl, ssw)
        .toPromise()
        .then(response => {
          let data = response.resultList;
          let totalCount = response.totalElements;
          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;
          if (this.allUdpMcpProduits) {
            data = data.filter(p => !this.allUdpMcpProduits.filter(udpMcpP => udpMcpP.produit.id === p.id).length);
            totalCount -= this.allUdpMcpProduits.length;
          }
          return {data, totalCount};
        });
    }
  });

  canSave = (): boolean => this.hasIDistri;

  save = async (): Promise<void> => {
    let rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    if (!rowsSelected.length) {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez sélectionnez au minimum un plat à ajouter');
    } else {
      this.implMcpSvc.saveMultiple({mcpId: this.model.id, platsIdList: rowsSelected} as BindPlatsMcpModel)
        .subscribe(response => {
          this.implMcpSvc.announceBindPlatsDone();
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Plat(s) associé(s) avec succès');
          this.closeDialog();
        });
    }
  };

}
