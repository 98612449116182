import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {GroupeAchat__UdpDTO} from "../../../../core/dtos/unite-de-production/groupe-achat-udp-dto";
import {UtilsService} from "../../../../core/utils/utils.service";
import {GroupeAchatSidebarSupplier} from "../groupe-achat-sidebar-resolver.service";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {GroupeAchatService, TAB_NAME_GR} from "../../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {GroupeAchatDTO} from "../../../../core/dtos/unite-de-production/groupe-achat-dto";
import {UniteDeProductionDTO} from "../../../../core/dtos/unite-de-production-dto";
import {remove as _remove} from 'lodash'
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../../core/models/is-deletable-object";
import {DxDataGridComponent} from "devextreme-angular";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-membre-groupe-achat',
  templateUrl: './membre-groupe-achat.component.html',
  styleUrls: ['./membre-groupe-achat.component.scss']
})
export class MembreGroupeAchatComponent implements OnInit, OnDestroy {


  groupeAchat: GroupeAchatDTO;
  membreGroupeAchatList: GroupeAchat__UdpDTO[] = [];

  subscriptionRoute: Subscription;
  subUniteDeProcutionSelected: Subscription;

  selectedMembers: number[] = [];

  @ViewChild('grid') grid: DxDataGridComponent;

  pathFile: string = HELP_FOLDERS.FOURNISSEURS_GROUPES_ACHATS + '/fournisseur-membre-groupe-achat';

  constructor(public utils: UtilsService,
              private groupeAchatSvc: GroupeAchatService,
              private auth2Svc: Auth2Service,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.initData();
    this.subcriptionUniteDeProcutionSelected();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subUniteDeProcutionSelected);
  }

  initData() {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { groupeAchatSidebarSupplier: GroupeAchatSidebarSupplier }) => {
        this.membreGroupeAchatList = data.groupeAchatSidebarSupplier.membreGroupeAchatList;
        this.groupeAchat = data.groupeAchatSidebarSupplier.groupeAchat;
        this.groupeAchatSvc.announceActiveMenuItem(TAB_NAME_GR.membres);
      });
  }

  loadMemberGroupeAchat() {
    this.groupeAchatSvc.getMembreGroupeAchat(this.groupeAchat.id)
      .subscribe((data: ResponseWrapper<GroupeAchat__UdpDTO>) => {
        this.membreGroupeAchatList = data.resultList;
      });
  }

  subcriptionUniteDeProcutionSelected() {
    this.subUniteDeProcutionSelected = this.groupeAchatSvc.uniteDeProductionSelected$.subscribe(updList => {

      const updListToSaved = this.deleteDoublon(updList);
      const idUdpList = this.getIdlist(updListToSaved);
      if (idUdpList.length > 0) {
        this.saveGroupeAchatUdp(idUdpList);
      }
    });
  }

  saveGroupeAchatUdp(updListToSaved: number[]) {
    this.groupeAchatSvc.saveGroupeAchatUdpList(updListToSaved, this.groupeAchat.id)
      .subscribe(data => {
        data.resultList.forEach(item => {
          this.membreGroupeAchatList.unshift(item);
        });
        this.selectedMembers = [];
        this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Sauvegarde réussie.`);
      });
  }

  getIdlist(updListToSaved: UniteDeProductionDTO[]) {
    let idUdpList = [];

    updListToSaved.forEach(item => {
      idUdpList.push(item.id);
    });

    return idUdpList;
  }

  deleteDoublon(updList: UniteDeProductionDTO[]) {
    updList.forEach((newItem: UniteDeProductionDTO) => {
      this.membreGroupeAchatList.forEach((membre: GroupeAchat__UdpDTO) => {
        if (newItem.id === membre.uniteDeProduction.id) {
          _remove(updList, newItem);
        }
      });
    });
    return updList;
  }


  openAddUniteDeProduction() {
    this.groupeAchatSvc.announceOpenDialogAddMember();
  }


  canAdd() {
    return this.auth2Svc.isSiteLocal(this.groupeAchat.site.id);
  }

  canModify() {
    return this.auth2Svc.isSiteLocal(this.groupeAchat.site.id);
  }

  help() {
    return undefined;
  }

  applyMajPrix: (groupeAchat: GroupeAchatDTO, selectedMembers: number[]) => Promise<void> = async (groupeAchat: GroupeAchatDTO, selectedMembers: number[]) => {
    if (this.grid.selectedRowKeys) {
      const rows: GroupeAchat__UdpDTO[] = this.grid.instance.getSelectedRowsData();
      let idUdpList = [];
      if (rows && rows.length) {
        idUdpList = [ ...new Set(rows
          .filter(item => item.uniteDeProduction)
          .map(item => item.uniteDeProduction.id) ) ];
      }
      let res = await confirm("Souhaitez-vous lancer le calcul des prix des articles sur le(s) membre(s) sélectionné(s) ?", "Calcul du prix des articles");
      if (res && selectedMembers && selectedMembers.length) {
        this.groupeAchatSvc.applyGroupeAchatPrixArticles(groupeAchat.id, idUdpList)
          .subscribe(response => {
            this.loadMemberGroupeAchat();
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le calcul des prix des articles est terminé`)
          });
      }
    }
  };

  removeMembers: (selectedMembers: number[]) => Promise<void> = async (selectedMembers: number[]) => {
    let res = await confirm("Souhaitez-vous supprimer le(s) membre(s) sélectionné(s) ?", "Suppression de(s) membre(s)");
    if (res && selectedMembers && selectedMembers.length) {
      this.groupeAchatSvc.deleteMemberGroupeAchat(selectedMembers)
        .subscribe((response: ResponseWrapper<IsDeletableObject>) => {
          this.utils.deleteMessage(response, MSG_KEY.SIDEBAR);
          this.loadMemberGroupeAchat();
        });
    }
  };
}
