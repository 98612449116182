import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericManageDialogService} from "../../../../core/services/generics/generic-manage-dialog.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import ZoneANettoyerPmsDto from "../../../../core/dtos/pms/zone-a-nettoyer-pms-dto";
import {
  ZoneANettoyerPmsSavedSupplier,
  ZoneANettoyerPmsService
} from "../../../../core/services/pms/zone-a-nettoyer-pms.service";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {Title} from "@angular/platform-browser";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-pms-zone-a-nettoyer-grille',
  templateUrl: './pms-zone-a-nettoyer-grille.component.html',
  styleUrls: ['./pms-zone-a-nettoyer-grille.component.scss']
})
export class PmsZoneANettoyerGrilleComponent implements OnInit, OnDestroy {
  zonesList: ZoneANettoyerPmsDto[] = [];

  hasPms = false;

  subZoneSaved: Subscription;

  entityName: string;
  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.PMS + '/pms-zone-a-nettoyer';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              public gds: GenericDatagridService,
              private i8nSvc: InternationalizationService,
              private zonePmsSvc: ZoneANettoyerPmsService,
              private title: Title) {}

  ngOnInit(): void {
    this.initProperty();
    this.initHasPms();
    this.initZonesPms();
    this.zoneSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subZoneSaved);
  }

  getTitle = (): string => "GESTION DES ZONES A NETTOYER";

  initProperty = () => {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
    this.entityName = this.zonePmsSvc.getEntityName().toLowerCase();
    this.title.setTitle(this.getTitle());
  };

  canEdit = (): boolean => this.hasPms && this.auth2Svc.hasSitesLocaux();

  initZonesPms = (): void => {
    this.zonePmsSvc.getAll()
      .subscribe(response => {
        this.zonesList = response.resultList;
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  zoneSavedSubscription = (): void => {
    this.subZoneSaved = this.zonePmsSvc.zoneSaved$
      .subscribe((response: ZoneANettoyerPmsSavedSupplier) => {
        if (!response.isUpdate) {
          this.zonesList.push(response.zone);
        } else {
          this.zonesList = this.zonesList.map(zone => zone.id != response.zone.id ? zone : response.zone);
        }
      });
  };

  openZone = (z: ZoneANettoyerPmsDto): void => {
    this.zonePmsSvc.announceOpenDialog(z);
  };

  deleteValues = async (): Promise<void> => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.zonesList.find(z => z.id === idSelected))
        .map((zone: ZoneANettoyerPmsDto) => zone.id);
      this.zonePmsSvc.deleteByIds(idsToDelete)
        .subscribe(o => {
          this.zonesList = this.utils.handleIsDeletableResultWithNum(o.one, idsToDelete, this.zonesList);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La suppression a bien été prise en compte`);
        });
    }
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les zones à nettoyer (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
