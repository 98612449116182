import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {HttpClient} from '@angular/common/http';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {Subscription} from 'rxjs';
import {GestionGemrcnService} from '../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {ModeleNutritionnelDTO} from '../../core/dtos/modele-nutritionnel-dto';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {FS_ROUTES, HELP_FOLDERS, MESSAGE_CREATION_OBJET_IMPOSSIBLE, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {ModelesNutritionnelsService} from '../../core/services/entities/modeles-nutritionnels.service';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {cloneDeep as _cloneDeep} from 'lodash'
import {SiteDTO} from '../../core/dtos/site-dto';
import {OverlayPanel} from 'primeng/overlaypanel';
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-grille-modeles-nutris',
  templateUrl: './grille-modeles-nutris.component.html',
  styleUrls: ['./grille-modeles-nutris.component.scss']
})
export class GrilleModelesNutrisComponent implements OnInit, OnDestroy {

  @ViewChild('opDuplication') opDuplication: OverlayPanel;

  cols: any[] = [
    {field: 'selection', header: 'Sélection'},
    {field: 'actions', header: 'Actions'},
    {field: 'site', header: 'Site'},
    {field: 'libelle', header: 'Modèle de menu'},
    {field: 'actif', header: 'Actif'}
  ];

  planAlimentaireToDuplicate: ModeleNutritionnelDTO;
  duplicationSite: SiteDTO;

  plansAlimentaires: ModeleNutritionnelDTO[];
  selectedPlansAlimentaires: ModeleNutritionnelDTO[];
  totalRecords = 0;

  private subPlanAlimentaire: Subscription;
  private subSavedPlanAlimentaire: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.GEMRCN + '/gemrcn-modeles-nutris';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private ggSvc: GestionGemrcnService,
              public gds: GenericDatagridService,
              private gfs: GenericFormService,
              private routeMap: RoutemapService,
              public http: HttpClient,
              private modeleNutritionnelSvc: ModelesNutritionnelsService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.subPlanAlimentaire = this.ggSvc.modelesNutris$.subscribe(modelesNutris => {

      this.plansAlimentaires = modelesNutris;
      this.totalRecords = modelesNutris ? modelesNutris.length : 0;
    });

    //Abonnement à la réponse d'enregistrement retournée par le back
    this.subSavedPlanAlimentaire = this.modeleNutritionnelSvc.savedDto$.subscribe(response => {
      this.plansAlimentaires = this.gds.refreshDatagridPrimengAfterSave(this.plansAlimentaires, response, this.modeleNutritionnelSvc.getEntityName()) as ModeleNutritionnelDTO[];
      this.selectedRows = [];
      this.selectedRows.push(response.one.id);
    });
  }

  openModeleNutri = (modeleNutritionnel: ModeleNutritionnelDTO) => {

    if (this.utils.isNullOrEmpty(modeleNutritionnel)) {
      modeleNutritionnel = this.modeleNutritionnelSvc.createEmptyDTO();
    }

    this.routeMap.goToSecondaryRoute([FS_ROUTES.GGE_MODELENUTRI, modeleNutritionnel.id, 'fiche-identite']);


  };

  openModeleNutriDeleteDialog = (dto: ModeleNutritionnelDTO) => {

    //TODO

  };


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subPlanAlimentaire);
    this.utils.unsubscribe(this.subSavedPlanAlimentaire);
  }

  deletePlansAlimentaires = async () => {
    const result = confirm('Etes vous sûr de vouloir supprimer ce(s) plan(s) alimentaire ?', 'Suppression de(s) plan(s) alimentaire');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      const idsToDelete = this.selectedRows;
      this.gfs.deleteList(this.modeleNutritionnelSvc.getEntityName(), idsToDelete).subscribe(response => {

        if (!this.utils.isResponseSupplierError(response)) {

          const deletedRows = this.gds.mapObjectsFromIds(response.additionalProperties['idsDeleted'], this.plansAlimentaires);
          const notDeletedRows = this.gds.mapObjectsFromIds(response.additionalProperties['idsNotDeleted'], this.plansAlimentaires);

          if (!this.utils.isCollectionNullOrEmpty(deletedRows)) {
            this.utils.showMsg('modelenutri', MSG_SEVERITY.SUCCESS, `Plan(s) alimentaire(s) supprimé(s) avec succès.`);
            this.plansAlimentaires = this.plansAlimentaires.filter(pa => !deletedRows.filter(r => pa.id === r.id).length);
          }
          if (!this.utils.isCollectionNullOrEmpty(notDeletedRows)) {

            const labels = notDeletedRows.map(elt => elt.libelle).join(', ');

            this.utils.showMsg('modelenutri', MSG_SEVERITY.ERROR, `Impossible de supprimer les plan(s) alimentaire(s) suivant(s): ${labels}`, '', 8000);
          }
        }
      });
    }
  };

  openPanelDuplication = ($event, rowData: ModeleNutritionnelDTO) => {


    if(this.auth2Svc.utilisateur.siteListLocaux.length === 1){
      this.duplicationSite = this.auth2Svc.utilisateur.siteListLocaux[0];
    }else{
      this.duplicationSite = undefined;
    }
    this.planAlimentaireToDuplicate = _cloneDeep(rowData);

    this.opDuplication.toggle($event);
  };

  duplicateModeleNutri = (planAlimentaireToDuplicate: ModeleNutritionnelDTO, duplicationSite: SiteDTO) => {

    this.modeleNutritionnelSvc.duplicate(planAlimentaireToDuplicate, duplicationSite).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {

        this.modeleNutritionnelSvc.announceSavedDTO(response);

        this.utils.showMsg(MSG_KEY.MODELE_NUTRI, MSG_SEVERITY.SUCCESS,`Modèle de menu dupliqué avec succès.`);

      }

      this.opDuplication.hide();

    });
  };

  canCreate = (): boolean => this.auth2Svc.hasSitesLocaux();

  tooltipBoutonCreer = (): string => {
    let response: string;
    if (this.canCreate()) {
      response = "";
    } else {
      if (!this.auth2Svc.hasSitesLocaux()) {
        response = MESSAGE_CREATION_OBJET_IMPOSSIBLE;
      }
    }
    return response;
  };

  isDisabledBin = () => {
    let haveNoRightToDelete: boolean = false;

    if(!this.utils.isCollectionNullOrEmpty(this.selectedRows)){
      this.selectedRows.forEach(id => {
        const planAlim = this.plansAlimentaires.find(p => p.id === id);
        if(planAlim && planAlim.site && !this.auth2Svc.isSiteLocal(planAlim.site.id)){
          haveNoRightToDelete = true;
        }
      });
    }

    return this.utils.isCollectionNullOrEmpty(this.selectedRows) ? true : haveNoRightToDelete;
  };

  help = () => undefined;
}
