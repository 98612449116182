import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SelectionRepasPlcsSupplier} from './selection-plcs-resolver.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MenuItem, SelectItem, TreeNode} from 'primeng/api';
import {WorkflowsService} from '../../core/services/entities/workflows.service';
import {DomSanitizer} from '@angular/platform-browser';
import {UtilsService} from '../../core/utils/utils.service';
import {
  DATEPICKER_FR,
  HELP_FOLDERS,
  MSG_KEY,
  MSG_SEVERITY,
  REGLES_PREFERES,
  TYPE_EFFECTIF,
  WORKFLOW_REVISION
} from '../../core/constants';
import * as moment from 'moment';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {RunStepDTO} from '../../core/dtos/run-step-dto';
import {SelectionRepasPlcsService} from '../../core/services/selection-plcs/selection-repas-plcs.service';
import {ReglePrefereDTO} from '../../core/dtos/regle-prefere-dto';
import {WorkflowInstanceDTO} from '../../core/dtos/workflow-instance-dto';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {FlatNode} from '../../core/models/flatNode';
import {debounceTime} from 'rxjs/operators';

export enum STEP_SELECTION_PLCS {
  selectionBesoin,
  sectionPeriode
}

@Component({
  selector: 'yo-selection-plcs',
  templateUrl: './selection-plcs.component.html',
  styleUrls: ['./selection-plcs.component.scss'],
  animations: [
    trigger('animateErreurPanel', [

      transition('*=>open', animate('800ms', keyframes([
        style({opacity: 0.1, offset: 0.1,}),
        style({opacity: 0.5, offset: 0.3}),
        style({opacity: 1, offset: 0.5}),
        style({opacity: 0.5, offset: 0.7}),
        style({opacity: 1, offset: 1}),
      ]))),
    ]),
  ]
})
export class SelectionPlcsComponent implements OnInit, OnDestroy {


  TYPE_EFFECTIF = TYPE_EFFECTIF;

  subRoute: Subscription;
  subChangeMonths: Subscription;
  subWorkflowInstance: Subscription;
  sps: SelectionRepasPlcsSupplier;

  stateErreurPanel = 'close';
  userMessage = '';

  valid = false;
  expandTree = false;
  selectTree = false;

  localeFr = DATEPICKER_FR;

  datesSaisieEffectifs: Date[] = [];
  dateCreationMenu: Date[] = [];
  dateMin: Date;

  reglePrefereDefaut: SelectItem = {label: 'Paramétrage par défaut', value: REGLES_PREFERES.PAR_DEFAUT};
  selectedReglePrefere: any;
  reglesPreferes: SelectItem[] = [];
  selectionInterfaceErrorMessages = {ERR_SELECTION_REPAS: '', ERR_SELECTION_PERIODE: ''};

  activeIndex: number;
  items: MenuItem[] = [];
  STEP_SELECTION_PLCS: any;

  newMonth: any;

  pathFile: string = HELP_FOLDERS.CLIENTS + '/selection-plcs';

  constructor(private route: ActivatedRoute,
              public sanitizer: DomSanitizer,
              public workflowSvc: WorkflowsService,
              public selectionRepasPlcsSvc: SelectionRepasPlcsService,
              private cd: ChangeDetectorRef,
              public utils: UtilsService) {

    this.STEP_SELECTION_PLCS = STEP_SELECTION_PLCS;
    this.activeIndex = STEP_SELECTION_PLCS.selectionBesoin;

    this.items = [{
      label: 'Sélection des points de livraison',
      command: (event: any) => {
        this.activeIndex = STEP_SELECTION_PLCS.selectionBesoin;
      }
    },
      {
        label: 'Sélection de la période',
        command: (event: any) => {
          this.activeIndex = STEP_SELECTION_PLCS.sectionPeriode;
        }
      }
    ];
  }

  ngOnInit() {
    // préchargement des données selon la route appelée
    this.routeSubscription();
    // lorsqu'on change de mois dans le calendrier, on surligne les dates de saisie effectifs et on grise toutes les dates inférieures à la date min.
    this.changeMonthsSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }

  changeMonthsSubscription = () => {
    this.subChangeMonths = this.selectionRepasPlcsSvc.changeMonths$.pipe(
      debounceTime(500)).subscribe(event => {
      this.newMonth = event;
      this.getCalendarValidDates();
    });
  };

  routeSubscription = () => {
    this.subRoute = this.route.data
      .subscribe((data: { selectionPlcsSupplier: SelectionRepasPlcsSupplier }) => {
        this.sps = data.selectionPlcsSupplier;

        //recuperation dans le local storage du type effectif.
        this.sps.typeEffectif = this.utils.isNullOrEmpty(localStorage.getItem('selection-plcs-type-effectif')) ?
          this.TYPE_EFFECTIF.previsionnelle : localStorage.getItem('selection-plcs-type-effectif');

        this.reglesPreferes = this.initReglesPreferesItems(this.sps.reglesPreferes, this.sps.workflowInstance, this.reglePrefereDefaut);
        this.selectedReglePrefere = this.initSelectedReglePrefereItem(this.reglesPreferes, this.sps.workflowInstance, this.reglePrefereDefaut);

        this.expandTree = false;
        this.expandAll(this.expandTree);
        this.selectTree = false;

        this.valid = this.validSelectionRepas(this.sps.selectedNodes, this.sps.selectedDates);

        if (this.valid) {
          this.getCalendarValidDates();
          this.activeIndex = STEP_SELECTION_PLCS.sectionPeriode;
          this.treeNodeSelectable(false);
        }

        this.stateErreurPanel = 'close';
        if (!this.utils.isCollectionNullOrEmpty(this.sps.erreurs)) {
          this.stateErreurPanel = 'open';
        }
      });
  };

  initReglesPreferesItems = (reglesPreferes: ReglePrefereDTO[], wi: WorkflowInstanceDTO, reglePrefereDefaut: SelectItem) => {
    const items: SelectItem[] = [];

    items.push(reglePrefereDefaut);
    for (const reglePrefere of reglesPreferes) {
      items.push({label: reglePrefere.libelle, value: reglePrefere.code});
    }
    return items;
  };

  initSelectedReglePrefereItem = (reglesPreferes: SelectItem[], wi: WorkflowInstanceDTO, reglePrefereDefaut: SelectItem) => {

    if (wi.reglePrefereParametrageDefaut) {
      return reglePrefereDefaut.value;
    }

    for (const reglePrefere of reglesPreferes) {
      if (reglePrefere.value === wi.reglePrefereCode) {
        return reglePrefere.value;
      }
    }

    return undefined;
  };

  validSelectionRepas = (selectedNodes: TreeNode[], selectedDates: Date[]) => {

    let valid = true;

    this.selectionInterfaceErrorMessages = {ERR_SELECTION_REPAS: '', ERR_SELECTION_PERIODE: ''};

    if (this.utils.isCollectionNullOrEmpty(selectedDates)
      || this.utils.isNullOrEmpty(selectedDates[0])
      || this.utils.isNullOrEmpty(selectedDates[1])
    ) {
      this.selectionInterfaceErrorMessages.ERR_SELECTION_PERIODE = 'Veuillez sélectionner une période.';
      valid = false;
    }
    if (this.utils.isCollectionNullOrEmpty(selectedNodes)) {
      this.selectionInterfaceErrorMessages.ERR_SELECTION_REPAS = 'Veuillez sélectionner des repas.';
      valid = false;
    }
    return valid;
  };


  saveSelectionRepas = () => {

    this.valid = this.validSelectionRepas(this.sps.selectedNodes, this.sps.selectedDates);
    this.stateErreurPanel = 'close';

    localStorage.setItem('selection-plcs-type-effectif', this.sps.typeEffectif);

    if (this.valid) {
      const runStep: RunStepDTO = new RunStepDTO();
      runStep.idWorkflowInstance = this.sps.workflowInstance.id;
      runStep.revTypeValue = WORKFLOW_REVISION.MODIFIE;
      runStep.runInput = this.getRunInput();

      this.workflowSvc.runStep(runStep).subscribe(response => {
        if (response) {
          this.workflowSvc.announceWorkflowInstance(response.one);
          const erreurs = response.additionalProperties['erreurs'];
          this.sps.erreurs = erreurs;

          if (this.utils.isCollectionNullOrEmpty(erreurs)) {
            this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Sélection des repas par point de livraison enregistrée avec succès !`);
          } else {
            this.stateErreurPanel = 'open';
          }
          this.cd.markForCheck();
        }
      });
    }
  };

  getRunInput = () => {
    const plcNodes = this.utils.selectedNodesLevel(this.sps.selectedNodes, 5);
    let repasPlcs = [];

    if (!this.utils.isCollectionNullOrEmpty(plcNodes)) {
      repasPlcs = plcNodes.map(node => node.data);
    }

    let datesStr = [];
    if (!this.utils.isNullOrEmpty(this.sps.selectedDates)) {
      const dates = this.utils.getDates(this.sps.selectedDates[0], this.sps.selectedDates[1]);
      datesStr = dates.map(date => this.utils.getYYYYMMDD(moment(date)));
    }

    const runInput = {repasPlcs, selectedDates: datesStr, typeEffectif: this.sps.typeEffectif};

    localStorage.setItem('selection-plcs-type-effectif', this.sps.typeEffectif);

    return runInput;
  };

  updateErrors = ($event: any) => {
    this.stateErreurPanel = 'close';
    if ($event) {
      this.sps.erreurs = $event;
      this.stateErreurPanel = 'open';
    }
  };

  expandAll = (expandTree: boolean) => {
    this.sps.tree.forEach(node => this.utils.expandRecursive(node, expandTree, 4));
  };

  onChangeSelectionRepas = ($event: any) => {
    console.log('onChangeSelectionRepas', $event);
    this.sps.selectedNodes = $event;

  };

  getTreeHelp = (): DialogMsgSupplier => {

    let dms = new DialogMsgSupplier();
    dms.title = `Sélection des points de livraison `;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
      `Niveau 1 = <i class="fas fa-industry mg-r-5"></i> Unités de Production`,
      `Niveau 2 = <i class="fas fa-handshake mg-r-5"></i>Contrats`,
      `Niveau 3 = <i class="fas fa-users mg-r-5"></i>Prestations`,
      `Niveau 4 = <i class="fas fa-hamburger mg-r-5"></i>Repas`,
      `Niveau 5 = <i class="fas fa-map-pin mg-r-5"></i>Point de livraison client`,
    ];

    dms.content = {
      intro: `Sélectionner des points de livraison`,
      paragraphes: [p1]

    };

    return dms;
  };


  getPeriode = (selectedDates: Date[]) => {
    let periode = 'Aucune période sélectionnée.';

    if (!this.utils.isCollectionNullOrEmpty(selectedDates)
      && !this.utils.isNullOrEmpty(selectedDates[0])
      && !this.utils.isNullOrEmpty(selectedDates[1])
    ) {
      periode = `Période du <b>${this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(selectedDates[0]))}</b> au  <b>${this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(selectedDates[1]))}</b> `;
    }
    return periode;
  };

  changeDates = ($event: any) => {
    this.valid = this.validSelectionRepas(this.sps.selectedNodes, this.sps.selectedDates);
    this.selectionRepasPlcsSvc.announceChangeMonths($event);
  };

  changeStep = (step: number) => {
    this.activeIndex = step;

    switch (step) {
      case STEP_SELECTION_PLCS.selectionBesoin: {

        //mettre le tree en mode selectionnable
        this.treeNodeSelectable(true);

        break;
      }
      case STEP_SELECTION_PLCS.sectionPeriode: {
        this.getCalendarValidDates();

        //mettre le tree en mode readOnly
        this.treeNodeSelectable(false);
        break;
      }
    }
  };

  getCalendarValidDates = () => {

    if (!this.utils.isCollectionNullOrEmpty(this.sps.selectedDates)) {
      let startDate = new Date();

      if (!this.utils.isNullOrEmpty(this.sps.selectedDates[0])) {
        startDate = this.sps.selectedDates[0];
      }
      if (this.sps.selectedNodes.length > 0) {

        const plcSpecNodes: TreeNode[] = this.utils.selectedNodesLevel(this.sps.selectedNodes, 5);
        const flatNodeList: FlatNode[] = FlatNode.treeNodeToFlatNodeList(plcSpecNodes);
        this.selectionRepasPlcsSvc.changeMonthsHttpRequest(this.newMonth, flatNodeList, startDate, 2).subscribe(response => {
          if (response) {

            this.datesSaisieEffectifs = response.additionalProperties['datesSaisieEffectifs'];
            this.dateCreationMenu = response.additionalProperties['dateCreationMenu'];
            this.dateMin = response.additionalProperties['dateMin'];
            this.userMessage = response.additionalProperties['userMessage'];

            this.dateMin = this.utils.convertNumberDateToDate(this.dateMin);

            if (!this.utils.isCollectionNullOrEmpty(this.datesSaisieEffectifs)) {
              this.datesSaisieEffectifs = this.datesSaisieEffectifs.map(item => this.utils.convertNumberDateToDate(item));
            }

            if (!this.utils.isCollectionNullOrEmpty(this.dateCreationMenu)) {
              this.dateCreationMenu = this.dateCreationMenu.map(item => this.utils.convertNumberDateToDate(item));
            }
          }
        });
      }
    }
  };

  setNodeAndChildSelectable = (node: TreeNode, isSelectable: boolean) => {
    node.selectable = isSelectable;
    if (node.children) {
      node.children.forEach(childNode => {
        this.setNodeAndChildSelectable(childNode, isSelectable);
      });
    }
  };

  treeNodeSelectable = (isSelectable: boolean) => {
    this.sps.tree.forEach(node => {
      this.setNodeAndChildSelectable(node, isSelectable);
    });
  };

}
