import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../core/dtos/object-dto';
import {HELP_FOLDERS, MSG_KEY} from '../../core/constants';
import {FournisseurDTO} from '../../core/dtos/fournisseur-d-t-o';
import {FournisseurService} from '../../core/services/entities/fournisseur.service';
import {MenuItem} from 'primeng/api';
import {Menu} from 'primeng/menu';
import {SecteursService} from '../../core/services/entities/secteurs.service';
import {SecteursFournisseursService} from '../../core/services/entities/secteurs-fournisseurs.service';
import {CataloguesAchatsService} from '../../core/services/entities/catalogues-achats.service';
import {FournissseurSupplier} from './grid-fournisseur-resolver.service';
import {GestionFournisseurService} from '../../core/services/gestion-fournisseurs/gestion-fournisseur.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {GestionFournisseurCatalogueAchatService} from '../../core/services/gestion-fournisseurs/gestion-fournisseur-catalogue-achat.service';
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-grid-fournisseurs',
  templateUrl: './grid-fournisseur.component.html',
  styleUrls: ['./grid-fournisseur.component.scss']
})
export class GridFournisseurComponent implements OnInit, OnDestroy {

  title: string;
  subRoute: Subscription;
  subNewFournisseurParent: Subscription;

  selectedFiliale: FournisseurDTO;

  fournisseursList: FournisseurDTO[] = [];
  selectedFournisseur: FournisseurDTO;

  totalRow: number = 0;

  displayFormDialog: boolean = false;

  cols: any[] = [
    {field: 'actions', header: 'Actions'},
    {field: 'site', header: 'Site'},
    {field: 'libelle', header: 'Fournisseur'},
    {field: 'filiales', header: 'Filiales'},
    {field: 'code', header: 'Code'},
    {field: 'adresse', header: 'Adresse'},
    {field: 'actif', header: 'Actif'}
  ];

  /**
   * Selection des actions sur le bon de réception
   */
  filialeActions: MenuItem[] = [];

  pathFile: string = HELP_FOLDERS.FOURNISSEURS + '/fournisseurs';

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private gestionFournisseurSvc: GestionFournisseurService,
              private fournisseurSvc: FournisseurService,
              private secteurSvc: SecteursService,
              private secteurFournisseurSvc: SecteursFournisseursService,
              private catalogueAchatSvc: CataloguesAchatsService,
              public gds: GenericDatagridService,
              private gfs: GenericFormService,
              private cd: ChangeDetectorRef,
              private caFournsseurSvc: GestionFournisseurCatalogueAchatService) {
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subNewFournisseurParent);
  }

  initData = () => {
    this.subRoute = this.route.data.subscribe((data: { fournisseurSupplier: FournissseurSupplier }) => {
      this.fournisseursList = data.fournisseurSupplier.fournisseurParentList;
    });

    this.subNewFournisseurParent = this.gestionFournisseurSvc.refreshGridParent$.subscribe((newOne: FournisseurDTO) => {
      this.gestionFournisseurSvc.getAllFournisseurParent().subscribe(data => {
        this.fournisseursList = data.resultList;
      });
    });
  };

  initFilialeActions = () => {
    this.filialeActions = [
      {
        label: this.auth2Svc.isSiteLocal(this.selectedFiliale.site.id) ? 'Éditer la filiale' : 'Voir la filiale',
        icon: 'fas fa-file-invoice',
        command: () => this.openFiliale(this.selectedFournisseur, this.selectedFiliale)
      },
      {
        label: 'Voir le catalogue articles de la filiale',
        icon: 'fas fa-book-open',
        command: (event) => this.openArticlesCatalogueAchat(this.selectedFiliale)
      },
      {
        label: 'Supprimer la filiale',
        icon: 'fas fa-trash-alt',
        disabled: this.isDisabledBtn(),
        command: () => this.openDeleteObject(this.selectedFiliale)
      }
    ];
  };

  help = (): DialogMsgSupplier => {

    let dms = new DialogMsgSupplier();
    dms.title = `Les fournisseurs`;

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Fournisseurs et Unités de production`;
    p1.lines = [
      `Les fournisseurs ont des filiales.`,
      `Les filiales sont rattachées à des secteurs géographiques.`,
      `Les filiales peuvent livrer les unités de production si elles ont le même secteur géographique que celles-ci.`
    ];

    dms.content = {
      intro: `Fonctionnement des fournisseurs`,
      paragraphes: [p1]
    };
    return dms;
  };

  getFilialeInfos = (dto: FournisseurDTO): string => {

    if (!this.utils.isCollectionNullOrEmpty(dto.secteurFournisseurList)) {
      return 'Livraisons sur : <br>' + dto.secteurFournisseurList.map(sf => sf.secteur.libelle).join('<br>');
    }
    return '';
  };

  openFiliale = (fournisseur: FournisseurDTO, filiale: FournisseurDTO) => {

    //si undefined mode creation
    if (this.utils.isNullOrEmpty(filiale)) {
      this.fournisseurSvc.announceCreateFiliale(fournisseur);
    } else {
      this.fournisseurSvc.announceEditFiliale(filiale);
    }

    this.displayFormDialog = true;
  };

  openArticlesCatalogueAchat = (filiale: FournisseurDTO) => {
    this.caFournsseurSvc.announceDisplayCatalogueAchatFournisseur(filiale);
  };

  openEditObject = (fournisseur: FournisseurDTO) => {

    if (this.utils.isNullOrEmpty(fournisseur)) {
      fournisseur = this.fournisseurSvc.createEmptyDTO();
      fournisseur.id = 0;
    }
    this.routeMapSvc.goToSecondaryRoute(['gestion-fournisseurs-fournisseur', fournisseur.id, 'ficheidentite']);
  };

  openDeleteObject = async (dto: ObjectDTO) => {
    const result = confirm(`Etes-vous sûr de vouloir supprimer ce fournisseur  '${dto.libelle}' ?`, 'Suppression de fournisseur');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.gestionFournisseurSvc.deleteFournisseur(dto.id).subscribe((response: ResponseWrapper<IsDeletableObject>) => {
        this.initData();
        this.utils.deleteMessage(response, MSG_KEY.ROOT);
      });
    }
  };

  chooseFilialeAction = (menuActions: Menu, $event: MouseEvent, filiale: FournisseurDTO) => {
    this.selectedFiliale = filiale;
    this.initFilialeActions();
    menuActions.show($event);
  };

  canCreate = (): boolean => {
    return this.auth2Svc.hasSitesLocaux();
  };


  onFilterdata = (event: any) => {
    this.totalRow = event.filteredValue.length;
  };

  onCloseFormDialog = () => {
    this.displayFormDialog = false;
  };

  isDisabledBtn = () => {
    if (!this.utils.isNullOrEmpty(this.selectedFiliale)) {
      return !this.auth2Svc.isSiteLocal(this.selectedFiliale.site.id);
    } else {
      return false;
    }
  };

}
