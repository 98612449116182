import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {ModelViewMatierePremiere} from '../model-view-matiere-premiere';
import {SearchSupplierWrapper} from '../../../../core/suppliers/wrappers/search-supplier-wrapper';
import {ProduitDeclinaisonService} from '../../../../core/services/entities/produit-declinaison.service';
import {LOTS_PERIMES, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {SearchSupplier} from '../../../../core/suppliers/search-supplier';

@Component({
  selector: 'yo-dialog-perimes',
  templateUrl: './dialog-perimes.component.html',
  styleUrls: ['./dialog-perimes.component.scss']
})
export class DialogPerimesComponent implements OnInit, OnDestroy {

  subAction: Subscription;


  displayDialog = false;
  selectedMatierePremiere: ModelViewMatierePremiere;
  ssw: SearchSupplierWrapper;

  LOTS_PERIMES = LOTS_PERIMES;

  // T = tous les périmés, S = produit sélectionné
  deleteOption = LOTS_PERIMES.DENREE_SELECTIONNEE;

  constructor(public utils: UtilsService,
              private produitDeclinaisonSvc: ProduitDeclinaisonService) {}

  ngOnInit() {
    this.subAction = this.produitDeclinaisonSvc.openDialogPerimes$.subscribe(response => {
      this.displayDialog = true;
      this.selectedMatierePremiere = response.selectedMatierePremiere;
      this.ssw = response.ssw;

      if (!this.utils.isNullOrEmpty(this.selectedMatierePremiere)) {
        this.deleteOption = this.selectedMatierePremiere.noStock ? LOTS_PERIMES.TOUS : LOTS_PERIMES.DENREE_SELECTIONNEE;
      } else {
        this.deleteOption = LOTS_PERIMES.TOUS;
      }
    });
  }

  closeDialog = () => {
    this.displayDialog = false;
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAction);
  }

  deletePerimes = () => {
    this.ssw.filtersMap['ID_PRODUIT_DECLINAISON'] = new SearchSupplier(  -1);
    if(this.deleteOption === LOTS_PERIMES.DENREE_SELECTIONNEE){
      this.ssw.filtersMap['ID_PRODUIT_DECLINAISON'] = new SearchSupplier( this.selectedMatierePremiere ? this.selectedMatierePremiere.id : -1);
    }

    this.produitDeclinaisonSvc.deleteLotsPerimes(this.ssw).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.closeDialog();
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Les lots périmés ont été enlevés du stock.');
        // annoncer que des lots ont été supprimés pour péremption : reinit du filtre périmé
        // rafraichir la grille des matieres premieres
        this.produitDeclinaisonSvc.announceLotsDeleted();
      }
    });
  };
}
