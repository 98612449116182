import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {ContratmenuDTO} from '../../dtos/contratmenu-dto';
import {UtilsService} from '../../utils/utils.service';
import {cloneDeep as _cloneDeep, isEmpty as _isEmpty} from 'lodash'
import {ContratMenuConviveRepasDTO} from '../../dtos/contratmenuconviverepas-dto';
import {MenusRoutemapService} from './menus-routemap.service';
import * as moment from 'moment';
import {Auth2Service} from '../security/auth2.service';
import {PreferencesUtilisateurService} from '../preferences-utilisateur.service';
import {ContratMenuConviveDecoupageDTO} from '../../dtos/contratmenuconvivedecoupage-dto';
import {MenuCompositionDTO} from '../../dtos/menucomposition-dto';
import {FS_ROUTES, MENU_PROFIL, USER_PREFERENCE} from '../../constants';
import {MenusDatesService} from './menus-dates.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {HttpClient} from '@angular/common/http';
import {MenusCompositionService} from './menus-composition.service';
import {GroupeNutritionnelDecoupageDTO} from '../../dtos/groupeNutritionnelDecoupage-dto';
import {RoutemapService} from '../routemap.service';
import {CelluleTableauDTO} from '../../dtos/gestionmenus/planning/cellule-tableau-dto';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../dtos/contratmenuconvive__contrainte-alim-dto';
import {InfosMenuCompoSupplier} from "../../suppliers/gestionmenus/info-menu-compo-supplier";


export const URL_GET_PREPARE_TOOLBAR_MENUS = `dolrest/gestionmenus2/prepare-toolbar-menus`;

@Injectable()
export class MenusToolbarService {

  //Observables

  private subjectDisplayValidationMenusDialog = new Subject<boolean>();
  displayValidationMenusDialog$ = this.subjectDisplayValidationMenusDialog.asObservable();

  private subjectDisplayFormSearch = new BehaviorSubject(false);
  displayFormSearch$ = this.subjectDisplayFormSearch.asObservable();

  private subjectDisplayCoherenceArticle = new BehaviorSubject(false);
  displayCoherenceArticle$ = this.subjectDisplayCoherenceArticle.asObservable();

  private subjectDisplayProfil = new BehaviorSubject(MENU_PROFIL.DIET);
  displayProfil$ = this.subjectDisplayProfil.asObservable();


  private subjectDialogInfoMenu = new Subject<InfosMenuCompoSupplier>();
  dialogInfoMenu$ = this.subjectDialogInfoMenu.asObservable();



  private subjectStartDateMenu = new BehaviorSubject(new Date());
  private subjectContratMenu = new BehaviorSubject(undefined);
  private subjectPrestations = new BehaviorSubject(null);
  private subjectRegime = new BehaviorSubject(null);
  private subjectContratsMenusConvivesForCalculMatieres = new BehaviorSubject(undefined);
  private subjectGemrcnSupplier = new BehaviorSubject(undefined);
  private subjectMonthChange = new Subject<any>();
  monthChange$ = this.subjectMonthChange.asObservable();
  private subjectValidationMenuMonthChange = new Subject<any>();
  validationMenuMonthChange$ = this.subjectValidationMenuMonthChange.asObservable();
  contratMenuSelected$ = this.subjectContratMenu.asObservable();

  startDateMenu$ = this.subjectStartDateMenu.asObservable();
  prestations$ = this.subjectPrestations.asObservable();
  regime$ = this.subjectRegime.asObservable();

  announceStartDate = (date: Date) => {
    this.subjectStartDateMenu.next(date);
  };

  announceContratMenu = (contratMenu: ContratmenuDTO) => {
    this.subjectContratMenu.next(contratMenu);
  };

  announcePrestations = (prestations: ContratMenuConviveDTO[]) => {
    this.subjectPrestations.next(prestations);
  };

  announceRegime = (regime: ContratMenuConvive__ContrainteAlimDTO) => {
    this.subjectRegime.next(regime);
  };

  isProfilToDisplay = (currentProfil: number, prefProfil: number): boolean => {

    if (currentProfil === MENU_PROFIL.TOUT) {
      return true;
    }

    if (currentProfil === prefProfil) {
      return true;
    }

    return false;

  };

  announceDisplayProfil = (profil: number) => {
    this.subjectDisplayProfil.next(profil);
  };


  announceDisplayCoherenceArticle = (display: boolean) => {
    this.subjectDisplayCoherenceArticle.next(display);
  };

  announceDisplayFormSearch = (display: boolean) => {
    this.subjectDisplayFormSearch.next(display);
  };

  constructor(public utils: UtilsService,
              private routeMapSvc: RoutemapService,
              private auth2Svc: Auth2Service,
              private menuCompoSvc: MenusCompositionService,
              private http: HttpClient,
              private menuDateSvc: MenusDatesService,
              private prefUserSvc: PreferencesUtilisateurService,
              private menusRoutemapSvc: MenusRoutemapService) {
  }

  getLabelContratMenuConvive = (contratMenuConvive: ContratMenuConviveDTO): string => {

    if (contratMenuConvive && contratMenuConvive.libelle && contratMenuConvive.site.libelle) {
      return `${contratMenuConvive.libelle} - ${contratMenuConvive.code} (${contratMenuConvive.site.libelle})`;
    }

    return '';
  };

  getLabelContratMenu = (contratMenu: ContratmenuDTO): string => {

    if (contratMenu && contratMenu.libelle && contratMenu.site.libelle) {
      return `${contratMenu.libelle} (${contratMenu.site.libelle})`;
    }

    return '';
  };

  getMenusCompositionsList = (contratMenuConviveDecoupageDTOs: ContratMenuConviveDecoupageDTO[], momentDates: moment.Moment[]): Observable<ResponseWrapper<MenuCompositionDTO>> => {

    if (!_isEmpty(contratMenuConviveDecoupageDTOs) && !_isEmpty(momentDates)) {
      let idsContratMenuConviveDecoupage: number[] = contratMenuConviveDecoupageDTOs.map(item => item.id);
      let strDates: string[] = momentDates.map(item => item.format('YYYYMMDD'));
      return this.http.get(this.menusRoutemapSvc.getMenusCompositionsSLink(idsContratMenuConviveDecoupage, strDates)).pipe(
        catchError(error => this.utils.handleError(error, true)));

    }
    return of(null);
  };

  getGroupeNutritionnelDecoupageList = (contratMenuConviveDecoupageDTOs: ContratMenuConviveDecoupageDTO[]): Observable<ResponseWrapper<GroupeNutritionnelDecoupageDTO>> => {
    if (!_isEmpty(contratMenuConviveDecoupageDTOs)) {
      let idsContratMenuConviveDecoupage: number[] = contratMenuConviveDecoupageDTOs.map(item => item.id);
      return this.http.get(this.menusRoutemapSvc.getGroupesNutritionnelsDecoupagesSLink(idsContratMenuConviveDecoupage)).pipe(
        catchError(error => this.utils.handleError(error, true)));

    }
    return of(null);
  };

  getContratMenuConviveDecoupageList = (contratMenuConviveRepasDTOs: ContratMenuConviveRepasDTO[]): Observable<ContratMenuConviveDecoupageDTO[]> => {
    let idsContratMenuConviveRepas: number[] = contratMenuConviveRepasDTOs.map(item => item.id);
    if (idsContratMenuConviveRepas && idsContratMenuConviveRepas.length > 0) {
      return this.http.get(this.menusRoutemapSvc.getContratMenuConvivesDecoupageSLink(idsContratMenuConviveRepas)).pipe(
        catchError(error => this.utils.handleError(error, true)));
    }
    return of(null);
  };

  isLockedContratMenu = (contratMenu: ContratmenuDTO): boolean => {
    if (!this.utils.isNullOrEmpty(contratMenu)) {
      return !this.auth2Svc.isSiteLocal(contratMenu.site.id);
    }
    return false;
  };


  openSettings = () => {
    this.prefUserSvc.announcePreferencesUtilisateur();
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GME_SETTINGS]);
  };

  openInfosMenuCompo = (menuCompo: MenuCompositionDTO, cellule: CelluleTableauDTO) => {

    const infoMenuCompoSupplier = new InfosMenuCompoSupplier();
    infoMenuCompoSupplier.menuCompo = _cloneDeep(menuCompo);
    infoMenuCompoSupplier.cellule = new CelluleTableauDTO();
    infoMenuCompoSupplier.cellule.libelleConvive = cellule.libelleConvive
    infoMenuCompoSupplier.cellule.dateMenu = cellule.dateMenu
    infoMenuCompoSupplier.cellule.libelleRepas = cellule.libelleRepas
    infoMenuCompoSupplier.cellule.libelleDecoupageRepas = cellule.libelleDecoupageRepas
    infoMenuCompoSupplier.cellule.contratMenuSiteId = cellule.contratMenuSiteId

    this.announceDialogInfoMenu(infoMenuCompoSupplier);
  };

  openDuplicationMenusCompos = () => {
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.GME_DUPLICATION_MENU]);
  };

  getCurrentDates = (currentDate: Date, contratMenuConviveRepasDTOs: ContratMenuConviveRepasDTO[]): moment.Moment[] => {
    if (!_isEmpty(contratMenuConviveRepasDTOs) && currentDate) {

      let currentDateMoment = moment(currentDate).clone();
      let nbJoursPref: number = +this.prefUserSvc.getPreferenceUtilisateurValue(USER_PREFERENCE.GESTIONMENUS_DISPLAY_NBJOURS);
      let listDayOfWeek = this.menuDateSvc.getIntersectionDaysOfWeek(this.menuDateSvc.getListeJourSemaineContrat(contratMenuConviveRepasDTOs), this.prefUserSvc.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_SELECT_JOURS_SEMAINE));
      return this.menuDateSvc.getDates(currentDateMoment, nbJoursPref, listDayOfWeek);
    }
    return [];
  };

  getNextStartDate = (currentDate: Date, contratMenuConviveRepasDTOs: ContratMenuConviveRepasDTO[]): Date => {
    let currentDates = this.getCurrentDates(currentDate, contratMenuConviveRepasDTOs);
    if (!_isEmpty(currentDates)) {
      let listDayOfWeek = this.menuDateSvc.getIntersectionDaysOfWeek(this.menuDateSvc.getListeJourSemaineContrat(contratMenuConviveRepasDTOs), this.prefUserSvc.getPreferenceUtilisateurArrIntValue(USER_PREFERENCE.GESTIONMENUS_SELECT_JOURS_SEMAINE));
      let nextMomentDate = this.menuDateSvc.getNextStartDate(currentDates, listDayOfWeek);
      if (nextMomentDate) {
        return nextMomentDate.clone().toDate();
      }
    }
    return undefined;
  };

  isEmpty = (cmcs: ContratMenuConviveDTO[]): boolean => {
    if (_isEmpty(cmcs)) {
      return true;
    }
    return false;
  };


  /**
   * Récupérer toutes les infos nécessaires  à la création des menus
   */
  prepareToolbarMenus = () => {
    return this.http.get(URL_GET_PREPARE_TOOLBAR_MENUS).pipe(
      catchError(error => this.utils.handleError(error)));
  };

  announceMonthChange = event => {
    this.subjectMonthChange.next(event);
  };

  announceDisplayValidationMenusDialog = (displayDialog: boolean) => {
    this.subjectDisplayValidationMenusDialog.next(displayDialog);
  };

  announceValidationMenuMonthChange = ($event: any) => {
    this.subjectValidationMenuMonthChange.next($event);
  };

  announceDialogInfoMenu = (searchSupplier: InfosMenuCompoSupplier) => {
    this.subjectDialogInfoMenu.next(searchSupplier);
  };
}

