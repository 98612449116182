import {Subscription} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {EnvironnementDTO} from '../../../core/dtos/environnement-dto';
import {EnvironnementService} from '../../../core/services/entities/gestionenvironnements/environnement.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {FS_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {SiteService} from '../../../core/site.service';
import {EnvironnementResolverService} from '../../environnement-resolver.service';
import {SearchService} from '../../../core/services/search.service';
import {RoutemapService} from '../../../core/services/routemap.service';
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {confirm} from "devextreme/ui/dialog";


@Component({
  selector: 'yo-grilleenvironnements',
  templateUrl: './grilleenvironnements.component.html',
  styleUrls: ['./grilleenvironnements.component.scss']
})
export class GrilleenvironnementsComponent implements OnInit {

  loading: boolean;
  totalRecords: number;
  scrollHeight: string = '800px';

  start: boolean = true;

  environnementsForGrille: EnvironnementDTO[];
  subGrilleEnvironnements: Subscription;
  subEnvironnement: Subscription;
  subDeleteEnvironnement: Subscription;

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/environnements';

  constructor(private environnementService: EnvironnementService,
              private routeMapSvc: RoutemapService,
              private siteService: SiteService,
              private searchService: SearchService,
              public utils: UtilsService,
              private environnementResolverService: EnvironnementResolverService) {
  }

  ngOnInit() {
    this.utils.setTitle('Gestion des environnements');
    this.subGrilleEnvironnements = this.environnementService.searchEnvironnement('')
      .subscribe(environnementsForGrille => this.environnementsForGrille = environnementsForGrille)

    this.environnementService.environnementSubjectToDelete$.subscribe(env => {
      const idx = this.environnementsForGrille.findIndex(e => e.id === env.id);
      this.environnementsForGrille.splice(idx, 1);
    });

    this.subEnvironnement = this.environnementResolverService.environnementAnnounceSource.subscribe(environnement => {
      const idx = this.environnementsForGrille.findIndex(e => e.id === environnement.id);
      if (idx >= 0)
        this.environnementsForGrille[idx] = environnement;
      else
        this.environnementsForGrille.push(environnement);
    });

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subGrilleEnvironnements);
    this.utils.unsubscribe(this.subDeleteEnvironnement);
    this.utils.unsubscribe(this.subEnvironnement);
  }

  confirmDelete = async (environnementDTO: EnvironnementDTO): Promise<void> => {
    const result = confirm("Etes vous sûr de vouloir supprimer l'environnement '" + environnementDTO.libelle + "' ?",
      'Suppression de l\'environnement');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.delete(environnementDTO);
    }
  };

  delete = (environnementDTO: EnvironnementDTO): Subscription => {
    this.subDeleteEnvironnement = this.environnementService.delete(environnementDTO.id)
      .subscribe(
      data => {
        let result : ResponseWrapper<boolean> = data;
        if(result.inError){
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR,  `Suppression impossible : ${result.resultMessage}`, '', 8000);
        }else{
          this.environnementService.announceEnvironnementToDelete(environnementDTO);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Environnement ${environnementDTO.libelle} supprimé.`, '', 8000);
        }
      },
      error => {
        this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Impossible de supprimer l'environnement ${environnementDTO.libelle}.Vérifiez qu'il n'est pas utilisé dans l'application`, '', 8000);
      }
    );
    return this.subDeleteEnvironnement;
  };

  openEnvironnement = (environnementDTO: EnvironnementDTO): void => {
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.ADM_ENVIRONNEMENT_FIC, environnementDTO.id]);
  };

  openNewEnvironnement = (): void => {
    this.environnementService.announceEnvironnementToCreate();
    this.routeMapSvc.goToSecondaryRoute([FS_ROUTES.ADM_ENVIRONNEMENT_FIC, 0]);
  };

  help = (): undefined => undefined;


}
