import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {GroupeAchat__FournisseurDTO} from "../../../../core/dtos/unite-de-production/groupe-achat-fournisseur-dto";
import {UtilsService} from "../../../../core/utils/utils.service";
import {GroupeAchatService, TAB_NAME_GR} from "../../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {Subscription} from "rxjs";
import {remove as _remove} from 'lodash'
import {GroupeAchatDTO} from "../../../../core/dtos/unite-de-production/groupe-achat-dto";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {ActivatedRoute} from "@angular/router";
import {GroupeAchatSidebarSupplier} from "../groupe-achat-sidebar-resolver.service";
import {IdFournisseurRatioModel} from "../../../../core/models/gestion-unites-production/id-fournisseur-ratio.model";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../../core/models/is-deletable-object";
import {DxDataGridComponent} from "devextreme-angular";
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-founisseur-groupe-achat',
  templateUrl: './founisseur-groupe-achat.component.html',
  styleUrls: ['./founisseur-groupe-achat.component.scss']
})
export class FounisseurGroupeAchatComponent implements OnInit, OnDestroy {

  groupeAchatFournisseurList: GroupeAchat__FournisseurDTO[] = [];
  groupeAchat: GroupeAchatDTO;
  selectedFournisseurs: number[] = [];

  subFournisseurSelected: Subscription;
  subscriptionRoute: Subscription;

  @ViewChild('grid') grid: DxDataGridComponent;

  pathFile: string = HELP_FOLDERS.FOURNISSEURS_GROUPES_ACHATS + '/fournisseur-groupe-achat';

  constructor(public utils: UtilsService,
              private groupeAchatSvc: GroupeAchatService,
              private auth2Svc: Auth2Service,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.initData();
    this.subcriptionFournisseurSelected();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subFournisseurSelected);
  }

  initData = () => {
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { groupeAchatSidebarSupplier: GroupeAchatSidebarSupplier }) => {
        this.groupeAchatFournisseurList = data.groupeAchatSidebarSupplier.groupeAchatFournisseurList;
        this.groupeAchat = data.groupeAchatSidebarSupplier.groupeAchat;
        this.groupeAchatSvc.announceActiveMenuItem(TAB_NAME_GR.fournisseurs);
      });
  };

  subcriptionFournisseurSelected = () => {
    this.subFournisseurSelected = this.groupeAchatSvc.fournisseurSelected$.subscribe(gafournisseurList => {
      const gafournisseurListToSaved = this.deleteDoublon(gafournisseurList);
      const idFournisseurRatioMap = this.getMapIdFournisseurRatio(gafournisseurListToSaved);
      if (gafournisseurListToSaved.length > 0) {
        this.saveGroupeAchatFournisseur(idFournisseurRatioMap);
      }
    });
  };

  getMapIdFournisseurRatio = (gafournisseurListToSaved: GroupeAchat__FournisseurDTO[]) => {
    let idFournisseurRatioMap = [];
    gafournisseurListToSaved.forEach(item => {
      let fournisseurRatio: IdFournisseurRatioModel = new IdFournisseurRatioModel();
      fournisseurRatio.idFournisseur = item.fournisseur.id;
      fournisseurRatio.ratio = item.ratioPrixUF;
      idFournisseurRatioMap.push(fournisseurRatio);
    });
    return idFournisseurRatioMap;
  };

  deleteDoublon = (gafNewList: GroupeAchat__FournisseurDTO[]) => {
    let gafToremoveList: GroupeAchat__FournisseurDTO[] = []
    gafNewList.forEach((newItem: GroupeAchat__FournisseurDTO) => {
      this.groupeAchatFournisseurList.forEach((gaf: GroupeAchat__FournisseurDTO) => {
        if (newItem.fournisseur.id === gaf.fournisseur.id) {
          gafToremoveList.push(newItem)
        } else {
          newItem.groupeAchat = this.groupeAchat;
        }
      });
    });

    gafToremoveList.forEach(itemToRemove => {
      _remove(gafNewList, itemToRemove);
      this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.INFO, `Le fournisseur` + itemToRemove.fournisseur.libelle + `existe déjà dans la liste`);
    });
    return gafNewList;
  };

  saveGroupeAchatFournisseur = (gaFournisseurList: any) => {
    this.groupeAchatSvc.saveGroupeAchatFournisseurList(gaFournisseurList, this.groupeAchat.id)
      .subscribe(data => {
        data.resultList.forEach(item => {
          this.groupeAchatFournisseurList.unshift(item);
        });
        this.selectedFournisseurs = [];
        this.utils.showMsg(MSG_KEY.SIDEBAR, MSG_SEVERITY.SUCCESS, `Sauvegarde réussie.`);
      });
  };


  loadFournisseurGroupeAchat = () => {
    this.groupeAchatSvc.getGroupeAchatFournisseurList(this.groupeAchat.id)
      .subscribe((data: ResponseWrapper<GroupeAchat__FournisseurDTO>) => {
        this.groupeAchatFournisseurList = data.resultList;
      });
  };

  openAddFournisseur = () => {
    this.groupeAchatSvc.announceOpenDialogAddFournisseur();
  };

  canAdd = () => this.auth2Svc.isSiteLocal(this.groupeAchat.site.id);

  canModify = () => this.auth2Svc.isSiteLocal(this.groupeAchat.site.id);

  help = () => {
  };

  removeFournisseurs = async (selectedFournisseurs: number[]) => {
    const result = confirm(`Etes vous sûr(e) de vouloir supprimer le(s) ${selectedFournisseurs.length} fournisseur(s) sélectionné(s) ?`,
      'Suppression de fournisseur');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.groupeAchatSvc.deleteGroupeAchatFournisseur(selectedFournisseurs)
        .subscribe((response: ResponseWrapper<IsDeletableObject>) => {
          this.utils.deleteMessage(response, MSG_KEY.SIDEBAR);
          this.loadFournisseurGroupeAchat();
        });
    }
  };
}
